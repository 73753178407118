export const VERIFY_USER_AUTH = 'verifyUserAuth';
export const FIREBASE_TOKEN = 'firebaseToken';
export const USER_UUID = 'userUuid';
export const USER_DATA = 'userData';
export const USER_OBJECT = 'userObject';
export const USER_CLAIMS = 'userClaims';
export const FIREBASE_USER_OBJECT = 'firebaseUserObject';
export const STUDIES_SELECTED = 'studiesSelected';
export const CENTERS_SELECTED = 'centersSelected';
export const PATIENT_DETAILS = 'patientDetails';
export const USER_DETAILS = 'userDetails';
export const DRAFT_DETAILS = 'draftDetails';
