import { combineReducers } from 'redux';
import * as actions from '../actions/actionTypes';

export const initialPatientDetails = {
    isForSomeone: false,
    contactName: '',
    name: '',
    phone: '',
    email: '',
    age: '',
    gender: 'male',
};

export const patientFlowInitialState = {
    selectedStudies: { study: [], patientCity: '', patientPincode: '' },
    selectedCenter: {},
    patientDetails: initialPatientDetails,
    userDetails: {},
    draftDetails: {},
};

const initialState = '';

const VerifyUserAuth = (state = { isLoading: true }, action) => {
    switch (action.type) {
        case actions.VERIFY_USER_AUTH:
            return { ...state, isLoading: action.payload.value };
        default:
            return state;
    }
};

const GetFirebaseToken = (state = initialState, action) => {
    switch (action.type) {
        case actions.FIREBASE_TOKEN:
            return action.payload.value;
        default:
            return state;
    }
};

const GetUserUuid = (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_UUID:
            return action.payload.value;
        default:
            return state;
    }
};

const GetUserData = (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_DATA:
            return action.payload.value;
        default:
            return state;
    }
};

const GetUserObject = (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_OBJECT:
            return action.payload.value;
        default:
            return state;
    }
};

const GetUserClaims = (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_CLAIMS:
            return action.payload.value;
        default:
            return state;
    }
};

const GetFirebaseUserObject = (state = initialState, action) => {
    switch (action.type) {
        case actions.FIREBASE_USER_OBJECT:
            return action.payload.value;
        default:
            return state;
    }
};

const PatientFlowReducer = (state = patientFlowInitialState, action) => {
    switch (action.type) {
        case actions.STUDIES_SELECTED:
            return {
                ...state,
                selectedStudies: action.payload.value,
            };
        case actions.CENTERS_SELECTED:
            return {
                ...state,
                selectedCenter: action.payload.value,
            };
        case actions.PATIENT_DETAILS:
            return {
                ...state,
                patientDetails: action.payload.value,
            };
        case actions.DRAFT_DETAILS: {
            return { ...state, draftDetails: action.payload.value };
        }
        case actions.USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload.value,
            };
        default:
            return state;
    }
};

const allReducer = combineReducers({
    VerifyUserAuthReduxState: VerifyUserAuth,
    FirebaseTokenReduxState: GetFirebaseToken,
    UserUuidReduxState: GetUserUuid,
    UserDataReduxState: GetUserData,
    UserObjectReduxState: GetUserObject,
    UserClaimsReduxState: GetUserClaims,
    FirebaseUserObjectReduxState: GetFirebaseUserObject,
    PatientFlowState: PatientFlowReducer,
});

export default allReducer;
