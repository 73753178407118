import { FormEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useAddOrder, useUpdateOrder } from '../hooks/useOrder';
import { useAddPatient } from '../hooks/useBooking';
import { useGetPhysicians } from '.././hooks/useUsers';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import OrderVerification from './OrderVerification';
import { toast } from 'react-toastify';
import { AiFillCloseCircle, AiOutlineFileAdd } from 'react-icons/ai';
import { useGetCenterTimeSlotByModalityV2 } from '../hooks/useCenter';
import { mergeDateAndTime, OrderType } from '../utils';
import { addDays, format } from 'date-fns';
import { useUploadDocumentOrder } from '.././hooks/useOrder';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../ModalTemplate/ModalComponent';
import SelectDocumentsModal from '../ModalTemplate/components/SelectDocumentsModal';
import { useCreateModalState } from '../hooks/useModalHooks';
import { ModalityDiscountObjType } from '../hooks/useModality';
import { userObject } from '../../redux/selectors';
import {
    calculateTotalBasePrice,
    calculateTotalContrastPrice,
    handleStudySlotSelect,
} from './Common';
import { calModalityDiscountPrice } from '../../patientFlow/helpers/helper';
import Loader from '../utils/Loader';
import TimeSlotV2 from './TimeSlotV2';

interface Props {
    orderDetailsObj: SubProps;
    handleClose?: () => void;
    discount: any;
    hasPrepaidFeature: boolean;
    testPreparation: null | string;
    changeCenterDetails: {
        orderDetails: any;
        isChangeCenter: boolean;
        searchModalClose?: () => void;
    };
}
interface SubProps {
    centerId: string;
    center: string;
    isSchedulerEnabled: boolean;
    description: string;
    location: string;
    priceDetails: {
        priceAfterDiscount: number;
        priceToCenter: number;
        priceToProvider: number;
        providerCommission: number;
        aggCommission: number;
        studyList: {
            studyName: string;
            price: number;
            modalityId: string;
            testPreparation: string[];
        }[];
        discount: string | number;
        totalPrice: number;
    };
    slots: number;
    type: OrderType;
    study: any;
    packageId?: any;
    modalityDiscount: ModalityDiscountObjType;
}

export interface StudySlotType {
    [studyId: string]: {
        studyName: string;
        studyPrice: number;
        centerId: string;
        modalityId: string;
        date: Date;
        slot: string;
        testPreparation: string;
    };
}

const getNextTenDaysInCalendar = () => {
    return addDays(new Date(), 10);
};

export const handleStudySlotData = (
    studyList: { id: string; studyName: string; testPreparation: string }[],
    priceList: { studyName: string; price: number; modalityId: string }[],
    centerId: string
): StudySlotType => {
    if (!studyList?.length) return {};
    let studySlots: StudySlotType = {};
    priceList.map((study, index: number) => {
        return (studySlots = {
            ...studySlots,
            [studyList[index].id]: {
                studyName: study.studyName,
                studyPrice: study.price,
                testPreparation: studyList[index].testPreparation,
                centerId,
                modalityId: study.modalityId,
                date: new Date(),
                slot: '',
            },
        });
    });
    return studySlots;
};
enum DiscountStatus {
    NONE = 'NONE',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

let daysTimeSlotsByDate: any;

const BookSlotsModal = ({
    orderDetailsObj,
    discount,
    hasPrepaidFeature,
    handleClose,
    changeCenterDetails,
}: Props) => {
    const user = userObject();
    const orderDiscountRef = useRef<HTMLInputElement>(null);
    const uploadDocsModal = useCreateModalState();

    const [studySlotsData, setStudySlotsData] = useState<StudySlotType>(
        handleStudySlotData(
            orderDetailsObj.study,
            orderDetailsObj.priceDetails.studyList,
            orderDetailsObj.centerId
        )
    );
    const [patientData, setPatientData] = useState({
        name: '',
        email: '',
        phone: '',
        age: '',
        gender: 'male',
        isPrepaid: false,
    });
    const [orderVerifyId, setOrderVerifyId] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    // const [orderDiscountValue, setOrderDiscountValue] = useState<number>();
    const [appliedDiscount, setAppliedDiscount] = useState({
        percentage: 0,
        discountedPrice: orderDetailsObj.priceDetails.totalPrice,
    });
    const [physicians, setPhysicians] = useState({
        value: null,
        label: 'None',
    });
    const [discountStatus, setDiscountStatus] = useState<DiscountStatus>(
        DiscountStatus.NONE
    );

    const [selectedFile, setSelectedFile] = useState<string[]>([]);
    const [selectedFileToUpload, setSelectedFileToUpload] = useState<File[]>(
        []
    );

    const { mutateAsync: mutateUploadDocumentOrder } = useUploadDocumentOrder();
    const { mutateAsync: addOrder, isLoading: isAddOrderLoading } =
        useAddOrder();
    const { mutateAsync: addPatient, isLoading: isAddPatientLoading } =
        useAddPatient();
    const {
        mutateAsync: mutateUpdateCenterInOrder,
        isLoading: isUpdateCenterInOrderLoading,
    } = useUpdateOrder();

    const {
        data: centerMachineTimeSlotsData,
        isLoading: centerMachineTimeSlotLoader,
        isError: centerMachineTimeSlotError,
    } = useGetCenterTimeSlotByModalityV2(
        orderDetailsObj.centerId,
        orderDetailsObj.priceDetails.studyList.map((study) => {
            return study.modalityId;
        }),
        orderDetailsObj.type === OrderType.GROUP,
        false
    );

    const isCombinedSlots = useMemo(() => {
        return (
            !!centerMachineTimeSlotsData?.length &&
            !Array.isArray(centerMachineTimeSlotsData?.[0])
        );
    }, [centerMachineTimeSlotsData]);

    const { data: physiciansList, isLoading: physicianListLoading } =
        useGetPhysicians();

    // TODO: Add discount from order detail modal
    // const {
    //     data: centerModalityDiscountList,
    //     isLoading: isCenterModalityDiscountListLoading,
    // } = useGetModalityDiscountByAggCenter({
    //     customerId: user.customerID,
    //     centerId: orderDetailsObj.centerId,
    // });

    //custom styles for react select
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 42,
            marginBottom: 10,
        }),
    };

    const physicianOptions = physiciansList?.map((d: any) => {
        if (d?.defaultPhysician && physicians.value !== d.id) {
            setPhysicians({
                value: d.id,
                label: d.name,
            });
        }
        return {
            value: d.id,
            label: d.name,
        };
    });

    if (typeof physicianOptions !== 'undefined' && physicians.value == null) {
        physicianOptions.unshift({
            value: null,
            label: 'None',
        });
    }

    const handlePhysicianChange = (physicianVal: any) => {
        setPhysicians({
            value: physicianVal.value,
            label: physicianVal.label,
        });
    };

    const handleStudyDateSelect = (date: Date, studyId: string) => {
        if (isCombinedSlots) {
            setStudySlotsData((prevVal) => {
                for (let studyId in prevVal) {
                    prevVal[studyId].date = date;
                    prevVal[studyId].slot = '';
                }
                return {
                    ...prevVal,
                };
            });
        } else {
            setStudySlotsData((prevVal) => {
                return {
                    ...prevVal,
                    [studyId]: {
                        ...studySlotsData[studyId],
                        date: date,
                        slot: '',
                    },
                };
            });
        }
    };

    const handleCloseAllModal = () => {
        changeCenterDetails.searchModalClose?.();
        handleClose?.();
    };

    const totalContrastPrice = useMemo(() => {
        return calculateTotalContrastPrice(orderDetailsObj);
    }, [orderDetailsObj]);

    const totalBasePrice = useMemo(() => {
        return calculateTotalBasePrice(orderDetailsObj);
    }, [orderDetailsObj]);

    const handleOnDiscountApplyClick = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let orderDiscountValue = Number(orderDiscountRef.current?.value);

        if (orderDiscountValue > 0) {
            const discountDetails = calModalityDiscountPrice(
                orderDetailsObj.study,
                orderDetailsObj.modalityDiscount,
                orderDiscountValue
            );
            if (!discountDetails.discountStatus) {
                setDiscountStatus(DiscountStatus.FAILED);
                return;
            } else {
                setDiscountStatus(DiscountStatus.SUCCESS);
                setAppliedDiscount({
                    discountedPrice: discountDetails.AfterDiscountTotalPrice,
                    percentage: orderDiscountValue,
                });
            }
        } else {
            setDiscountStatus(DiscountStatus.FAILED);
        }
    };

    const getMachineId = useCallback(
        (orderStudyId: string) => {
            let centerMachineId = null;
            if (
                centerMachineTimeSlotsData &&
                centerMachineTimeSlotsData?.length &&
                !isCombinedSlots
            ) {
                centerMachineId = centerMachineTimeSlotsData.find(
                    (slotObj: any) =>
                        slotObj?.[0].modality_id ===
                        studySlotsData[orderStudyId].modalityId
                )?.[0]?.id;
            }
            return centerMachineId ? centerMachineId : null;
        },
        [centerMachineTimeSlotsData]
    );

    const handleOnBookSlot = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (
            Object.values(studySlotsData).filter((value) => value.slot === '')
                .length
        ) {
            toast.error('Timeslot is empty. Please pick a slot');
            return;
        }
        Object.values(studySlotsData).forEach((value) => {
            if (!value.slot) {
                toast.error('Timeslot is empty. Please pick a slot.');
                return;
            }
            const slotDateTime = mergeDateAndTime(value.date, value.slot);
            if (new Date() > slotDateTime) {
                toast.error(
                    'Select timeslot is not valid. Please pick different slot.'
                );
                return;
            }
        });
        if (patientData.phone.length < 10) {
            toast.error('Contact number must be 10 digits');
            return;
        }

        setIsClicked(true);

        await addPatient(patientData).then((patient) => {
            if (patient) {
                const bookedOrderObj = {
                    accountType: user.accountType ?? '',
                    role: user.role ?? '',
                    studyList: orderDetailsObj.study.map((study: any) => {
                        const studyTimeSlot = studySlotsData[study.id];
                        return {
                            ...study,
                            studyId: study.id,
                            centerId: orderDetailsObj.centerId,
                            scheduledDateTime: mergeDateAndTime(
                                studyTimeSlot.date,
                                studyTimeSlot.slot
                            ).toISOString(),
                            centerMachineId: getMachineId(study.id),
                        };
                    }),
                    type: orderDetailsObj.type,
                    date: new Date(), // order created date
                    patientId: patient.id,
                    centerId: orderDetailsObj.centerId,
                    modalityDiscount: orderDetailsObj.modalityDiscount,
                    mrpTotalPrice: orderDetailsObj.priceDetails.totalPrice,
                    discount: appliedDiscount.percentage,
                    physicianId: physicians.value,
                    packageId: orderDetailsObj.packageId
                        ? orderDetailsObj.packageId
                        : null,
                    ...(orderDetailsObj?.packageId
                        ? {
                              totalPrice:
                                  orderDetailsObj.priceDetails
                                      .priceAfterDiscount,
                              priceToCenter:
                                  orderDetailsObj.priceDetails.priceToCenter,
                              priceToProvider:
                                  orderDetailsObj.priceDetails.priceToProvider,
                              providerCommission:
                                  orderDetailsObj.priceDetails
                                      .providerCommission,
                              aggCommission:
                                  orderDetailsObj.priceDetails.aggCommission,
                          }
                        : {}),
                };

                addOrder(bookedOrderObj).then(async (bookedData: any) => {
                    setIsClicked(false);
                    if (selectedFileToUpload.length > 0) {
                        const formData = new FormData();
                        selectedFileToUpload.map((file: File, index: number) =>
                            formData.append(`${index}`, file)
                        );
                        formData.append('bookingId', bookedData.addOrder.id);
                        await mutateUploadDocumentOrder({
                            formData: formData,
                            id: bookedData.addOrder.id,
                        });
                    }
                    setOrderVerifyId(bookedData.addOrder.id);
                });
            }
        });
    };

    const handleChangeCenterAndBookSlot = () => {
        let isTimeSlotSelected = false;
        const updateData = {
            orderId: changeCenterDetails.orderDetails?.id,
            centerId: orderDetailsObj.centerId,
            orderStudyList: changeCenterDetails.orderDetails.orderStudy.map(
                (orderStudy: any) => {
                    const studyTimeSlot = studySlotsData[orderStudy.study.id];
                    isTimeSlotSelected = !!studyTimeSlot.slot;
                    return {
                        orderStudyId: orderStudy.id,
                        scheduledDateTime: mergeDateAndTime(
                            studyTimeSlot.date,
                            studyTimeSlot.slot
                        ).toISOString(),
                        centerMachineId: getMachineId(orderStudy.study.id),
                    };
                }
            ),
        };

        if (!isTimeSlotSelected) {
            toast.error('Please pick a time slot.');
            return;
        }
        mutateUpdateCenterInOrder(updateData).then(handleCloseAllModal);
    };

    const renderStudyAndSlotSelectionComponent = () => {
        return (
            <div
                className={`p-2 w-full  ${
                    isCombinedSlots &&
                    'flex flex-col md:flex-row border-dotted border-2 rounded-lg my-2'
                }`}
            >
                <div
                    className={`w-full divide-y  ${
                        isCombinedSlots ? 'border' : 'border-dotted border-2'
                    } rounded`}
                >
                    {Object.values(studySlotsData)?.map((study, index) => {
                        if (isCombinedSlots) {
                            daysTimeSlotsByDate =
                                centerMachineTimeSlotsData?.filter(
                                    (slotObj: any) =>
                                        slotObj.date_time ===
                                        format(study?.date, 'MM/dd/yyyy')
                                )?.[0];
                        } else {
                            const timeSlotsArray =
                                centerMachineTimeSlotsData?.filter(
                                    (centerTimeSlot: any) =>
                                        centerTimeSlot?.[0]?.modality_id ===
                                        study.modalityId
                                );
                            console.log({ timeSlotsArray });
                            daysTimeSlotsByDate = timeSlotsArray?.[0]?.filter(
                                (slotObj: any) =>
                                    slotObj?.date_time ===
                                    format(study?.date, 'MM/dd/yyyy')
                            )?.[0];
                        }

                        return (
                            <div key={`i_${index}`}>
                                <div className="w-full px-4 md:px-8 py-2 grid grid-flow-row md:grid-cols-5 gap-4 justify-between">
                                    <div className="col-span-1 flex flex-col">
                                        <p className="text-xs py-2 text-gray-500">
                                            Study Name:
                                        </p>
                                        <p className="font-semibold">
                                            {study.studyName}
                                        </p>
                                        {study.studyPrice && (
                                            <p className="text-sm font-semibold text-gray-400">
                                                Rs.
                                                {study.studyPrice}
                                            </p>
                                        )}
                                    </div>
                                    {!isCombinedSlots && (
                                        <div className="col-span-4 w-full flex flex-col md:flex-row gap-2 justify-between md:px-4 py-2">
                                            <div>
                                                <p className="text-xs py-2 text-gray-500">
                                                    Choose Date:
                                                </p>
                                                <DatePicker
                                                    selected={study.date}
                                                    minDate={new Date()}
                                                    maxDate={getNextTenDaysInCalendar()}
                                                    onChange={(date: Date) => {
                                                        handleStudyDateSelect(
                                                            date,
                                                            Object.keys(
                                                                studySlotsData
                                                            )[index]
                                                        );
                                                        setIsClicked(false);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="border border-gray-200 bg-white text-sm font-semibold tracking-wider z-[0] inline p-2 rounded focus:ring-1 ring-primary-500 outline-none  cursor-pointer relative  "
                                                />
                                            </div>

                                            <div className="w-full">
                                                <TimeSlotV2
                                                    timeSlotData={
                                                        daysTimeSlotsByDate?.timings
                                                    }
                                                    selectedSlot={study.slot}
                                                    selectedDate={study.date}
                                                    isLoading={
                                                        centerMachineTimeSlotLoader
                                                    }
                                                    handleOnSlotSelect={(val) =>
                                                        handleStudySlotSelect(
                                                            val,
                                                            Object.keys(
                                                                studySlotsData
                                                            )[index],
                                                            studySlotsData,
                                                            setStudySlotsData
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {study?.testPreparation && (
                                    <div className="mx-4">
                                        <b>Test Preparation: </b>
                                        {study?.testPreparation
                                            ?.trim()
                                            ?.split(';')
                                            .filter((val) => val !== '')
                                            ?.map(
                                                (value: any, index: number) => {
                                                    const preparation =
                                                        value.trim();
                                                    return (
                                                        <li key={index}>
                                                            {preparation}
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="m-2 md:m-0 w-full">
                    {isCombinedSlots && (
                        <div className="col-span-4 w-full flex flex-col gap-2 justify-between md:px-4 py-2">
                            <div>
                                <p className="text-xs py-2 text-gray-500">
                                    Choose Date:
                                </p>
                                <DatePicker
                                    selected={
                                        studySlotsData[
                                            Object.keys(studySlotsData)[0]
                                        ]?.date
                                    }
                                    minDate={new Date()}
                                    maxDate={getNextTenDaysInCalendar()}
                                    onChange={(date: Date) => {
                                        handleStudyDateSelect(
                                            date,
                                            Object.keys(studySlotsData)[0]
                                        );
                                        setIsClicked(false);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="border border-gray-200 bg-white text-sm font-semibold tracking-wider z-[0] inline p-2 rounded focus:ring-1 ring-primary-500 outline-none  cursor-pointer relative  "
                                />
                            </div>

                            <div className="w-full">
                                <TimeSlotV2
                                    timeSlotData={daysTimeSlotsByDate?.timings}
                                    selectedSlot={
                                        studySlotsData[
                                            Object.keys(studySlotsData)[0]
                                        ]?.slot
                                    }
                                    selectedDate={
                                        studySlotsData[
                                            Object.keys(studySlotsData)[0]
                                        ]?.date
                                    }
                                    isLoading={centerMachineTimeSlotLoader}
                                    handleOnSlotSelect={(val) =>
                                        handleStudySlotSelect(
                                            val,
                                            Object.keys(studySlotsData)[0],
                                            studySlotsData,
                                            setStudySlotsData,
                                            isCombinedSlots
                                        )
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderPatientDetialsInputFormComponent = () => {
        return (
            <form
                onSubmit={handleOnBookSlot}
                className="col-span-4 flex flex-col"
            >
                <div className="px-4 w-full pt-2">
                    <h3 className="text-md font-semibold leading-6 text-gray-900">
                        Patient details
                    </h3>
                </div>
                <div className="w-full rounded-md">
                    <div className="bg-white flex flex-col gap-2 p-4">
                        <div className="flex flex-col sm:flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="name"
                                    className="block text-sm "
                                >
                                    Full Name
                                </label>
                                <input
                                    required
                                    value={patientData.name}
                                    onChange={(e) => {
                                        setPatientData((prev) => {
                                            return {
                                                ...prev,
                                                name: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="last-name"
                                    id="name"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="email"
                                    className="block text-sm"
                                >
                                    Email
                                </label>
                                <input
                                    required
                                    value={patientData.email}
                                    onChange={(e) => {
                                        setPatientData((prev) => {
                                            return {
                                                ...prev,
                                                email: e.target.value,
                                            };
                                        });
                                    }}
                                    type="email"
                                    name="email"
                                    id="email-address"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="phone"
                                    className="block text-sm"
                                >
                                    Phone
                                </label>
                                <input
                                    required
                                    value={patientData.phone}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setPatientData((prev) => {
                                                return {
                                                    ...prev,
                                                    phone: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="tel"
                                    name="number"
                                    id="number"
                                    maxLength={10}
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label htmlFor="age" className="block text-sm ">
                                    Age
                                </label>
                                <input
                                    required
                                    value={patientData.age}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setPatientData((prev) => {
                                                return {
                                                    ...prev,
                                                    age: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="number"
                                    pattern="[0-9]*"
                                    name="age"
                                    id="age"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="gender"
                                    className="block text-sm "
                                >
                                    Gender
                                </label>
                                <select
                                    required
                                    value={patientData.gender}
                                    onChange={(e) => {
                                        setPatientData((prev) => {
                                            return {
                                                ...prev,
                                                gender: e.target.value,
                                            };
                                        });
                                    }}
                                    id="gender"
                                    name="gender"
                                    className="p-2 bg-white focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                            <div className="flex-1">
                                <label
                                    className="block text-gray-600  text-sm"
                                    htmlFor="modality"
                                >
                                    Referring Physician
                                </label>
                                <div id="modality" className="h-[30px]">
                                    <Select
                                        styles={customStyles}
                                        isLoading={physicianListLoading}
                                        menuPlacement="top"
                                        value={physicians}
                                        options={physicianOptions}
                                        onChange={handlePhysicianChange}
                                    />
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* =============================== DOCUMENT UPLOAD  STARTS HERE ======================*/}
                <div
                    className={
                        'w-full flex rounded-sm justify-end items-start gap-2  bg-white mt-2 px-4'
                    }
                >
                    <div
                        className="flex flex-col items-end"
                        onClick={() => {
                            uploadDocsModal.openModal();
                        }}
                    >
                        <label className="flex justify-between items-center gap-2 cursor-pointer text-sm px-4 py-3 overflow-hidden font-medium bg-primary-500 text-white rounded">
                            Add Document
                            <AiOutlineFileAdd size={18} />
                        </label>

                        <div className={'text-gray-500 text-xs'}>
                            <p>
                                Uploads&nbsp;:&nbsp;
                                {selectedFile.length}
                            </p>
                        </div>
                    </div>
                </div>

                {/* =============================== PATIENT DETAILS FORM HERE ======================*/}

                <div className="w-full flex justify-end items-center gap-4 p-4">
                    {hasPrepaidFeature && (
                        <div className="mr-8 flex items-center">
                            <input
                                id="paid"
                                type="checkbox"
                                defaultChecked={false}
                                className={'mr-3 w-5 h-5 cursor-pointer'}
                                onChange={(e) => {
                                    setPatientData((prev: any) => {
                                        return {
                                            ...prev,
                                            isPrepaid: e.target.checked,
                                        };
                                    });
                                }}
                            />{' '}
                            <label
                                htmlFor="paid"
                                className=" text-sm font-medium text-gray-700"
                            >
                                Already paid?
                            </label>
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={
                            isClicked ||
                            isAddOrderLoading ||
                            isAddPatientLoading
                        }
                        className={`py-2 px-14 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50 focus:outline-none`}
                    >
                        Book Slot
                    </button>
                </div>
            </form>
        );
    };

    const renderOrderDiscountComponent = () => {
        return (
            <div className="w-full col-span-2 flex flex-col gap-4 px-4">
                <div className="text-md font-semibold">
                    {orderDetailsObj.center}
                </div>
                {!orderDetailsObj?.packageId && (
                    <div>
                        <form
                            onSubmit={handleOnDiscountApplyClick}
                            className={`${
                                discountStatus === DiscountStatus.NONE
                                    ? 'border-gray-200'
                                    : discountStatus === DiscountStatus.SUCCESS
                                    ? 'border-green-300'
                                    : 'border-red-400'
                            } w-full flex flex-row border rounded overflow-hidden`}
                        >
                            <input
                                type="number"
                                placeholder="Discount(%)"
                                ref={orderDiscountRef}
                                className="w-full p-2 outline-none"
                            />
                            <button
                                type="submit"
                                className="px-4 text-sm font-medium rounded text-primary-600 bg-primary-50 transition-all duration-200"
                            >
                                Apply
                            </button>
                        </form>
                        {Boolean(totalContrastPrice) && (
                            <p className="text-right text-xs text-gray-500 italic">
                                *discount is applied only to the total base
                                price
                            </p>
                        )}
                    </div>
                )}
                <div className="border-gray-200 border rounded">
                    {Boolean(totalContrastPrice) && (
                        <>
                            <div className="flex justify-between px-4 py-2 text-xs border-b">
                                <span>Total Base Price:</span>
                                <p className="">
                                    Rs.
                                    {totalBasePrice}
                                    /-
                                </p>
                            </div>
                            <div className="flex justify-between px-4 py-2 text-xs border-b">
                                <span>Total Contrast Price:</span>
                                <p className="">
                                    Rs.
                                    {totalContrastPrice}
                                    /-
                                </p>
                            </div>
                        </>
                    )}
                    <div className="flex justify-between px-4 py-2 text-xs border-b">
                        <span>Total MRP:</span>
                        <p className="">
                            Rs.
                            {orderDetailsObj.priceDetails.totalPrice}
                            /-
                        </p>
                    </div>
                    {!orderDetailsObj?.packageId && (
                        <div className="flex justify-between px-4 py-2 text-xs border-b">
                            <span>Discount Applied:</span>
                            <p className="font-semibold">
                                <span>
                                    Rs.
                                    {orderDetailsObj.priceDetails.totalPrice -
                                        appliedDiscount.discountedPrice}
                                </span>
                                <span className="text-green-600">
                                    ({appliedDiscount.percentage}
                                    %)
                                </span>
                            </p>
                        </div>
                    )}
                    <div>
                        <div className="flex justify-between px-4 py-2 text-sm">
                            <span>To be paid:</span>
                            <span className="font-semibold">
                                Rs.
                                {appliedDiscount.discountedPrice}
                                /-
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderChangeCenterAndBookSlotButton = () => {
        return (
            <Loader isLoading={isUpdateCenterInOrderLoading}>
                <div className="py-4 w-full flex justify-end items-end gap-4 px-6">
                    <button
                        onClick={() => handleCloseAllModal()}
                        className="py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded text-gray-900 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleChangeCenterAndBookSlot}
                        className={`
                    bg-primary-500 hover:bg-primary-700 py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded text-white focus:outline-none disabled:opacity-30`}
                    >
                        Change Center
                    </button>
                </div>
            </Loader>
        );
    };
    return (
        <div className="w-full">
            {orderVerifyId ? (
                <OrderVerification
                    discountedPrice={
                        orderDetailsObj.priceDetails.priceAfterDiscount
                    }
                    handleClose={handleClose}
                    orderId={orderVerifyId}
                />
            ) : (
                <div className="mt-10 sm:mt-0">
                    <div className="">
                        <div className="flex w-full justify-between mt-4 px-2  border-b-2 border-gray-200">
                            <h3 className="text-md font-bold leading-6 text-gray-900 mb-4 ml-6">
                                Choose a slot
                            </h3>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={handleClose}
                            >
                                <AiFillCloseCircle
                                    className={
                                        'hover:drop-shadow-lg rounded-[50%] '
                                    }
                                    size={24}
                                />
                            </div>
                        </div>
                        <div className="mt-2 md:mt-0 md:col-span-2">
                            <div className="flex flex-col justify-between items-start gap-4">
                                {renderStudyAndSlotSelectionComponent()}
                                <div className="w-full grid grid-cols-6 divide-x p-4">
                                    {!changeCenterDetails.isChangeCenter &&
                                        renderOrderDiscountComponent()}
                                    {/* =============================== */}
                                    {!changeCenterDetails.isChangeCenter &&
                                        renderPatientDetialsInputFormComponent()}
                                </div>

                                {changeCenterDetails.isChangeCenter &&
                                    renderChangeCenterAndBookSlotButton()}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <AnimatePresence initial={false} mode="wait">
                {uploadDocsModal.modal && (
                    <ModalComponent
                        handleClose={uploadDocsModal.closeModal}
                        width={'800px'}
                        min_height={'100%'}
                    >
                        <SelectDocumentsModal
                            handleClose={uploadDocsModal.closeModal}
                            selectedFile={selectedFile}
                            selectedFileToUpload={selectedFileToUpload}
                            setSelectedFile={setSelectedFile}
                            setSelectedFileToUpload={setSelectedFileToUpload}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default BookSlotsModal;
