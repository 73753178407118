import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../../ModalTemplate/ModalComponent';
import BookSlotsModal from '../../slots/BookSlotsModal';
import ReactTable, { RightArrow } from '../../templates/ReactTables/ReactTable';
import { AiFillInfoCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { userData } from '../../../redux/selectors';
import { useCreateModalState } from '../../hooks/useModalHooks';

// Enable once data populated
// import AmenityAndMachineDetails from '../../center/utils/AmenityAndMachineDetails';
// import ToolTip from '../../utils/ToolTip';

const SearchResults = ({
    data,
    typeAndStudy,
    discount = 0,
    changeCenterDetails = { orderDetails: '', isChangeCenter: false },
}) => {
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const modal = useCreateModalState();

    const [bookSlotsData, setBookSlotsData] = useState({
        centerId: '',
        center: '',
        description: '',
        location: '',
        priceDetails: {},
        modalityDiscount: [],
        study: [],
        slots: 0,
    });

    const hasPrepaidFeature = userData().prepaidFeature;

    const renderInformationIcon = (id) => {
        return (
            <AiFillInfoCircle
                className="text-sm inline cursor-pointer text-primary-500 ml-1"
                data-tip
                data-for={id}
            />
        );
    };

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Center',
                    accessor: 'center',
                    width: '500px',
                    Cell: ({ row }) => {
                        const { centerId, description, center } = row.original;
                        return (
                            <div className="flex flex-col">
                                <p>{center}</p>
                                <span
                                    className={
                                        'text-[#CBD1D4] flex items-start'
                                    }
                                >
                                    <span className="text-sm text-gray-500 italic">
                                        Description
                                    </span>
                                    <span>
                                        {renderInformationIcon(
                                            `description_${centerId}`
                                        )}
                                    </span>
                                    <ReactTooltip
                                        id={`description_${centerId}`}
                                        place="top"
                                        effect="solid"
                                        class="max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl"
                                    >
                                        <span>{description}</span>
                                    </ReactTooltip>
                                </span>
                            </div>
                        );
                    },
                },
                {
                    Header: 'Location',
                    accessor: 'location',
                    Cell: ({ row }) => (
                        <div className="text-sm text-gray-500">
                            {row.original.location}
                        </div>
                    ),
                },
                {
                    Header: 'Pincode',
                    accessor: 'pincode',
                },

                {
                    ...(!changeCenterDetails.isChangeCenter && {
                        Header: 'Price ',
                        accessor: 'priceDetails',
                        Cell: ({ row }) => {
                            const { id, totalPrice, studyList } =
                                row.original.priceDetails;
                            return (
                                <div
                                    className={
                                        'flex items-center text-gray-500 italic'
                                    }
                                >
                                    &#8377;{totalPrice}
                                    <span className={'text-[#CBD1D4]'}>
                                        {renderInformationIcon(id)}
                                        <ReactTooltip
                                            id={id}
                                            place="top"
                                            effect="solid"
                                        >
                                            {studyList.map((study, index) => {
                                                return (
                                                    <div key={index}>
                                                        <span className="text-xs">
                                                            {study.studyName}
                                                        </span>
                                                        (MRP) :
                                                        <span className="text-xs">
                                                            &nbsp;&nbsp;&#8377;
                                                            {study.price}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                            <div>
                                                Total Price
                                                (MRP):&nbsp;&nbsp;&#8377;
                                                {totalPrice}/-
                                            </div>
                                        </ReactTooltip>
                                    </span>
                                </div>
                            );
                        },
                    }),
                },
                {
                    Header: 'Distance',
                    accessor: 'distance',
                    Cell: ({ row }) => {
                        return (
                            <div className="text-sm text-gray-500">
                                ~ {row.original.distance} km
                            </div>
                        );
                    },
                },
                // Enable once data populated
                // {
                //     Header: 'Center Details',
                //     accessor: 'centerDetails',
                //     Cell: ({ row }) => {
                //         const { amenities, machineType } =
                //             row.original.centerDetails ?? {};
                //         return (
                //             <AmenityAndMachineDetails
                //                 amenities={amenities}
                //                 machineType={machineType}
                //             />
                //         );
                //     },
                // },
                {
                    Header: '',
                    accessor: 'slots',
                    Cell: ({ row }) => {
                        return (
                            <div className={'text-sm mr-6 text-gray-500'}>
                                <RightArrow />
                            </div>
                        );
                    },
                },
            ].filter((val) => val.accessor),
        [changeCenterDetails]
    );

    if (data.length === 0)
        return (
            <div className="text-lg text-center py-1 text-gray-500">
                No centers available
            </div>
        );

    const centerData = tableData?.map((data) => {
        return {
            centerId: data.id,
            center: data.centerName,
            isSchedulerEnabled: data.isSchedulerEnabled,
            location: data.address,
            pincode: data.pincode,
            distance: data.distance,
            centerDetails: data.centerDetails,
            priceDetails: {
                id: data.id,
                totalPrice: data.totalPrice,
                studyList: data.priceBreakdown,
                ...(data.packageId
                    ? {
                          priceAfterDiscount: data.priceAfterDiscount,
                          priceToCenter: data.priceToCenter,
                          priceToProvider: data.priceToProvider,
                          providerCommission: data.providerCommission,
                          aggCommission: data.aggCommission,
                          discountPercentage: discount,
                      }
                    : {}),
            },
            description: data.description ? data.description : '',
            study: data.study,
            slots: '',
            packageId: data.packageId ? data.packageId : null,
            modalityDiscount: data.modalityDiscount ?? [],
        };
    });

    return (
        <div className="my-5 border-[1px] border-[#E7EAEB] rounded bg-white">
            <div className="w-full p-4 flex flex-row justify-between items-center flex-wrap">
                <div className={'flex flex-col'}>
                    <div className="text-lg font-semibold tracking-wide">
                        Results
                    </div>
                    <p className="text-sm font-light text-gray-500">
                        Please select a center...
                    </p>
                </div>
            </div>
            <ReactTable
                columns={columns}
                data={centerData}
                modal={modal.modal}
                closeModal={modal.closeModal}
                openModal={modal.openModal}
                onRowClick={(_, data) => setBookSlotsData(data)}
            />

            <AnimatePresence initial={false} mode="wait">
                {modal.modal && (
                    <ModalComponent
                        handleClose={modal.closeModal}
                        width={'1200px'}
                    >
                        <BookSlotsModal
                            handleClose={modal.closeModal}
                            orderDetailsObj={Object.assign(
                                bookSlotsData,
                                typeAndStudy
                            )}
                            discount={discount}
                            hasPrepaidFeature={hasPrepaidFeature}
                            changeCenterDetails={changeCenterDetails}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default SearchResults;
