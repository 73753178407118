import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { steps } from '../common/Stepper';
import { useDispatch } from 'react-redux';
import { setSelectedCenters } from '../../redux/actions/actions';
import { MdLocationOn } from 'react-icons/md';
import { patientFlowStateObject } from '../../redux/selectors';
import ImageGridView from '../common/ImageViewGrid';
import Amenities from '../common/Amenities';
import { getFullPatientRoute } from '../helpers/contants';
import NoAccess from '../common/NoAccess';
import Carousel from '../common/Carousel';
import { formatPriceINR } from '../helpers/helper';
import { VscSettings } from 'react-icons/vsc';
import { IoLocationSharp } from 'react-icons/io5';
import { HiCurrencyRupee } from 'react-icons/hi';
import CenterImageCard from '../common/CenterImageCard';
import styled from 'styled-components';

const SelectCenter = () => {
    const navigate = useNavigate();
    const { selectedStudies, draftDetails } = patientFlowStateObject();

    const [viewSortOptions, setViewSortOptions] = useState<boolean>(false);

    const dispatch = useDispatch();
    const location = useLocation();

    const { handleStepper, centerList } = useOutletContext<any>();

    const handleCenterSelection = (center: any) => {
        dispatch(setSelectedCenters(center));
        handleStepper(steps.SELECT_CENTER);
        navigate(getFullPatientRoute.timeSlotInfo(), {
            state: {
                from: location.pathname,
            },
        });
    };

    const [centerSearch, setCenterSearch] = useState('');
    const [filteredCenter, setFilteredCenter] = useState<any>();

    useEffect(() => {
        let searchResult: any[] = [];
        centerList?.forEach((center: any) => {
            const center_name = center?.center?.toLowerCase() || '';
            if (center_name.includes(centerSearch?.toLowerCase())) {
                searchResult.push(center);
            }
        });
        setFilteredCenter([...searchResult]);
    }, [centerSearch, centerList]);

    const sortCenterList = ({ by }: { by: string }) => {
        let sortedCenterList = [...filteredCenter];
        if (by === 'distance') {
            sortedCenterList.sort((a: any, b: any) => {
                const Adistance = parseFloat(a.distance);
                const Bdistance = parseFloat(b.distance);
                return Adistance - Bdistance;
            });
        }
        if (by === 'price') {
            sortedCenterList.sort(
                (a: any, b: any) =>
                    a?.priceDetails?.priceAfterDiscount -
                    b?.priceDetails?.priceAfterDiscount
            );
        }
        setFilteredCenter(sortedCenterList);
    };

    if (location?.state?.from !== getFullPatientRoute.testInfo()) {
        return <NoAccess />;
    }

    return (
        <div className="flex flex-col">
            <p className="font-semibold mb-2">Select a center</p>
            <div className="flex gap-2 mb-1">
                <MdLocationOn color="1F6299" size={20} />
                <p className="font-semibold text-[#1F6299]">
                    {selectedStudies?.patientCity?.value}
                </p>
                <p className="text-[#1F6299]">|</p>
                <p className="font-semibold text-[#1F6299]">
                    {selectedStudies?.patientPincode?.value}
                </p>
            </div>
            {Boolean(draftDetails?.draftDiscount) && (
                <p className="text-sm font-semibold text-[#5EA73C] mt-2">{`${draftDetails.draftDiscount}% Discount Applied!`}</p>
            )}
            <div className="flex flex-col sm:flex-row justify-between items-end sm:items-center mb-4 mt-4">
                <div className="flex w-full justify-center sm:justify-start items-center gap-3 mb-3 sm:mb-0">
                    <input
                        className="border-[1px] border-[#808080] rounded-full w-full sm:w-2/3 max-w-[400px] h-10 pl-4 pr-3 text-[12px]"
                        value={centerSearch}
                        onChange={(value) => {
                            setCenterSearch(value.target.value);
                        }}
                        placeholder="Search for center"
                    />
                </div>
                <div className="flex justify-end items-center p-[1%] gap-2 relative">
                    <span className="font-medium">Sort centers</span>
                    <VscSettings
                        size={20}
                        style={{ transform: 'rotate(90deg)' }}
                        onClick={() => {
                            setViewSortOptions((prev) => !prev);
                        }}
                    />
                    {viewSortOptions && (
                        <div className="flex flex-col w-[200px]  absolute top-[80%] bg-white p-4 border rounded-lg justify-start items-start gap-2 z-10">
                            <button
                                className="flex gap-1 justify-start items-center"
                                onClick={() => {
                                    sortCenterList({ by: 'distance' });
                                    setViewSortOptions((prev) => !prev);
                                }}
                            >
                                <IoLocationSharp size={20} />
                                By distance
                            </button>
                            <button
                                className="flex gap-1 justify-start items-center"
                                onClick={() => {
                                    sortCenterList({ by: 'price' });
                                    setViewSortOptions((prev) => !prev);
                                }}
                            >
                                <HiCurrencyRupee size={20} />
                                By price
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <ParentContainer>
                {filteredCenter?.length === 0 ? (
                    <NoAccess text="No Center Found!" />
                ) : (
                    filteredCenter?.map?.((center: any) => {
                        return (
                            <div
                                className="flex flex-col sm:flex-row  w-full border rounded-2xl shadow"
                                key={center?.centerId}
                            >
                                <div className="hidden sm:block w-[20%]">
                                    <ImageGridView
                                        imageArray={center?.centerImages}
                                        centerName={center?.center}
                                    />
                                </div>
                                <div className="sm:hidden">
                                    {center?.centerImages?.length > 0 ? (
                                        <Carousel
                                            CardComponent={CenterImageCard}
                                            data={center?.centerImages}
                                            breakpoints={{
                                                // when window width is >= 640px
                                                200: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            paginationInsideImage={true}
                                        />
                                    ) : (
                                        <div className="h-36 flex items-center justify-center">
                                            <img
                                                src={'../../DefaultImage.png'}
                                                alt="DefaultImage"
                                                className="max-h-[100%] max-w-[100%] object-contain"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="w-full flex">
                                    <div className="flex flex-col sm:shadow border-r w-[70%] sm:w-[80%] justify-start items-start px-5 pt-4 pb-3 gap-2 text-sm sm:text-md">
                                        <p className="font-semibold">
                                            {center?.center}
                                        </p>
                                        <p className="text-[#707070] text-sm flex">
                                            <MdLocationOn
                                                color="1F6299"
                                                size={20}
                                            />
                                            {center?.areaLocation},{' '}
                                            {center?.city}
                                        </p>
                                        <p className="text-[#707070] text-sm flex pl-[20px]">
                                            {center?.distance} kms away
                                        </p>
                                        <Amenities
                                            amenities={center?.amenities}
                                            machineType={center?.machineType}
                                        />
                                    </div>

                                    <div className="flex-initial flex flex-col justify-center items-center p-5 gap-2 font-semibold text-[#707070] text-xs sm:text-sm">
                                        <p>Test Price </p>
                                        <p className="flex flex-initial text-[#010423] font-semibold text-md sm:text-lg">
                                            ₹{' '}
                                            {formatPriceINR(
                                                center?.priceDetails
                                                    ?.priceAfterDiscount
                                            )}
                                        </p>
                                        <button
                                            onClick={() => {
                                                handleCenterSelection(center);
                                            }}
                                            className="bg-[#1F6299] text-white py-1 px-2 rounded-sm "
                                        >
                                            Select
                                        </button>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                        );
                    })
                )}
            </ParentContainer>
        </div>
    );
};

export default SelectCenter;

const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    height: 50vh;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
