import ModalHeader from '../../ModalTemplate/components/ModalHeader';
import QRCode from 'react-qr-code';
import { copyToClipboard } from '../../utils/CopyToClipboard';
import { MdOutlineContentCopy } from 'react-icons/md';
import { QRDetailsType } from '../aggregator/ViewAllAgg';

type ShareQRFlowLinkPropType = {
    handleClose: () => void;
    QRDetails: QRDetailsType;
};

const ShareQRFlowLink = (props: ShareQRFlowLinkPropType) => {
    const { QRDetails, handleClose } = props;
    const qrLink = `${process.env.REACT_APP_PATIENT_FLOW_BASE_URL}?user_id=${QRDetails.userId}`;

    return (
        <div>
            <ModalHeader
                title={' Scan QR to Book Tests'}
                handleClose={handleClose}
            />
            <div className="flex flex-col p-6 items-center justify-center gap-4">
                <p className="text-lg font-medium">{QRDetails.displayName}</p>
                <div className="border-2 border-black p-2 mb-4">
                    <QRCode value={qrLink} size={300} />
                </div>
                <button
                    onClick={() => {
                        copyToClipboard(qrLink);
                    }}
                    className="items-center grow sm:grow-0 justify-center flex px-6 py-3 gap-2 rounded hover:bg-primary-50 text-primary-600 border border-primary-600"
                >
                    <span>
                        <MdOutlineContentCopy />
                    </span>
                    Copy Link
                </button>
            </div>
        </div>
    );
};

export default ShareQRFlowLink;
