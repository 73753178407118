import { MdOutlineDashboard } from 'react-icons/md';
import { ImExit } from 'react-icons/im';
import { FiUsers } from 'react-icons/fi';
import { BiClinic, BiUserCircle } from 'react-icons/bi';
import {
    AiOutlineAccountBook,
    AiOutlineTransaction,
    AiOutlineCalendar,
} from 'react-icons/ai';
import { IoSettingsSharp } from 'react-icons/io5';
import { getRoutesEndPoints } from '../../router/utils/routes';
import { TbReportMedical } from 'react-icons/tb';
import {
    RiListSettingsLine,
    RiCoupon3Line,
    RiFileEditLine,
} from 'react-icons/ri';

export const userMenuItems = [
    {
        title: 'main',
        url: '',
    },
    {
        icon: MdOutlineDashboard,
        title: 'Dashboard',
        url: '/dashboard',
    },
    {
        icon: TbReportMedical,
        title: 'Referring Physicians',
        url: '/user-physicians',
    },
    {
        title: 'My Profile',
        url: '',
    },
    {
        icon: BiUserCircle,
        title: '',
        subtitle: 'Member',
        url: '/',
    },
    {
        icon: ImExit,
        title: 'Logout',
        url: '/logout',
    },
];

export const aggregatorMenuItems = [
    {
        title: 'main',
        url: '',
    },
    {
        icon: MdOutlineDashboard,
        title: 'Dashboard',
        url: getRoutesEndPoints.aggregator_admin.dashboard(),
    },
    {
        icon: RiFileEditLine,
        title: 'Draft Order',
        url: getRoutesEndPoints.aggregator_admin.draftOrder(),
    },
    {
        icon: FiUsers,
        title: 'All Users',
        url: '/users',
    },
    {
        icon: TbReportMedical,
        title: 'Referring Physicians',
        url: '/admin-physicians',
    },
    {
        icon: RiCoupon3Line,
        title: 'Coupons',
        url: '/coupons',
    },
    {
        title: 'finance',
        url: '',
    },
    {
        icon: AiOutlineAccountBook,
        title: 'Transaction Summary',
        url: '/aggregator-ledger',
    },
    {
        icon: AiOutlineTransaction,
        title: 'Transaction',
        url: '/aggregator/transaction',
    },
    {
        title: 'My Profile',
        url: '',
    },
    {
        icon: BiUserCircle,
        title: '',
        subtitle: 'Aggregator',
        url: '/',
    },
    {
        icon: IoSettingsSharp,
        title: 'Settings',
        url: '/aggregator-settings',
    },
    {
        icon: ImExit,
        title: 'Logout',
        url: '/logout',
    },
];

export const adminMenuItems = [
    {
        title: 'main',
        url: '',
    },
    {
        icon: MdOutlineDashboard,
        title: 'Dashboard',
        url: getRoutesEndPoints.admin.dashboard(),
    },
    {
        icon: RiFileEditLine,
        title: 'Draft Orders',
        url: getRoutesEndPoints.admin.aggregatorDraftOrder(),
    },
    {
        icon: BiClinic,
        title: 'All Centers',
        url: '/centers',
    },
    {
        icon: FiUsers,
        title: 'All Aggregators',
        url: '/aggregators',
    },
    {
        icon: TbReportMedical,
        title: 'Referring Physicians',
        url: '/admin-physician',
    },
    {
        icon: RiCoupon3Line,
        title: 'Coupons',
        url: '/admin-coupons',
    },
    {
        title: 'finance',
        url: '',
    },
    {
        icon: AiOutlineTransaction,
        title: 'Transactions',
        url: '/transactions',
    },
    {
        icon: AiOutlineAccountBook,
        title: 'Transaction Summary',
        url: '/admin/ledger',
    },
    {
        title: 'My Profile',
        url: '',
    },
    {
        icon: BiUserCircle,
        title: '',
        subtitle: 'Admin',
        url: '/',
    },
    {
        icon: RiListSettingsLine,
        title: 'Study Management',
        url: '/admin/study-management',
    },

    {
        icon: RiListSettingsLine,
        title: 'Modality Management',
        url: '/admin/modality-management',
    },

    {
        icon: IoSettingsSharp,
        title: 'Settings',
        url: '/admin-settings',
    },
    {
        icon: ImExit,
        title: 'Logout',
        url: '/logout',
    },
];

export const financeMenuItems = [
    {
        title: 'main',
        url: '',
    },
    {
        icon: MdOutlineDashboard,
        title: 'Dashboard',
        url: '/finance/dashboard',
    },
    {
        title: 'finance',
        url: '',
    },
    {
        icon: AiOutlineAccountBook,
        title: 'Transaction Summary',
        url: '/finance/ledger',
    },
    {
        icon: MdOutlineDashboard,
        title: 'Centers',
        url: '/finance/centers',
    },
    {
        icon: FiUsers,
        title: 'Aggregators',
        url: '/finance/aggregators',
    },
    {
        icon: AiOutlineTransaction,
        title: 'Transaction',
        url: '/finance/transaction',
    },
    {
        title: 'My Profile',
        url: '',
    },
    {
        icon: BiUserCircle,
        title: '',
        subtitle: 'Finance',
        url: '/',
    },
    // {
    //     icon: IoSettingsSharp,
    //     title: 'Settings',
    //     url: '/finance/settings',
    // },
    {
        icon: ImExit,
        title: 'Logout',
        url: '/logout',
    },
];

export const centerMenuItems = [
    {
        title: 'main',
        url: '',
    },
    {
        icon: MdOutlineDashboard,
        title: 'Dashboard',
        url: getRoutesEndPoints.center_admin.dashboard(),
    },
    {
        icon: AiOutlineCalendar,
        title: 'Calendar',
        url: getRoutesEndPoints.center_admin.machineScheduler(),
    },
    {
        title: 'finance',
        url: '',
    },
    {
        icon: AiOutlineAccountBook,
        title: 'Transaction Summary',
        url: getRoutesEndPoints.center_admin.ledgerByCustomerId(),
    },
    {
        icon: AiOutlineTransaction,
        title: 'Transaction',
        url: getRoutesEndPoints.center_admin.transaction(),
    },
    {
        title: 'My Profile',
        url: '',
    },
    {
        icon: BiUserCircle,
        title: '',
        subtitle: 'Diagnostic Center',
        url: '/',
    },
    {
        icon: IoSettingsSharp,
        title: 'Settings',
        url: getRoutesEndPoints.center_admin.settings(),
    },
    {
        icon: ImExit,
        title: 'Logout',
        url: '/logout',
    },
];
