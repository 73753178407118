import React, {
    Fragment,
    useEffect,
    useState,
} from 'react';

import {
    useRowSelect, useTable, usePagination, useGlobalFilter, useSortBy
    // @ts-ignore
} from 'react-table';
import styled from 'styled-components';

import {
    TbChevronLeft,
    TbChevronRight,
    TbChevronsLeft,
    TbChevronsRight,
} from 'react-icons/tb';

import { AiOutlineArrowRight } from 'react-icons/ai';
import GlobalFilter from '../GlobalFilter';
import { format } from 'date-fns';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { Popover, Transition } from '@headlessui/react';
import Loader from '../../utils/Loader';
import { ORDER_TYPES } from '../DashboardFilter';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

type TablePaginationType = {
    manualPagination: boolean;
    initalState: {
        pageIndex: number;
        pageRowCount: number;
        totalRows: number;
    };
    onFetchData?: (pageIndex: number, pageSize: number) => void;
    onPageForward?: (nextPage: number, pageCount: number) => void;
    onPageBackward?: () => void;
    onPageSizeChange?: (count: number) => void;
};
interface TableProps {
    data: any;
    title?: any;
    columns: any;
    modal?: boolean;
    openModal?: any;
    border?: boolean;
    closeModal?: any;
    isLoading?: boolean;
    totalRowCount?: number;
    onRowClick?: (id: any, raw: any) => void;
    topRenderComponent?: () => JSX.Element;
    manualSortBy?: {
        enable: boolean,
        onHeaderClick: (column: any) => void
    }
    searchFilter?: {
        searchValue: string;
        setSearchValue: (search: string) => void;
    };
    dateRangeFilter?: {
        manualDateFilter?: boolean,
        accessorId: string;
        defaultDateRange?: DateRangeType;
        dateRangeValue?: (date: DateRangeType) => void;
    };
    pagination?: TablePaginationType;
    setRowSelectable?: {
        show: boolean,
        rowIds: string[],
        setSelectedRowIds?: (ids: string[]) => void
        onToggleHeaderCheckBox?: (e: React.ChangeEvent<HTMLInputElement>) => void
    }
}

export type DateRangeType = {
    startDate: Date;
    endDate: Date;
    key?: string;
};

export type OrderFilterAndPaginationType = {
    type: ORDER_TYPES,
    date?: {
        startDate: Date;
        endDate: Date;
    },
    pageIndex: number,
    pageRowCount: number,
    totalRows: number,
    search?: string
    customer_ids?: string[]
    center_ids?: string[]
    statuses?: string[]
}

export enum TableFilterObjType {
    START_DATE = "start_date",
    END_DATE = "end_date",
    SEARCH = "search"
}

// Added a layer to check data is undefined or not
const ReactTable = (props: TableProps) => {
    return (
        <Loader isLoading={!props.data}>
            <CustomReactTableComponent {...props} />
        </Loader>
    )
}

export const stateReducer = (ids: string[]) => (newState: any, action: any) => {
    if (action.type === "toggleAllRowsSelected") {
        // determine if the header checkbox is selected or deselected
        // if selected then push all ids into selectedRowIds
        if (action.value) {
            return {
                ...newState,
                selectedRowIds: extractSelectedRowIds(ids)
            };
        }
        // else empty selectedRowIds state
        return { ...newState, selectedRowIds: {} };
    }
    return newState;
};

const extractSelectedRowIds = (ids: string[]) => {
    return ids.reduce((row, id) => ({ ...row, [id]: true }), {});
};


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef: any = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <input
                type="checkbox"
                ref={resolvedRef}
                {...rest}
            />
        );
    }
);

const CustomReactTableComponent = ({
    data,
    modal,
    title,
    columns,
    openModal,
    isLoading,
    closeModal,
    onRowClick,
    searchFilter,
    totalRowCount,
    border = false,
    dateRangeFilter = {
        manualDateFilter: false,
        accessorId: "",
        defaultDateRange: {} as DateRangeType,
        dateRangeValue: (date: DateRangeType) => { }
    },
    topRenderComponent: ExtraComponent,
    manualSortBy = {
        enable: false,
        onHeaderClick: () => { }
    },
    pagination = {
        manualPagination: false,
        initalState: {
            pageIndex: 0,
            pageRowCount: 10,
            totalRows: 0,
        },
    },
    setRowSelectable = {
        show: false,
        rowIds: [],
        setSelectedRowIds: () => { },
        onToggleHeaderCheckBox: () => { }
    }
}: TableProps) => {
    const { show: showRowSelectable, rowIds, setSelectedRowIds, onToggleHeaderCheckBox } = setRowSelectable
    const { enable: isManualSortEnabled, onHeaderClick } = manualSortBy
    const { accessorId, dateRangeValue, defaultDateRange, manualDateFilter } = dateRangeFilter
    const {
        manualPagination,
        initalState: paginationInitialState,
        onFetchData,
    } = pagination;

    const defaultStartDate = defaultDateRange?.startDate;
    const defaultEndDate = defaultDateRange?.endDate;

    const [dataWithFilters, setDataWithFilters] = useState([]);
    const [range, setRange] = useState<DateRangeType>({
        startDate: new Date(
            format(
                defaultStartDate ? new Date(defaultStartDate) : new Date(),
                'MM/dd/yyyy'
            )
        ),
        endDate: new Date(
            format(
                defaultEndDate ? new Date(defaultEndDate) : new Date(),
                'MM/dd/yyyy'
            )
        ),
        key: 'selection',
    });

    useEffect(() => {
        applyDateFilter(data ?? []);
    }, [data, range]);

    const {
        rows,
        page,
        nextPage,
        gotoPage,
        pageCount,
        prepareRow,
        pageOptions,
        canNextPage,
        headerGroups,
        previousPage,
        getTableProps,
        setGlobalFilter,
        canPreviousPage,
        getTableBodyProps,
        // setPageSize, // enable for custom page count feat
        // selectedFlatRows, // enable to get list of select row raw data
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    } = useTable(
        {
            columns,
            data: accessorId && !manualDateFilter ? dataWithFilters : data,
            initialState: {
                pageIndex: paginationInitialState.pageIndex,
                pageSize: paginationInitialState.pageRowCount,
                GlobalFilter: '',
            },
            ...(showRowSelectable && {
                autoResetSelectedRows: false,
                manualSortBy: isManualSortEnabled,
                stateReducer: stateReducer(rowIds),
                // eslint-disable-next-line react-hooks/rules-of-hooks
                getRowId: React.useCallback((row: any) => { return row.id }, []),
            }),
            ...(manualPagination && {
                manualPagination: manualPagination,
                pageCount: Math.ceil(
                    paginationInitialState.totalRows /
                    paginationInitialState.pageRowCount
                ),
            }),
        },
        useGlobalFilter,
        ...(showRowSelectable ? [useSortBy] : []),
        usePagination,
        ...(showRowSelectable ? [useRowSelect,
            (hooks: { visibleColumns: ((columns: any) => any[])[] }) => {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }: any) => {
                            return <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    getToggleAllRowsSelectedProps()?.onChange(e)
                                    onToggleHeaderCheckBox?.(e)
                                }}
                            />
                        },
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }: any) => (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
            }] : [])
    );

    useEffect(() => {
        if (showRowSelectable) {
            setSelectedRowIds?.(Object.keys(selectedRowIds));
        }
    }, [selectedRowIds]);

    useEffect(() => {
        onFetchData?.(pageIndex, pageSize);
    }, [pageIndex, pageSize]);

    const handleDateRange = (item: RangeKeyDict) => {
        setRange(item.selection as DateRangeType);
        dateRangeValue?.(item.selection as DateRangeType);
    };

    // return ['a']['b'] if accessorId is 'a.b'
    function getNestedPropertyValue(obj: any, propertyPath: string[]) {
        return propertyPath.reduce((value, prop) => value && value[prop], obj);
    }

    const applyDateFilter = (rawData: any) => {
        if (accessorId && !manualDateFilter && range.startDate && range.endDate) {
            dateRangeValue?.(range as DateRangeType);
            setDataWithFilters(() =>
                rawData.filter((item: any) => {
                    const key = accessorId?.split(".");
                    return (
                        new Date(format(new Date(getNestedPropertyValue(item, key)), 'yyyy/MM/dd')) >=
                        range.startDate &&
                        new Date(format(new Date(getNestedPropertyValue(item, key)), 'yyyy/MM/dd')) <=
                        range.endDate
                    );
                })

            );
        }
        return
    };

    return (
        <div className="bg-white rounded">
            <div className="py-6 flex flex-col items-center gap-4">
                {title && (
                    <div className="w-full flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between items-center gap-4 px-8">
                        <div className="flex-1 text-lg font-semibold text-center sm:text-start">
                            {title}
                            <span className="text-gray-400">
                                {Boolean(totalRowCount) &&
                                    ` (${totalRowCount})`}
                            </span>
                        </div>
                        {ExtraComponent && <ExtraComponent />}
                    </div>
                )}

                <div className="w-full px-8 flex flex-col md:flex-row justify-between items-center gap-4">
                    <div className="flex flex-col sm:gap-4 sm:flex-row grow w-full sm:w-fit sm:items-center">
                        <GlobalFilter
                            filter={searchFilter?.searchValue ?? globalFilter}
                            setFilter={searchFilter?.setSearchValue ?? setGlobalFilter}
                        />
                    </div>

                    {accessorId && (
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={`
                                        ${open
                                                ? 'text-opacity-75'
                                                : 'text-opacity-100'
                                            } group inline-flex items-center rounded bg-white px-4 py-2 text-base 
                                        border-2 border-gray-200 font-medium text-gray-600 transition-all duration-200
                                        hover:text-opacity-100 outline-none justify-center w-full h-10 md:w-96`}
                                    >
                                        <p>
                                            {` ${format(
                                                range.startDate,
                                                'dd/MM/yyyy'
                                            )} - ${format(
                                                range.endDate,
                                                'dd/MM/yyyy'
                                            )} `}
                                        </p>
                                    </Popover.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-0"
                                        enterTo="opacity-100 translate-y-1"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-1"
                                        leaveTo="opacity-0 translate-y-0"
                                    >
                                        <Popover.Panel className="absolute z-10 mt-3">
                                            <DateRangePicker
                                                ranges={[range]}
                                                months={1}
                                                direction="horizontal"
                                                moveRangeOnFirstSelection={
                                                    false
                                                }
                                                onChange={(item) =>
                                                    handleDateRange(item)
                                                }
                                                className="scale-75 min-[375px]:scale-100 absolute -translate-x-[12.5%] md:-translate-x-[30%] min-[830px]:-translate-x-1/2  z-50 shadow-md border-2"
                                                renderStaticRangeLabel={
                                                    renderCustomRangeLabel
                                                }
                                                staticRanges={[
                                                    {
                                                        label: 'Default Range',
                                                        hasCustomRendering:
                                                            true,
                                                        range: () => ({
                                                            startDate:
                                                                defaultStartDate,
                                                            endDate:
                                                                defaultEndDate,
                                                        }),
                                                        isSelected() {
                                                            return true;
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    )}
                </div>
            </div>

            <div className="overflow-x-auto">
                <Loader isLoading={isLoading || false}>
                    <table {...getTableProps()} className="min-w-full divide-y">
                        <thead className="bg-white">
                            {headerGroups.map(
                                (
                                    headerGroup: {
                                        getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                                            React.ClassAttributes<HTMLTableRowElement> &
                                            React.HTMLAttributes<HTMLTableRowElement>;
                                        headers: any[];
                                    },
                                    index: any
                                ) => (
                                    <tr
                                        key={`i_${index}`}
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {headerGroup.headers.map(
                                            (column, index) => (
                                                <th
                                                    key={`i_${index}`}
                                                    scope="col"
                                                    className={`${border ? "border" : ""} group p-4 text-left text-xs text-gray-900 font-semibold tracking-wider`}
                                                    {...Boolean(showRowSelectable) && {
                                                        ...column.getHeaderProps(column.getSortByToggleProps())
                                                    }}
                                                    onChange={() => onHeaderClick(column)}
                                                >
                                                    <div className="flex flex-row justify-start items-center">
                                                        {column.render(
                                                            'Header'
                                                        )}
                                                        <span>
                                                            {column.sortDirection === 'asc' ? (
                                                                <MdArrowDropUp className='text-gray-500' size={18} />
                                                            ) : column.sortDirection === 'desc' ? (
                                                                <MdArrowDropDown className='text-gray-500' size={18} />
                                                            ) : null}
                                                        </span>
                                                    </div>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                )
                            )}
                        </thead>
                        <tbody
                            {...getTableBodyProps()}
                            className="bg-white divide-y divide-gray-200"
                        >
                            {rows.length !== 0 ? (
                                page?.map((row: any, i: any) => {
                                    prepareRow(row);
                                    return (
                                        <TableRow
                                            key={`i_${i}`}
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                onRowClick?.(
                                                    row.original.id,
                                                    row.original
                                                );
                                                modal
                                                    ? closeModal?.()
                                                    : openModal?.();
                                            }}
                                        >
                                            {row.cells.map((cell: any) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={`${border ? "border" : ""} px-4 py-4 text-sm`}
                                                        role="cell"
                                                    >
                                                        {cell.column.Cell
                                                            .name ===
                                                            'defaultRenderer' ? (
                                                            <div className="text-sm text-stone-800">
                                                                {cell.render(
                                                                    'Cell'
                                                                )}
                                                            </div>
                                                        ) : (
                                                            cell.render('Cell')
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        align="center"
                                        colSpan={headerGroups[0].headers.length}
                                        className="text-sm text-center text-gray-500 py-2"
                                    >
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Loader>
            </div>
            <PaginationDiv>
                <div className="flex flex-row gap-2 items-center justify-center px-4">
                    {/* TODO: enable for custom page count feat */}
                    {/* <span className="text-gray-400 text-xs font-medium">
                        Rows per page:
                    </span>
                    <select
                        className="border rounded font-bold text-black px-2 py-1 outline-none"
                        value={pageSize}
                        onChange={(e) => {
                            const page = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            onPageSizeChange?.(page);
                            setPageSize(page);
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select> */}
                    <p className="text-gray-400 text-xs font-medium px-1">
                        Page&nbsp;
                        <span className="text-black font-semibold">
                            {pageIndex + 1}
                            &nbsp;of&nbsp;
                            {pageOptions.length}
                        </span>
                    </p>

                    <button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                        className="border border-gray-300 p-1 disabled:opacity-50"
                    >
                        <TbChevronsLeft />
                    </button>
                    <button
                        onClick={() => {
                            previousPage();
                        }}
                        disabled={!canPreviousPage}
                        className="border border-gray-300 p-1 disabled:opacity-50"
                    >
                        <TbChevronLeft />
                    </button>
                    <button
                        onClick={() => {
                            nextPage();
                        }}
                        disabled={!canNextPage}
                        className="border border-gray-300 p-1 disabled:opacity-50"
                    >
                        <TbChevronRight />
                    </button>
                    <button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                        className="border border-gray-300 p-1 disabled:opacity-50"
                    >
                        <TbChevronsRight />
                    </button>
                </div>
            </PaginationDiv>
        </div >
    );
};

const renderCustomRangeLabel = () => {
    return <span>Reset date</span>;
};

const PaginationDiv = styled.div`
    justify-content: right;
    padding: 1rem;
    align-content: baseline;
    align-items: center;
    display: flex;
    gap: 1em;
    color: #98a3aa;
    border-top: 1px solid #e2e8f0;
    transition: all 0.2s ease-in-out;

    button {
        cursor: pointer;
        border-radius: 50%;
        font-size: 1.5rem;
        color: #202029;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e7eaeb;
        }
    }
`;

export const RightArrow = styled(AiOutlineArrowRight)`
    font-size: 1.5em;
    color: #6366f1;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    margin-right: 0.5em;
`;

export const TableRow = styled.tr`
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #f7f7f7;
        transition: all 0.2s ease-in-out;
    }

    &:hover ${RightArrow} {
        opacity: 1;
        -ms-transform: translateX(14px) scale(1.4); /* IE 9 */
        -webkit-transform: translateX(14px) scale(1.4); /* Safari 3-8 */
        //transform: scale(1.5);
        transform: translateX(14px) scale(1.4);
    }
`;

export default ReactTable