import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateCenter } from '../../hooks/useCenter';
import { userObject } from '../../../redux/selectors';
import ModalHeader from '../../ModalTemplate/components/ModalHeader';
import SelectDocumentsModal from '../../ModalTemplate/components/SelectDocumentsModal';
import ModalComponent from '../../ModalTemplate/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { AiOutlineFileAdd } from 'react-icons/ai';
import {
    useUploadDocumentsCustomer,
    useUploadImageCenter,
} from '../../hooks/useOrder';
import { useCreateModalState } from '../../hooks/useModalHooks';
import Loader from '../../utils/Loader';
import Select, { components } from 'react-select';
import { useGetModality } from '../../hooks/useTempOrder';
import {
    CTMachineTypeOption,
    MRIMachineTypeOption,
} from '../../../patientFlow/helpers/helper';

interface AddUserProps {
    handleClose?: () => void;
}

type SelectedType = {
    value: string;
    label: string;
};

export type ModalityMachineObjectType = {
    MRI?: string;
    CT?: string;
};

type CenterDataType = {
    id: string;
    pincode: string;
    centerName: string;
    location: string;
    city: string;
    address: string;
    description: string;
    latitude: string;
    longitude: string;
    mapLink: string;
    phoneNumber: string;
    email: string;
    cityLat: string;
    cityLong: string;
    pincodeLat: string;
    pincodeLong: string;
    videoLink: string;
    parkingAvailable: string;
    ambulanceAvailable: boolean;
    wheelchairAccessibility: boolean;
};

type CenterUserDataType = {
    name: string;
    email: string;
    password: string;
    phoneNumber: string;
    company: string;
    role: string;
    prepaidFeature: boolean;
    customerId: string;
    billingName: string;
    billingAddress: string;
    gstinCode: string;
    financialEmailList: string;
};

const AddCenterModal = (props: AddUserProps) => {
    const [centerData, setCenterData] = useState<CenterDataType>({
        id: '',
        pincode: '',
        centerName: '',
        location: '',
        city: '',
        address: '',
        description: '',
        latitude: '',
        longitude: '',
        mapLink: '',
        phoneNumber: '',
        email: '',
        cityLat: '',
        cityLong: '',
        pincodeLat: '',
        pincodeLong: '',
        videoLink: '',
        parkingAvailable: '',
        ambulanceAvailable: false,
        wheelchairAccessibility: false,
    });

    const [centerUserData, setCenterUserData] = useState<CenterUserDataType>({
        name: '',
        email: '',
        password: '',
        phoneNumber: '',
        company: '',
        role: 'admin',
        prepaidFeature: true,
        customerId: '',
        billingName: '',
        billingAddress: '',
        gstinCode: '',
        financialEmailList: '',
    });

    const [selectedFile, setSelectedFile] = useState<string[]>([]);
    const [selectedImage, setSelectedImage] = useState<string[]>([]);
    const [selectedFileToUpload, setSelectedFileToUpload] = useState<File[]>(
        []
    );
    const [selectedModalities, setSelectedModalities] = useState<
        SelectedType[]
    >([]);
    const [selectedMRIMachineType, setSelectedMRIMachineType] =
        useState<SelectedType>({} as SelectedType);
    const [selectedCTMachineType, setSelectedCTMachineType] =
        useState<SelectedType>({} as SelectedType);
    const [selectedImageToUpload, setSelectedImageToUpload] = useState<File[]>(
        []
    );
    const [uploadType, setUploadType] = useState<'image' | 'document'>(
        'document'
    );

    const uploadDocsModal = useCreateModalState();
    const user = userObject();

    const { mutateAsync: mutateUploadDocumentCustomer } =
        useUploadDocumentsCustomer();

    const { mutateAsync: mutateUploadImageCenter } = useUploadImageCenter();

    const {
        mutateAsync: mutateCreateCenter,
        isLoading: isCreateCenterLoading,
    } = useCreateCenter();

    const { data: modalityList, isLoading: isModalityLoading } =
        useGetModality();

    const modalityOptions = modalityList?.map(
        (modality: { id: string; modalityName: string }) => {
            return {
                value: modality.id,
                label: modality.modalityName,
            };
        }
    );

    const handleModalitySelection = (selectionModality: any) => {
        setSelectedModalities([...selectionModality]);
    };

    const isMRIModality = selectedModalities?.some(
        (modality: SelectedType) => modality.label === 'MRI'
    );
    const isCTModality = selectedModalities?.some(
        (modality: SelectedType) => modality.label === 'CT'
    );

    const handleAddCenter = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (centerData.phoneNumber.length < 10) {
            toast.error('Contact number must be 10 digits');
            return;
        }

        if (!selectedModalities.length) {
            toast.error('Please select Modality');
            return;
        }

        if (isMRIModality && !selectedMRIMachineType.value) {
            toast.error('Please select the Machine type');
            return;
        }

        if (isCTModality && !selectedCTMachineType.value) {
            toast.error('Please select the Machine type');
            return;
        }

        const machineType: ModalityMachineObjectType =
            {} as ModalityMachineObjectType;

        if (selectedCTMachineType.label) {
            machineType['CT'] = selectedCTMachineType.value;
        }
        if (selectedMRIMachineType.label) {
            machineType['MRI'] = selectedMRIMachineType.value;
        }

        const center_data = {
            centerData: centerData,
            centerUserData: centerUserData,
            customerId: user.customerID,
            machineType,
            modalities: selectedModalities?.map((modality) => {
                return {
                    id: modality.value,
                    modalityName: modality.label,
                };
            }),
        };

        mutateCreateCenter(center_data).then(async (res) => {
            if (selectedFileToUpload.length > 0) {
                const formData = new FormData();
                selectedFileToUpload.map((file: File, index: number) => {
                    formData.append(`${index}`, file);
                });
                formData.append('bookingId', res.customerId);
                await mutateUploadDocumentCustomer({
                    formData: formData,
                    id: res.customerId,
                });
            }

            if (selectedImageToUpload.length > 0) {
                const formData = new FormData();
                selectedImageToUpload.map((file: File, index: number) => {
                    formData.append(`${index}`, file);
                });
                formData.append('bookingId', centerData.id);
                await mutateUploadImageCenter({
                    formData: formData,
                    id: centerData.id,
                });
            }

            // @ts-ignore
            props.handleClose();
        });
    };

    return (
        <div>
            <form
                onSubmit={handleAddCenter}
                className="flex flex-col justify-between items-start gap-4"
            >
                <ModalHeader
                    title={'Create New Center'}
                    handleClose={props.handleClose}
                />

                <div className="w-full px-4">
                    <div className="bg-white flex flex-col gap-4 p-4 shadow rounded-md my-4">
                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Center Name *
                                </label>
                                <input
                                    required
                                    value={centerData.centerName}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                centerName: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="center-name"
                                    id="name"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="address"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Center ID *
                                </label>
                                <input
                                    required
                                    value={centerData.id}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                id: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="address"
                                    id="address"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="address"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Address *
                                </label>
                                <input
                                    required
                                    value={centerData.address}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                address: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="address"
                                    id="address"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>

                            <div className="flex-1">
                                <label
                                    htmlFor="location"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Location *
                                </label>
                                <input
                                    required
                                    value={centerData.location}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                location: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="location"
                                    id="location"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="city"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    City *
                                </label>
                                <input
                                    required
                                    value={centerData.city}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                city: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="city"
                                    id="city"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="phone"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Pincode *
                                </label>
                                <input
                                    required
                                    value={centerData.pincode}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setCenterData((prev) => {
                                                return {
                                                    ...prev,
                                                    pincode: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="tel"
                                    name="pincode"
                                    id="pincode"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Description *
                                </label>
                                <textarea
                                    required
                                    value={centerData.description}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                description: e.target.value,
                                            };
                                        });
                                    }}
                                    name="description"
                                    id="description"
                                    rows={4}
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Center Email *
                                </label>
                                <input
                                    required
                                    value={centerData.email}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                email: e.target.value,
                                            };
                                        });
                                    }}
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="contact-number"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Contact Number *
                                </label>
                                <input
                                    required
                                    value={centerData.phoneNumber}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setCenterData((prev) => {
                                                return {
                                                    ...prev,
                                                    phoneNumber: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="tel"
                                    name="contact-number"
                                    id="contact-number"
                                    maxLength={10}
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>

                            <div className="flex-1">
                                <label
                                    htmlFor="address"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Map Link
                                </label>
                                <input
                                    value={centerData.mapLink}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                mapLink: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="mapLink"
                                    id="mapLink"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="latitude"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Center Latitude *
                                </label>
                                <input
                                    required
                                    value={centerData.latitude}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                latitude: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="latitude"
                                    id="latitude"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>

                            <div className="flex-1">
                                <label
                                    htmlFor="longitude"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Center Longitude *
                                </label>
                                <input
                                    required
                                    value={centerData.longitude}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                longitude: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="longitude"
                                    id="longitude"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>

                            {/*<div className="flex-1">*/}
                            {/*    <label*/}
                            {/*        htmlFor="cityLat"*/}
                            {/*        className="block text-sm font-medium text-gray-700"*/}
                            {/*    >*/}
                            {/*        City Latitude*/}
                            {/*    </label>*/}
                            {/*    <input*/}
                            {/*        required*/}
                            {/*        value={centerData.cityLat}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setCenterData((prev) => {*/}
                            {/*                return {*/}
                            {/*                    ...prev,*/}
                            {/*                    cityLat: e.target.value,*/}
                            {/*                };*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        type="text"*/}
                            {/*        name="cityLat"*/}
                            {/*        id="cityLat"*/}
                            {/*        autoComplete="off"*/}
                            {/*        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*<div className="flex-1">*/}
                            {/*    <label*/}
                            {/*        htmlFor="cityLong"*/}
                            {/*        className="block text-sm font-medium text-gray-700"*/}
                            {/*    >*/}
                            {/*        City Longitude*/}
                            {/*    </label>*/}
                            {/*    <input*/}
                            {/*        required*/}
                            {/*        value={centerData.cityLong}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setCenterData((prev) => {*/}
                            {/*                return {*/}
                            {/*                    ...prev,*/}
                            {/*                    cityLong: e.target.value,*/}
                            {/*                };*/}
                            {/*            });*/}
                            {/*        }}*/}
                            {/*        type="text"*/}
                            {/*        name="cityLong"*/}
                            {/*        id="cityLong"*/}
                            {/*        autoComplete="off"*/}
                            {/*        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="videoLink"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Video Link
                                </label>
                                <input
                                    value={centerData.videoLink}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                videoLink: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="videoLink"
                                    id="videoLink"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2 my-2 items-end">
                            <div
                                className={
                                    ' w-full  flex rounded-sm justify-end items-start gap-2  bg-white mt-2'
                                }
                            >
                                <div
                                    className="flex flex-col items-end"
                                    onClick={() => {
                                        uploadDocsModal.openModal();
                                        setUploadType('image');
                                    }}
                                >
                                    <label className="flex justify-between items-center cursor-pointer text-sm p-3 overflow-hidden font-medium bg-primary-500 text-white transition-all duration-200 border mx-0 rounded-md">
                                        Add Image
                                        <AiOutlineFileAdd className="text-[22px] m-0.5 p-0.5" />
                                    </label>

                                    <div className="m-0.5 p-0.5 mx-0 text-gray-500 font-medium text-sm">
                                        <p>Uploads: {selectedImage.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white flex flex-col gap-4 p-4 shadow rounded-md my-4">
                        <h3 className="text-lg font-medium text-gray-900">
                            Modalities *
                        </h3>
                        <div>
                            <Select
                                required
                                className="focus:ring-primary-500 focus:border-primary-500 outline-none"
                                options={modalityOptions}
                                isMulti
                                placeholder="Select Modality"
                                onChange={handleModalitySelection}
                                isLoading={isModalityLoading}
                            />
                        </div>

                        {(isMRIModality || isCTModality) && (
                            <React.Fragment>
                                <hr />
                                <div className="flex gap-4 items-start md:items-center flex-col md:flex-row">
                                    <p className="text-md font-medium text-gray-700">
                                        Machine Details &#58;
                                    </p>
                                    <div className="flex flex-col gap-6 sm:flex-row">
                                        {isMRIModality && (
                                            <div>
                                                <Select
                                                    required
                                                    options={
                                                        MRIMachineTypeOption
                                                    }
                                                    onChange={(selectedValue) =>
                                                        setSelectedMRIMachineType(
                                                            selectedValue!
                                                        )
                                                    }
                                                    placeholder={
                                                        'Select Machine Type'
                                                    }
                                                    components={{
                                                        Control: ({
                                                            children,
                                                            ...rest
                                                        }) => (
                                                            <components.Control
                                                                {...rest}
                                                            >
                                                                <div className="p-[6px] border-r border-gray-300 bg-gray-200 rounded-l-sm">
                                                                    MRI *
                                                                </div>{' '}
                                                                {children}
                                                            </components.Control>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {isCTModality && (
                                            <div>
                                                <Select
                                                    required
                                                    options={
                                                        CTMachineTypeOption
                                                    }
                                                    placeholder={
                                                        'Select Machine Type'
                                                    }
                                                    onChange={(selectedValue) =>
                                                        setSelectedCTMachineType(
                                                            selectedValue!
                                                        )
                                                    }
                                                    components={{
                                                        Control: ({
                                                            children,
                                                            ...rest
                                                        }) => (
                                                            <components.Control
                                                                {...rest}
                                                            >
                                                                <div className="p-[6px] border-r border-gray-300 bg-gray-200 rounded-l-sm">
                                                                    CT *
                                                                </div>{' '}
                                                                {children}
                                                            </components.Control>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="bg-white flex flex-col gap-4 p-4 shadow rounded-md my-4">
                        <h3 className="text-lg font-medium text-gray-900">
                            Amenities
                        </h3>
                        <div className="flex flex-row gap-2">
                            <label
                                htmlFor="parking"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Parking Availability &#58;
                            </label>
                            <div className="flex flex-row gap-2 my-2 md:my-0">
                                <div className="flex items-center mr-4">
                                    <input
                                        type="radio"
                                        id="parkingAtCenter"
                                        name="parkingAtCenter"
                                        value="At Center"
                                        checked={
                                            centerData.parkingAvailable ===
                                            'At Center'
                                        }
                                        onChange={(e) => {
                                            setCenterData((prev) => ({
                                                ...prev,
                                                parkingAvailable:
                                                    e.target.value,
                                            }));
                                        }}
                                        className="h-4 w-4 text-primary-500 focus:ring-primary-600 border-gray-300 rounded"
                                    />
                                    <label
                                        htmlFor="parkingAtCenter"
                                        className="ml-2 block text-sm font-medium text-gray-700"
                                    >
                                        At Center
                                    </label>
                                </div>
                                <div className="flex items-center mr-4">
                                    <input
                                        type="radio"
                                        id="parkingNearCenter"
                                        name="parkingNearCenter"
                                        value="Near Center"
                                        checked={
                                            centerData.parkingAvailable ===
                                            'Near Center'
                                        }
                                        onChange={(e) => {
                                            setCenterData((prev) => ({
                                                ...prev,
                                                parkingAvailable:
                                                    e.target.value,
                                            }));
                                        }}
                                        className="h-4 w-4 text-primary-500 focus:ring-primary-600 border-gray-300 rounded"
                                    />
                                    <label
                                        htmlFor="parkingNearCenter"
                                        className="ml-2 block text-sm font-medium text-gray-700"
                                    >
                                        Near Center
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id="noParking"
                                        name="noParking"
                                        value="No"
                                        checked={
                                            centerData.parkingAvailable === 'No'
                                        }
                                        onChange={(e) => {
                                            setCenterData((prev) => ({
                                                ...prev,
                                                parkingAvailable:
                                                    e.target.value,
                                            }));
                                        }}
                                        className="h-4 w-4 text-primary-500 focus:ring-primary-600 border-gray-300 rounded"
                                    />
                                    <label
                                        htmlFor="noParking"
                                        className="ml-2 block text-sm font-medium text-gray-700"
                                    >
                                        No Parking
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex items-center flex-1">
                                <input
                                    type="checkbox"
                                    id="ambulance"
                                    name="ambulance"
                                    checked={centerData.ambulanceAvailable}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                ambulanceAvailable:
                                                    e.target.checked,
                                            };
                                        });
                                    }}
                                    className="h-4 w-4 text-primary-500 focus:ring-primary-600 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="ambulance"
                                    className="ml-2 block text-sm font-medium text-gray-700"
                                >
                                    Ambulance Available
                                </label>
                            </div>

                            <div className="flex items-center flex-1">
                                <input
                                    type="checkbox"
                                    id="wheelchair"
                                    name="wheelchair"
                                    checked={centerData.wheelchairAccessibility}
                                    onChange={(e) => {
                                        setCenterData((prev) => {
                                            return {
                                                ...prev,
                                                wheelchairAccessibility:
                                                    e.target.checked,
                                            };
                                        });
                                    }}
                                    className="h-4 w-4 text-primary-500 focus:ring-primary-600 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="ambulance"
                                    className="ml-2 block text-sm font-medium text-gray-700"
                                >
                                    Wheelchair Accessibility
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white flex flex-col shadow rounded-md ">
                        <div className="p-4">
                            <h3 className="text-lg font-medium text-gray-900">
                                Create Admin Account for Center
                            </h3>
                        </div>
                        <div className="bg-white flex flex-col gap-2 px-4 rounded-md ">
                            <div className="flex flex-row gap-2">
                                <div className="flex-1">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Email *
                                    </label>
                                    <input
                                        required
                                        value={centerUserData.email}
                                        onChange={(e) => {
                                            setCenterUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    email: e.target.value,
                                                };
                                            });
                                        }}
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Password *
                                    </label>
                                    <input
                                        required
                                        value={centerUserData.password}
                                        onChange={(e) => {
                                            setCenterUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    password: e.target.value,
                                                };
                                            });
                                        }}
                                        type="password"
                                        id="password"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row gap-2 my-2">
                                <div className="flex-1">
                                    <label
                                        htmlFor="billing_name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Billing Name *
                                    </label>
                                    <input
                                        required
                                        value={centerUserData.billingName}
                                        onChange={(e) => {
                                            setCenterUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    billingName: e.target.value,
                                                };
                                            });
                                        }}
                                        type="text"
                                        name="billing_name"
                                        id="billing_name"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label
                                        htmlFor="billing_add"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Billing Address *
                                    </label>
                                    <input
                                        required
                                        value={centerUserData.billingAddress}
                                        onChange={(e) => {
                                            setCenterUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    billingAddress:
                                                        e.target.value,
                                                };
                                            });
                                        }}
                                        type="text"
                                        id="billing_add"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row gap-2 my-2 items-end">
                                <div className="flex-1">
                                    <label
                                        htmlFor="gst_no"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        GST No:
                                    </label>
                                    <input
                                        placeholder={'Optional'}
                                        value={centerUserData.gstinCode}
                                        onChange={(e) => {
                                            setCenterUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    gstinCode: e.target.value,
                                                };
                                            });
                                        }}
                                        type="text"
                                        name="gst_no"
                                        id="gst_no"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label
                                        htmlFor="billing_add"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Financial Email List{' '}
                                        <span className={'text-[11px]'}>
                                            (Comma Separated) *
                                        </span>
                                    </label>
                                    <input
                                        required
                                        value={
                                            centerUserData.financialEmailList
                                        }
                                        onChange={(e) => {
                                            setCenterUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    financialEmailList:
                                                        e.target.value,
                                                };
                                            });
                                        }}
                                        type="text"
                                        id="billing_add"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row gap-2 my-2 items-end">
                                <div
                                    className={
                                        ' w-full  flex rounded-sm justify-end items-start gap-2  bg-white mt-2'
                                    }
                                >
                                    <div
                                        className="flex flex-col items-end"
                                        onClick={() => {
                                            uploadDocsModal.openModal();
                                            setUploadType('document');
                                        }}
                                    >
                                        <label className="flex  justify-between items-center cursor-pointer text-sm p-3 overflow-hidden  font-medium bg-primary-500 text-white transition-all duration-200 border mx-0 rounded-md">
                                            Add Document
                                            <AiOutlineFileAdd
                                                className={
                                                    'text-[22px] m-0.5 p-0.5'
                                                }
                                            />
                                        </label>

                                        <div
                                            className={
                                                'm-0.5 p-0.5 mx-0 text-gray-500 font-medium text-sm'
                                            }
                                        >
                                            <p>
                                                Uploads : {selectedFile.length}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader isLoading={isCreateCenterLoading}>
                    <div className="px-6 py-4 w-full flex justify-end items-end gap-4">
                        <button
                            type="button"
                            onClick={props.handleClose}
                            className="py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded text-gray-900 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                        >
                            Cancel
                        </button>
                        <button
                            disabled={isCreateCenterLoading}
                            className={`
                        ${
                            isCreateCenterLoading
                                ? 'opacity-75'
                                : 'opacity-100 hover:bg-primary-700'
                        }
                       bg-primary-500 py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded text-white focus:outline-none`}
                        >
                            Add Center
                        </button>
                    </div>
                </Loader>
            </form>
            <AnimatePresence initial={false} mode="wait">
                {uploadDocsModal.modal && (
                    <ModalComponent
                        handleClose={uploadDocsModal.closeModal}
                        width={'800px'}
                        min_height={'100%'}
                    >
                        {uploadType === 'image' ? (
                            <SelectDocumentsModal
                                handleClose={uploadDocsModal.closeModal}
                                selectedFile={selectedImage}
                                selectedFileToUpload={selectedImageToUpload}
                                setSelectedFile={setSelectedImage}
                                setSelectedFileToUpload={
                                    setSelectedImageToUpload
                                }
                            />
                        ) : (
                            <SelectDocumentsModal
                                handleClose={uploadDocsModal.closeModal}
                                selectedFile={selectedFile}
                                selectedFileToUpload={selectedFileToUpload}
                                setSelectedFile={setSelectedFile}
                                setSelectedFileToUpload={
                                    setSelectedFileToUpload
                                }
                            />
                        )}
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AddCenterModal;
