import React, { useMemo, useState } from 'react';
import { ImLocation } from 'react-icons/im';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { steps } from '../common/Stepper';
import { patientFlowStateObject } from '../../redux/selectors';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../../components/ModalTemplate/ModalComponent';
import SelectDocumentsModal from '../../components/ModalTemplate/components/SelectDocumentsModal';
import { useCreateModalState } from '../../components/hooks/useModalHooks';
import { GrEdit } from 'react-icons/gr';
import {
    useCreatePatientFlowOrder,
    useDeletePublicDocument,
    useUploadDocumentDraftOrder,
} from '../../components/hooks/useOrder';
import { getFullPatientRoute } from '../helpers/contants';
import NoAccess from '../common/NoAccess';
import UploadDocumentsModal from '../../components/ModalTemplate/components/UploadDocumentsModal';
import { OrderDetailSourceType } from '../../constants/constant';
import { useGetDraftOrderPublic } from '../../components/hooks/useTempOrder';
import styled from 'styled-components';
import { calModalityDiscountPrice, formatPriceINR } from '../helpers/helper';
import { useGetCouponByCouponCode } from '../../components/hooks/useCoupons';
import { setSelectedCenters } from '../../redux/actions/actions';
import { toast } from 'react-toastify';
import { CouponDetailsStateType } from '../../components/aggregator/coupons/AddOrEditCoupons';
import { useDispatch } from 'react-redux';
import {
    calculateTotalBasePrice,
    calculateTotalContrastPrice,
} from '../../components/slots/Common';

const OrderSummary = () => {
    const {
        handleStepper,
        selectedFile,
        setSelectedFile,
        selectedFileToUpload,
        setSelectedFileToUpload,
        fcmToken,
    } = useOutletContext<any>();

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [couponCodeText, setCouponCodeText] = useState<string>('');
    const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
    const [couponDetails, setCouponDetails] = useState<CouponDetailsStateType>(
        {} as CouponDetailsStateType
    );

    const { selectedCenter, patientDetails, userDetails, draftDetails } =
        patientFlowStateObject();

    const addOrder = useCreatePatientFlowOrder();
    const { data: draftData } = useGetDraftOrderPublic(
        draftDetails?.draftOrderId
    );

    const { mutateAsync: couponByCouponCode, isLoading: isCouponLoading } =
        useGetCouponByCouponCode();

    const { mutateAsync: uploadDraftDocument } = useUploadDocumentDraftOrder();
    const { mutateAsync: mutateDeleteDocument } = useDeletePublicDocument();
    const uploadDocsModal = useCreateModalState();

    const renderEditButton = (route: string) => {
        return (
            <span className="px-2">
                <GrEdit
                    color="#1F6299"
                    size={13}
                    onClick={() => navigate(route)}
                />
            </span>
        );
    };

    const handleCreateOrder = async () => {
        const formData = new FormData();
        selectedFileToUpload?.map((file: any, index: number) => {
            formData.append(`${index}`, file);
        });

        const createOrderData: { [key: string]: any } = {
            name: patientDetails.name.trim(),
            email: patientDetails.email.trim(),
            phoneNumber: patientDetails.phone.trim(),
            fcmToken: fcmToken || '',
            age: patientDetails.age,
            gender: patientDetails.gender,
            ...(patientDetails.isForSomeone && {
                otherDetails: JSON.stringify({
                    contactName: patientDetails.contactName.trim(),
                }),
            }),
            studyList: JSON.stringify(selectedCenter.studyList),
            sourceType: OrderDetailSourceType.PATIENT_ORDER,
            type: 'individual',
            centerId: selectedCenter.centerId,
            centerTimeSlotId: '',
            draftId: draftDetails?.draftOrderId || '',
            patientId: patientDetails.id || '',
            draftOrderStatus: draftDetails?.status || '',
            couponId: couponDetails?.id || '',
            totalPrice: selectedCenter.priceDetails.totalPrice,
            priceToCenter: selectedCenter.priceDetails.priceToCenter,
            priceToProvider: selectedCenter.priceDetails.priceToProvider,
            providerCommission: selectedCenter.priceDetails.providerCommission,
            priceAfterDiscount: selectedCenter.priceDetails.priceAfterDiscount,
            aggCommission: selectedCenter.priceDetails.aggCommission,
            discount: draftDetails.draftDiscount
                ? String(draftDetails.draftDiscount)
                : couponDetails?.couponDiscount
                ? couponDetails?.couponDiscount
                : '0',
            physicianId: draftDetails?.physicianId || '',
            packageId: '',
        };

        Object.keys(createOrderData).map((val) => {
            formData.append(val, createOrderData[val]);
        });

        const orderDetails = await addOrder.mutateAsync({
            formData,
            userDetails,
        });
        handleStepper(steps.ORDER_SUMMARY);
        window.open(orderDetails?.data?.paymentDetails?.paymentLink, '_blank');
        navigate(getFullPatientRoute.payment(), {
            state: {
                from: location.pathname,
                qrLink: orderDetails?.data?.QRCodeDetails?.qrLink,
                paymentLink: orderDetails?.data?.paymentDetails?.paymentLink,
            },
        });
    };

    const handleCouponTextChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const formatValue = event.target.value
            .split(' ')
            .join('')
            .toUpperCase();
        setCouponCodeText(formatValue);
    };

    const totalContrastPrice = useMemo(() => {
        return calculateTotalContrastPrice(selectedCenter);
    }, [selectedCenter]);

    const totalBasePrice = useMemo(() => {
        return calculateTotalBasePrice(selectedCenter);
    }, [selectedCenter]);

    const handleCoupon = async () => {
        const fetchCouponDetails = await couponByCouponCode({
            customerId: userDetails.customerId,
            couponCode: couponCodeText,
            userId: userDetails.id,
        });

        if (!fetchCouponDetails?.id) {
            setCouponCodeText('');
            return toast.error('Invalid Coupon!');
        }

        const discountDetails = calModalityDiscountPrice(
            selectedCenter.study,
            selectedCenter.modalityDiscount,
            fetchCouponDetails.couponDiscount
        );

        if (discountDetails.discountStatus) {
            dispatch(
                setSelectedCenters({
                    ...selectedCenter,
                    priceDetails: {
                        ...selectedCenter.priceDetails,
                        priceAfterDiscount:
                            discountDetails.AfterDiscountTotalPrice,
                        aggCommission:
                            discountDetails.AfterDiscountTotalPrice -
                            selectedCenter.priceDetails.priceToProvider,

                        discountPercentage: couponDetails.couponDiscount,
                    },
                })
            );
            setCouponDetails(fetchCouponDetails);
            setIsCouponApplied(true);
            toast.success('Coupon Applied!');
        } else {
            setIsCouponApplied(false);
            setCouponCodeText('');
            setCouponDetails({} as CouponDetailsStateType);
            toast.error('Invalid Coupon!');
        }
    };

    const handleRemoveCoupon = () => {
        setIsCouponApplied(false);
        setCouponDetails({} as CouponDetailsStateType);
        setCouponCodeText('');
        dispatch(
            setSelectedCenters({
                ...selectedCenter,
                priceDetails: {
                    ...selectedCenter.priceDetails,
                    priceAfterDiscount: selectedCenter.priceDetails.totalPrice,
                    aggCommission:
                        selectedCenter.priceDetails.totalPrice -
                        selectedCenter.priceDetails.priceToProvider,

                    discountPercentage: '0',
                },
            })
        );
    };

    if (location?.state?.from !== getFullPatientRoute.patientInfo()) {
        return <NoAccess />;
    }
    return (
        <div>
            <div className="shadow-lg rounded-t-2xl px-8 py-4">
                <div className="flex gap-4 items-start mb-4">
                    <div className="border w-1/12 rounded-full">
                        <img
                            className="w-full rounded-full"
                            src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                            alt="image"
                        />
                    </div>
                    <div>
                        <div className="text-md font-bold">
                            <p>{selectedCenter?.center}</p>
                        </div>
                        <div className="text-xs leading-5 flex items-baseline gap-2">
                            <ImLocation color="#1890FF" />
                            <p>{selectedCenter?.location}</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between mb-4">
                    <div className="text-sm font-bold">
                        <p>{patientDetails.name}</p>
                    </div>

                    <div>
                        <button
                            onClick={uploadDocsModal.openModal}
                            className="flex text-[#1F6299] text-sm items-center gap-2"
                        >
                            Prescription
                            <span>
                                <GrEdit />
                            </span>
                        </button>
                    </div>
                </div>
                <ParentContainer>
                    {selectedCenter?.study?.map((study: any, index: number) => {
                        return (
                            <div key={`i_${index}`}>
                                <div className="text-sm flex justify-between my-4">
                                    <div className="flex gap-2 items-baseline">
                                        <span className="px-2 bg-gray-200 rounded-md text-xs flex items-center">
                                            {index + 1}
                                        </span>
                                        <p>{study.studyName}</p>
                                    </div>

                                    <div className="flex flex-col items-end">
                                        <p className="text-[#010423]">
                                            <span className="font-bold">₹</span>{' '}
                                            {formatPriceINR(+study.price)}
                                        </p>
                                        {renderEditButton(
                                            getFullPatientRoute.testInfo()
                                        )}
                                        {/* <RiDeleteBin6Fill /> */}
                                    </div>
                                </div>
                                <hr />
                            </div>
                        );
                    })}
                </ParentContainer>
                {Boolean(totalContrastPrice) && (
                    <div className="flex flex-col gap-1 mt-4">
                        <div className="text-sm flex justify-between items-center">
                            <p>Total Base Price</p>
                            <p className="text-[#010423]">
                                <span className="font-bold">₹</span>{' '}
                                {formatPriceINR(totalBasePrice)}
                            </p>
                        </div>
                        <div className="text-sm flex justify-between items-center">
                            <p>Total Contrast Price</p>
                            <p className="text-[#010423]">
                                <span className="font-bold">₹</span>{' '}
                                {formatPriceINR(totalContrastPrice)}
                            </p>
                        </div>
                    </div>
                )}
                <div className="text-sm flex justify-between items-center mt-4">
                    <p>Order Summary</p>
                    <div>
                        <p className="text-[#010423]">
                            <span className="font-bold">₹</span>{' '}
                            {formatPriceINR(
                                selectedCenter?.priceDetails?.totalPrice
                            )}
                        </p>
                    </div>
                </div>

                {!draftDetails?.draftDiscount && (
                    <div className="text-sm flex flex-col sm:flex-row justify-between sm:items-center my-4 flex-wrap gap-2">
                        <div>
                            <p>Have a coupon code?</p>
                        </div>
                        <div className="flex items-center justify-between gap-2 grow sm:grow-0">
                            <input
                                className="flex px-2 text-[#1F6299] justify-end items-center border-b-2 rounded-t h-full focus:outline-none border-[#1F6299] disabled:opacity-60"
                                disabled={isCouponApplied}
                                type="text"
                                value={couponCodeText}
                                onChange={handleCouponTextChange}
                            />
                            <div className="flex justify-center">
                                {isCouponApplied ? (
                                    <button
                                        className={
                                            'text-[#f0514f] border rounded text-sm py-1 px-4'
                                        }
                                        onClick={handleRemoveCoupon}
                                    >
                                        Remove
                                    </button>
                                ) : (
                                    <button
                                        disabled={Boolean(
                                            !couponCodeText.length ||
                                                isCouponLoading
                                        )}
                                        onClick={handleCoupon}
                                        className={
                                            'text-[#1F6299] disabled:bg-gray-200 border rounded text-sm py-1 px-4'
                                        }
                                    >
                                        Apply
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {(Boolean(draftDetails.draftDiscount) || isCouponApplied) && (
                    <div className="flex text-sm justify-between items-center mt-4">
                        <div>
                            <p>Discount applied</p>
                        </div>
                        <div>
                            <p>
                                <span className="font-bold">₹</span> -
                                {formatPriceINR(
                                    selectedCenter?.priceDetails?.totalPrice -
                                        selectedCenter?.priceDetails
                                            ?.priceAfterDiscount
                                )}{' '}
                                <span className="text-[#5EA73C]">
                                    {`(${
                                        draftDetails.draftDiscount ||
                                        couponDetails?.couponDiscount
                                    }%)`}
                                </span>
                            </p>
                        </div>
                    </div>
                )}
                {Boolean(totalContrastPrice) && (
                    <p className="text-right text-xs text-gray-500 italic">
                        *discount is applied only to the total base price
                    </p>
                )}
            </div>
            <div className="flex rounded-b-2xl  py-2 px-8 bg-[#1F6299] text-lg font-bold text-white justify-between items-center">
                <p>Total amount</p>
                <p>
                    <span className="font-bold">₹</span>{' '}
                    {formatPriceINR(
                        selectedCenter?.priceDetails?.priceAfterDiscount
                    )}
                </p>
            </div>
            <div className="flex justify-center mt-8">
                <button
                    disabled={addOrder.isLoading}
                    onClick={handleCreateOrder}
                    className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md disabled:bg-[#5d97c6] hover:bg-[#022e53]"
                >
                    Pay Now
                </button>
            </div>

            <AnimatePresence initial={false} mode="wait">
                {uploadDocsModal.modal && (
                    <ModalComponent
                        handleClose={uploadDocsModal.closeModal}
                        width={'800px'}
                        min_height={'80%'}
                    >
                        {draftDetails.draftOrderId ? (
                            <UploadDocumentsModal
                                handleClose={uploadDocsModal.closeModal}
                                //@ts-ignore
                                uploadDocument={uploadDraftDocument}
                                //@ts-ignore
                                deleteDocument={mutateDeleteDocument}
                                id={draftDetails.draftOrderId}
                                draftId={draftDetails.draftOrderId}
                                documents={draftData.documents}
                                userId={userDetails?.id}
                            />
                        ) : (
                            <SelectDocumentsModal
                                handleClose={uploadDocsModal.closeModal}
                                selectedFile={selectedFile}
                                selectedFileToUpload={selectedFileToUpload}
                                setSelectedFile={setSelectedFile}
                                setSelectedFileToUpload={
                                    setSelectedFileToUpload
                                }
                            />
                        )}
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default OrderSummary;

const ParentContainer = styled.div`
    max-height: 40vh;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
