export const getRoutesEndPoints = Object.freeze({
    auth: {
        login: () => '/',
    },
    aggregator: {
        dashboard: () => '/dashboard',
        order: () => '/order',
        physician: () => '/user-physicians',
    },
    aggregator_admin: {
        dashboard: () => '/aggregator/dashboard',
        draftOrder: () => `/aggregator/draft`,
        settings: () => '/aggregator-settings',
        ledgerByCustomerId: () => '/aggregator-ledger/:customerId',
        order: () => '/aggregator-order',
        users: () => '/users',
        userById: () => '/users/:userId',
        transaction: () => '/aggregator/transaction',
        physician: () => '/admin-physicians',
        coupons: () => '/coupons',
    },
    admin: {
        dashboard: () => '/admin/dashboard',
        transaction: () => '/transactions',
        adminOrder: () => '/admin-order',
        adminSettings: () => '/admin-settings',
        adminLedger: () => '/admin/ledger',
        adminCoupons: () => '/admin-coupons',

        centers: () => '/centers',
        centerByCenterId: () => '/centers/:centerId',
        centerMachineCalendar: (centerId: string | null = ':centerId') =>
            `/centers/calender/${centerId}`,
        centerMachineSettings: (centerId: string | null = ':centerId') =>
            `/center/settings/machine/${centerId}`,
        centerAddMachine: (centerId: string | null = ':centerId') =>
            `/center/add/machine/${centerId}`,
        centerEditMachineByMachineId: (
            centerId: string | null = ':centerId',
            machineId: string | null = ':machineId'
        ) => `/center/${centerId}/edit/machine/${machineId}`,

        aggregators: () => '/aggregators',
        aggregatorByCustomerId: () => '/aggregators/:customerId',
        aggregatorDraftOrder: () => `/admin/aggregator/draft`,

        packagesByCustomerId: () => '/aggregators/packages/:customerId',
        packageById: () => '/aggregators/view/package/:packageId',
        studyManagement: () => '/admin/study-management',
        modalityManagement: () => '/admin/modality-management',

        physician: () => '/admin-physician',
        editModalityDiscount: (customerId: string | null = ':customerId') =>
            `/admin/aggregators/${customerId}/edit-discount`,
    },
    finance_admin: {
        dashboard: () => '/finance/dashboard',
        invoiceByCustomerId: () => '/invoice/:customerId',
        centers: () => '/finance/centers',
        centerByCustomerId: () => '/finance/centers/:customerId/:centerId',
        aggregators: () => '/finance/aggregators',
        aggregatorByCustomerId: () => '/finance/aggregators/:customerId',
        ledger: () => '/finance/ledger',
        transaction: () => '/finance/transaction',
        packagesByCustomerId: (customerId: string | null = ':customerId') =>
            `/finance/aggregators/packages/${customerId}`,
    },
    center_admin: {
        dashboard: () => '/center/dashboard',
        ledgerByCustomerId: () => '/center/ledger/:customerId',
        transaction: () => '/center/transaction',
        settings: () => '/center/settings',
        invoiceSettings: () => '/center/settings/invoice',
        machineSettings: () => '/center/settings/machine',
        addMachine: () => '/center/add/machine',
        editMachineByMachineId: (machineId: string | null = ':machineId') =>
            `/center/edit/machine/${machineId}`,
        machineScheduler: () => '/center/scheduler',
    },
    patientFlow: {
        patientFlowHome: () => '/patient',
        createOrder: () => '/patient/create',
    },
    public: {
        paymentComplete: () => '/payment-complete',
        slotAvailability: () => '/slot-availability',
        success: () => '/success',
        noMatch: () => '*',
    },
});
