import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFetchOrderById } from '../hooks/useOrder';
import axiosInstance from '../../services/axiosInstance';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../ModalTemplate/ModalComponent';
import EditPatient from '../order/EditOrder/EditPatient';
import { MdModeEdit } from 'react-icons/md';
import { userObject } from '../../redux/selectors';
import CenterDetailsCard from '../ModalTemplate/components/CenterDetailsCard';
import ModalHeader from '../ModalTemplate/components/ModalHeader';
import StudiesViewCard from '../ModalTemplate/components/StudiesViewCard';
import PatientDetailAndPriceCard from '../ModalTemplate/components/PatientDetailAndPriceCard';
import ModalSpinner from '../ModalTemplate/components/ModalSpinner';
import { OrderLevelStatus, statusEnabledForEditPatient } from '../utils';
import { CaslUserRoles } from '../../casl/ability';
import { getRoutesEndPoints } from '../../router/utils/routes';
import { useCreateModalState } from '../hooks/useModalHooks';

const OrderVerification = ({ handleClose, orderId, discountedPrice }) => {
    const [sentPayment, setSentPayment] = useState(false);
    const [payStatus, setPayStatus] = useState(false);

    const { data: orderDetail } = useFetchOrderById(orderId);

    const navigation = useNavigate();

    const editModal = useCreateModalState();

    const handleTimeFormat = (hour) => {
        let suffix = hour >= 12 ? ' PM' : ' AM';
        return ((parseInt(hour) + 11) % 12) + 1 + suffix;
    };

    const handleStudyName = (data) => {
        let studyText = '';
        return data?.map((studies, index) => {
            return (index ? ', ' : '') + studyText + studies.study.studyName;
        });
    };

    const returnPricePercentage = (price, discount) => {
        return (
            parseInt(price) -
            (parseInt(price) - (parseInt(price) * parseInt(discount)) / 100)
        );
    };

    const handleStudyPrice = (data) => {
        let price = 0;
        return data?.map((studies) => {
            return (price += studies.study.price);
        });
    };

    const queryClient = useQueryClient();

    const user = userObject();

    useEffect(() => {
        if (orderDetail?.status === OrderLevelStatus.PAYMENT_RECEIVED) {
            setPayStatus(true);
        }
    }, [orderDetail]);

    const routeUser = (user, res) => {
        if (
            user?.accountType === CaslUserRoles.SERVICE_PROVIDER &&
            user?.role === CaslUserRoles.ADMIN
        ) {
            navigation(getRoutesEndPoints.admin.dashboard(), { replace: true });
            toast.success(res.data.status);
        } else if (
            user?.accountType === CaslUserRoles.AGGREGATOR &&
            user?.role === CaslUserRoles.AGGREGATOR_ADMIN
        ) {
            navigation(getRoutesEndPoints.aggregator_admin.order(), {
                replace: true,
            });
            toast.success(res.data.status);
        } else {
            navigation(getRoutesEndPoints.aggregator.dashboard(), {
                replace: true,
            });
            toast.success(res.data.status);
        }
    };

    const handleCompleteClick = () => {
        setSentPayment(true);

        if (orderDetail?.patient.isPrepaid) {
            axiosInstance
                .post('/send-notification', { orderId: orderId })
                .then((res) => {
                    queryClient.invalidateQueries().then(() => {
                        setSentPayment(false);
                        routeUser(user, res);
                        handleClose();
                    });
                })
                .catch((err) => {
                    toast.error('Failed to send payment link');
                    setSentPayment(false);
                    console.log(err);
                });
        } else {
            axiosInstance
                .post('/send-payment', { orderId: orderId })
                .then((res) => {
                    queryClient.invalidateQueries().then(() => {
                        handleClose();
                        setSentPayment(false);
                        routeUser(user, res);
                    });
                })
                .catch((err) => {
                    toast.error('Failed to send payment link');
                    setSentPayment(false);
                    console.log(err);
                });
        }
    };

    if (!orderDetail) return <ModalSpinner />;

    return (
        <>
            <div className="flex flex-col bg-white items-start gap-4">
                <ModalHeader
                    title={'Order Verification'}
                    handleClose={handleClose}
                />
                {!!orderDetail ? (
                    <div className="flex flex-col w-full">
                        <div className="w-full flex flex-col justify-start px-8 mb-4">
                            <CenterDetailsCard orderDetail={orderDetail} />

                            <StudiesViewCard orderDetail={orderDetail} />

                            <div className="flex flex-row justify-start items-center gap-2 mt-6">
                                <p className="text-sm font-medium text-gray-400 uppercase">
                                    Patient Details
                                </p>

                                {orderDetail?.statusName &&
                                    statusEnabledForEditPatient.includes(
                                        orderDetail?.statusName
                                    ) && (
                                        <MdModeEdit
                                            onClick={() => {
                                                editModal.modal
                                                    ? editModal.closeModal()
                                                    : editModal.openModal();
                                            }}
                                            className={
                                                'p-0.5 cursor-pointer text-gray-700 hover:text-gray-900'
                                            }
                                            size={24}
                                        />
                                    )}
                            </div>

                            <PatientDetailAndPriceCard
                                orderDetail={orderDetail}
                                discountedPrice={discountedPrice}
                            />
                        </div>
                        <div className="flex flex-row justify-end text-center items-end gap-4 w-full p-4 pr-8 border-t mb-4 border-gray-200">
                            {/*<button*/}
                            {/*    onClick={() => handleClose()}*/}
                            {/*    className="px-12 py-2 font-semibold text-gray-600 hover:text-gray-900 bg-gray-200 rounded"*/}
                            {/*>*/}
                            {/*    Go Back*/}
                            {/*</button>*/}
                            <button
                                onClick={() => handleCompleteClick()}
                                disabled={sentPayment}
                                className={`${
                                    sentPayment
                                        ? 'bg-primary-200'
                                        : 'bg-primary-600 hover:bg-primary-700'
                                } px-12 py-2 text-white font-semibold rounded`}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>

            <AnimatePresence initial={false} mode="wait">
                {editModal.modal && (
                    <ModalComponent
                        handleClose={editModal.closeModal}
                        width={'800px'}
                    >
                        <EditPatient
                            handleClose={editModal.closeModal}
                            orderDetail={orderDetail}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </>
    );
};

export default OrderVerification;
