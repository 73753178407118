import { useState } from 'react';

import { AiFillCloseCircle } from 'react-icons/ai';

interface Props {
    studyDetails?: any;
    orderDetails?: any;
    handleClose: () => void;
    handleCancel: (studyId: any) => void;
    disableOnLoading?: any;
}

const CancelReasonModal = ({
    studyDetails,
    handleClose,
    disableOnLoading,
    handleCancel,
    orderDetails,
}: Props) => {
    const [reason, setReason] = useState('');

    return studyDetails ? (
        <div className="mt-0 md:col-span-2 overflow-y-scroll ">
            <div className="flex w-full justify-between mt-4 px-2  border-b-2 border-gray-200">
                <h3 className="text-md font-bold leading-6 text-gray-900 mb-4 ml-6">
                    Reason for cancellation
                </h3>
                <div className="cursor-pointer mr-2" onClick={handleClose}>
                    <AiFillCloseCircle
                        className={'hover:drop-shadow-lg rounded-[50%] '}
                        size={24}
                    />
                </div>
            </div>
            <div className={` flex flex-col gap-3 p-7`}>
                <textarea
                    id="reason"
                    placeholder="Type reason here..."
                    className="border-2 h-[100px] p-2"
                    onChange={(e) => {
                        setReason(e.target.value);
                    }}
                    value={reason}
                ></textarea>
                <div className="flex justify-end">
                    <button
                        className={`text-md ${
                            disableOnLoading ? 'bg-rose-100' : 'bg-red-500'
                        } text-white p-3 rounded-sm`}
                        onClick={() => {
                            handleCancel({
                                id: studyDetails.id,
                                reason: reason,
                            });
                        }}
                        disabled={disableOnLoading}
                    >
                        Cancel Study
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <div className="mt-0 md:col-span-2 overflow-y-scroll ">
            <div className="flex w-full justify-between mt-4 px-2  border-b-2 border-gray-200">
                <h3 className="text-md font-bold leading-6 text-gray-900 mb-4 ml-6">
                    Reason for cancellation
                </h3>
                <div className="cursor-pointer mr-2" onClick={handleClose}>
                    <AiFillCloseCircle
                        className={'hover:drop-shadow-lg rounded-[50%] '}
                        size={24}
                    />
                </div>
            </div>
            <div className={` flex flex-col gap-3 p-7`}>
                <textarea
                    id="reason"
                    placeholder="Type reason here..."
                    className="border-2 h-[100px] p-2"
                    onChange={(e) => {
                        setReason(e.target.value);
                    }}
                    value={reason}
                ></textarea>
                <div className="flex justify-end">
                    <button
                        className={`text-md ${
                            disableOnLoading ? 'bg-rose-100' : 'bg-red-500'
                        } text-white p-3 rounded-sm`}
                        onClick={async () => {
                            handleCancel({
                                id: orderDetails.id,
                                reason: reason,
                            });
                        }}
                        disabled={disableOnLoading}
                    >
                        Cancel Order
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CancelReasonModal;
