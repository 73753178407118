import { ModalityType } from '../../components/admin/center/EditCenterModal';

// formatting the number in indian standard Local string
export const formatPriceINR = (price: number) => {
    return price?.toLocaleString('en-IN').replace(/,/g, ',');
};

//To remove the title like 'Dr.' dr.' if it already exists in the name
export const removeDrTitleFromDoctor = (name: string) => {
    const titles = ['Dr.'];
    const regex = new RegExp(`^(${titles.join('|')})(?:\\s|\\.)*`, 'i');
    return name.replace(regex, '');
};

export const requestPermission = () => {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        }
    });
};

export const calModalityDiscountPrice = (
    selectedStudy: any,
    modalityDiscount: any,
    discount: number
) => {
    //calculating price per study based on the discount provided by the user
    const discountPriceForSelectedStudy = selectedStudy.map(
        (singleStudy: any) => {
            return (
                +singleStudy.basePrice * (1 - +discount / 100) +
                +singleStudy.contrastPrice
            );
        }
    );

    //adding up the total discounted price based on the discount provided by the user
    const totalPriceAfterDiscount = Math.ceil(
        discountPriceForSelectedStudy?.reduce(
            (acc: number, discountedPrice: number) => discountedPrice + acc,
            0
        )
    );

    //calculating the discount and adding them up based on the modality and exception center Discount
    const totalPresetDiscountedPrice = Math.ceil(
        selectedStudy
            .map((singleStudy: any) => {
                const selectedStudyModality = singleStudy.modality.id;
                const selectedStudyPrice = +singleStudy.basePrice;
                for (let modality of modalityDiscount) {
                    if (modality.modalityId === selectedStudyModality) {
                        return (
                            selectedStudyPrice *
                                (1 - (+modality.percentage || 0) / 100) +
                            +singleStudy.contrastPrice
                        );
                    }
                }
                return selectedStudyPrice + +singleStudy.contrastPrice;
            })
            .reduce(
                (acc: number, discountedPrice: number) => discountedPrice + acc,
                0
            )
    );

    if (totalPriceAfterDiscount >= totalPresetDiscountedPrice) {
        return {
            selectedStudyDiscountPriceList: discountPriceForSelectedStudy,
            discountStatus: true,
            AfterDiscountTotalPrice: totalPriceAfterDiscount,
        };
    } else {
        return {
            selectedStudyDiscountPriceList: discountPriceForSelectedStudy,
            discountStatus: false,
            AfterDiscountTotalPrice: totalPriceAfterDiscount,
        };
    }
};

export const getPreSelectedModality = (modalities: ModalityType[]) =>
    modalities?.map((modalityObj) => {
        return {
            value: modalityObj.id,
            label: modalityObj.modalityName,
        };
    });

export const getMachineType = (machineType: string) => {
    if (machineType) return { value: machineType, label: machineType };
    else return null;
};

export const testimonials = [
    {
        name: 'Dumpy Kumar',
        location: 'Hyderabad',
        testimonial:
            'The Patient Experience Manager made the process easy for me, even though I went alone. Receiving everything on WhatsApp was very convenient.',
    },
    {
        name: 'Vakkalgaddi Srikant',
        location: 'Bengaluru',
        testimonial:
            'The reports were shared on WhatsApp, which made it easy for me to access them at any time. The entire process was smooth, and I faced no challenges.',
    },
    {
        name: 'Amit Kumar Singh',
        location: 'Goa',
        testimonial:
            'I selected a diagnostic center and went there with a friend. We appreciate receiving all the information on WhatsApp, which makes the process more convenient. I had no issues while using this feature and the experience manager from the 5C Network was extremely helpful.',
    },
    {
        name: 'Mohammed Faisal Iqbal',
        location: 'Bengaluru',
        testimonial:
            'The experience was smooth, and I received updates on WhatsApp. There were no challenges. I recommend this hassle-free and convenient feature by 5C.',
    },
    {
        name: 'Kajal Panigrahi',
        location: 'Hyderabad',
        testimonial:
            'The entire process was easy to navigate, and the added convenience of receiving everything on WhatsApp made it even more seamless. We encountered no issues while using this feature.',
    },
    {
        name: 'Poorva Lohiya',
        location: 'Bengaluru',
        testimonial: `Thanks to the convenience of choosing a nearby diagnostic center and the 5C Network's smooth and hassle-free process, the entire experience was great.`,
    },
];

export const CTMachineTypeOption = [
    { value: '4 Slices', label: '4 Slices' },
    { value: '8 Slices', label: '8 Slices' },
    { value: '16 Slices', label: '16 Slices' },
    { value: '32 Slices', label: '32 Slices' },
    { value: '40 Slices', label: '40 Slices' },
    { value: '64 Slices', label: '64 Slices' },
    { value: '128 Slices', label: '128 Slices' },
    { value: '256 Slices', label: '256 Slices' },
    { value: '320 Slices', label: '320 Slices' },
];

export const MRIMachineTypeOption = [
    { value: '0.2 Tesla', label: '0.2 Tesla' },
    { value: '0.3 Tesla', label: '0.3 Tesla' },
    { value: '0.4 Tesla', label: '0.4 Tesla' },
    { value: '0.5 Tesla', label: '0.5 Tesla' },
    { value: '1.5 Tesla', label: '1.5 Tesla' },
    { value: '3 Tesla', label: '3 Tesla' },
    { value: '7 Tesla', label: '7 Tesla' },
];
