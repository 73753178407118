import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Subject } from '@casl/ability';

export enum CaslActions {
    READ = 'read',
    WRITE = 'write',
    APPROVE = 'approve',
    REJECT = 'reject',
    VISIT = 'visit',
}

export enum CaslUserRoles {
    // Account Types
    AGGREGATOR = 'aggregator',
    SERVICE_PROVIDER = 'service_provider',
    DIAGNOSTICS_CENTER = 'diagnostic_center',
    // User Roles
    AGGREGATOR_ADMIN = 'admin',
    AGGREGATOR_USER = 'member',
    ADMIN = 'admin',
    FINANCE_ADMIN = 'admin_finance',
    CENTER_ADMIN = 'admin',
}

export enum CaslSubjects {
    // admin
    DRAFT_AGGREGATOR_FILTER = 'draft_aggregator_filter',
    ORDER_STUDY_CANCEL_OPTION = 'order_study_cancel_option',
}

export type CaslTypes = [CaslActions, CaslUserRoles | Subject];
export type ClaimsType = {
    action: CaslActions;
    subject: CaslUserRoles;
    conditions?: {
        [condition: string]: any;
    };
};

export type ContextualCanProps = {
    I: string;
    a: string;
    not?: boolean;
};

export { subject } from '@casl/ability';
export const AbilityContext = createContext<any>({});
export const ContextualCan = createContextualCan(AbilityContext.Consumer);
