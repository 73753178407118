import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { firebaseConfig } from './firebase/config';
import { setFirebaseToken, verifyUserAuth } from './redux/actions/actions';
import { useDispatch } from 'react-redux';
import { AbilityContext, CaslTypes, CaslUserRoles } from './casl/ability';
import { createMongoAbility } from '@casl/ability';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import LayoutWrapper from './components/layout/LayoutWrapper';
import ProtectedRoute from './router/ProtectedRoute';
import { getRoutesEndPoints } from './router/utils/routes';
import { getPatientFlowRoutes } from './router/utils/patientRoutes';
import PublicLayoutWrapper from './components/layout/PublicLayoutWrapper';
import '@firebase/auth';

import HomePage from './patientFlow/components/HomePage';
import Layout from './patientFlow/layout/Layout';
import SelectTimeSlot from './patientFlow/components/SelectTimeSlot';
import PatientInfo from './patientFlow/components/PatientInfo';
import OrderSummary from './patientFlow/components/OrderSummary';
import TestInfo from './patientFlow/components/TestInfo';
import PatientFlow from './patientFlow/PatientFlow';
import SelectCenter from './patientFlow/components/SelectCenter';
import PaymentPage from './patientFlow/components/PaymentPage';
import PaymentCompletePage from './patientFlow/components/PaymentCompletePage';
import { useAppSelector } from './redux/hook';

const AddOrder = lazy(() => import('./components/order/AddOrder'));
const LoginPage = lazy(() => import('./components/login/index'));
const ViewOrder = lazy(() => import('./components/order/ViewOrder'));

const PaymentComplete = lazy(
    () => import('./components/templates/PaymentComplete')
);
const SlotAvailability = lazy(
    () => import('./components/templates/SlotAvailability')
);
const ThankYouPage = lazy(() => import('./components/templates/ThankYou'));

const AdminViewOrder = lazy(
    () => import('./components/admin/order/AdminViewOrder')
);
const ViewAllCenters = lazy(
    () => import('./components/admin/center/ViewAllCenters')
);
const ViewAllUser = lazy(
    () => import('./components/aggregator/user/ViewAllUser')
);
const ViewAllAgg = lazy(
    () => import('./components/admin/aggregator/ViewAllAgg')
);
const AggViewOrder = lazy(
    () => import('./components/aggregator/order/AggViewOrder')
);
const ViewEachAgg = lazy(
    () => import('./components/admin/aggregator/ViewEachAgg')
);
const ViewEachUserOrders = lazy(
    () => import('./components/aggregator/user/ViewEachUserOrders')
);
const ViewEachCenter = lazy(
    () => import('./components/admin/center/ViewEachCenter')
);
const ViewAllTransaction = lazy(
    () => import('./components/admin/transaction/ViewAllTransaction')
);
const AggSettings = lazy(() => import('./components/aggregator/AggSettings'));
const AdminSettings = lazy(() => import('./components/admin/AdminSettings'));
const AllLedger = lazy(
    () => import('./components/finance/transactions/AllLedger')
);
const CenterLedger = lazy(
    () => import('./components/finance/transactions/CenterLedger')
);

const AggregatorLedger = lazy(
    () => import('./components/finance/transactions/AggregatorLedger')
);
const ViewTransactionAgg = lazy(
    () => import('./components/aggregator/transaction/ViewTransactionAgg')
);
const Invoices = lazy(
    () => import('./components/finance/transactions/Invoices')
);
const FinanceViewOrder = lazy(
    () => import('./components/finance/order/FinanceViewOrder')
);
const MachineCalendarView = lazy(
    () => import('./components/center/scheduler/MachineCalendarView')
);
const AddOrEditMachine = lazy(
    () => import('./components/center/machine/AddOrEditMachine')
);
const CenterViewTransaction = lazy(
    () => import('./components/center/transaction/CenterViewTransaction')
);
const CenterInvoiceSettings = lazy(
    () => import('./components/center/settings/CenterInvoiceSettings')
);
const CenterViewTransactionSummary = lazy(
    () => import('./components/center/transaction/CenterViewTransactionSummary')
);
const CenterViewOrder = lazy(
    () => import('./components/center/order/CenterViewOrder')
);
const RefferingPhysician = lazy(
    () => import('./components/aggregator/user/RefferingPhysician')
);
const ViewAllPackages = lazy(
    () => import('./components/admin/aggregator/ViewAllPackages')
);
const CenterMachineSettings = lazy(
    () => import('./components/center/settings/CenterMachineSettings')
);
const CenterSettings = lazy(
    () => import('./components/center/settings/CenterSettings')
);
const StudyManagement = lazy(
    () => import('./components/admin/study/StudyManagement')
);
const ModalityManagement = lazy(
    () => import('./components/admin/study/ModalityManagement')
);
const ViewEachPackage = lazy(
    () => import('./components/admin/aggregator/ViewEachPackage')
);
const EditModalityDiscount = lazy(
    () => import('./components/admin/modality/EditModalityDiscount')
);
const ViewAllCoupons = lazy(
    () => import('./components/aggregator/coupons/ViewAllCoupons')
);
const AggDraftOrder = lazy(
    () => import('./components/aggregator/order/AggDraftOrder')
);

function App() {
    const dispatch = useDispatch();
    const { UserClaimsReduxState } = useAppSelector((state) => state);
    const ability = createMongoAbility<CaslTypes>(UserClaimsReduxState || []);

    initializeApp(firebaseConfig);

    useEffect(() => {
        dispatch(verifyUserAuth(true));
        onAuthStateChanged(getAuth(), async (user) => {
            const idToken = await user?.getIdToken(true);

            if (idToken) {
                dispatch(setFirebaseToken(idToken));
                dispatch(verifyUserAuth(false));
            }
        });
    }, []);

    return (
        <AbilityContext.Provider value={ability}>
            <Router>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    transition={Slide}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />

                <Routes>
                    <Route element={<LayoutWrapper />}>
                        <Route
                            path={getRoutesEndPoints.aggregator_admin.ledgerByCustomerId()}
                            element={<AggregatorLedger />}
                        />
                        <Route
                            path={getRoutesEndPoints.aggregator_admin.users()}
                            element={<ViewAllUser />}
                        />
                        <Route
                            path={getRoutesEndPoints.aggregator_admin.userById()}
                            element={<ViewEachUserOrders />}
                        />
                        <Route
                            path={getRoutesEndPoints.aggregator_admin.order()}
                            element={<AddOrder />}
                        />
                        <Route
                            path={getRoutesEndPoints.aggregator_admin.transaction()}
                            element={<ViewTransactionAgg />}
                        />
                    </Route>

                    {/* AGGREGATOR ROUTES */}
                    <Route
                        element={
                            <ProtectedRoute
                                allowedAccountType={CaslUserRoles.AGGREGATOR}
                                allowedRoles={CaslUserRoles.AGGREGATOR_USER}
                            />
                        }
                    >
                        <Route element={<LayoutWrapper />}>
                            <Route
                                path={getRoutesEndPoints.aggregator.dashboard()}
                                element={<ViewOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator.physician()}
                                element={<RefferingPhysician />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator.order()}
                                element={<AddOrder />}
                            />
                        </Route>
                    </Route>

                    {/* AGGREGATOR_ADMIN ROUTES */}
                    <Route
                        element={
                            <ProtectedRoute
                                allowedAccountType={CaslUserRoles.AGGREGATOR}
                                allowedRoles={CaslUserRoles.AGGREGATOR_ADMIN}
                            />
                        }
                    >
                        <Route element={<LayoutWrapper />}>
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.dashboard()}
                                element={<AggViewOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.draftOrder()}
                                element={<AggDraftOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.settings()}
                                element={<AggSettings />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.ledgerByCustomerId()}
                                element={<AggregatorLedger />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.users()}
                                element={<ViewAllUser />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.userById()}
                                element={<ViewEachUserOrders />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.order()}
                                element={<AddOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.physician()}
                                element={<RefferingPhysician />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.transaction()}
                                element={<ViewTransactionAgg />}
                            />
                            <Route
                                path={getRoutesEndPoints.aggregator_admin.coupons()}
                                element={<ViewAllCoupons />}
                            />
                        </Route>
                    </Route>

                    {/* SERVICE PROVIDER ADMIN ROUTES */}
                    <Route
                        element={
                            <ProtectedRoute
                                allowedAccountType={
                                    CaslUserRoles.SERVICE_PROVIDER
                                }
                                allowedRoles={CaslUserRoles.ADMIN}
                            />
                        }
                    >
                        <Route element={<LayoutWrapper />}>
                            <Route
                                path={getRoutesEndPoints.admin.physician()}
                                element={<RefferingPhysician />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.dashboard()}
                                element={<AdminViewOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.studyManagement()}
                                element={<StudyManagement />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.modalityManagement()}
                                element={<ModalityManagement />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.transaction()}
                                element={<ViewAllTransaction />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.centers()}
                                element={<ViewAllCenters />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.centerByCenterId()}
                                element={<ViewEachCenter />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.centerMachineCalendar()}
                                element={<MachineCalendarView />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.centerMachineSettings()}
                                element={<CenterMachineSettings />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.centerAddMachine()}
                                element={<AddOrEditMachine />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.centerEditMachineByMachineId()}
                                element={<AddOrEditMachine edit />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.aggregators()}
                                element={<ViewAllAgg />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.aggregatorByCustomerId()}
                                element={<ViewEachAgg />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.aggregatorDraftOrder()}
                                element={<AggDraftOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.packagesByCustomerId()}
                                element={<ViewAllPackages />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.packageById()}
                                element={<ViewEachPackage />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.adminOrder()}
                                element={<AddOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.adminSettings()}
                                element={<AdminSettings />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.adminCoupons()}
                                element={<ViewAllCoupons />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.adminLedger()}
                                element={<AllLedger />}
                            />
                            <Route
                                path={getRoutesEndPoints.admin.editModalityDiscount()}
                                element={<EditModalityDiscount />}
                            />
                        </Route>
                    </Route>

                    {/* SERVICE PROVIDER FINANCE ADMIN ROUTES */}
                    <Route
                        element={
                            <ProtectedRoute
                                allowedAccountType={
                                    CaslUserRoles.SERVICE_PROVIDER
                                }
                                allowedRoles={CaslUserRoles.FINANCE_ADMIN}
                            />
                        }
                    >
                        <Route element={<LayoutWrapper />}>
                            <Route
                                path={getRoutesEndPoints.finance_admin.dashboard()}
                                element={<FinanceViewOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.finance_admin.invoiceByCustomerId()}
                                element={<Invoices />}
                            />
                            <Route
                                path={getRoutesEndPoints.finance_admin.centers()}
                                element={<ViewAllCenters />}
                            />

                            <Route
                                path={getRoutesEndPoints.finance_admin.centerByCustomerId()}
                                element={<CenterLedger />}
                            />

                            <Route
                                path={getRoutesEndPoints.finance_admin.aggregators()}
                                element={<ViewAllAgg />}
                            />
                            <Route
                                path={getRoutesEndPoints.finance_admin.packagesByCustomerId()}
                                element={<ViewAllPackages />}
                            />
                            <Route
                                path={getRoutesEndPoints.finance_admin.aggregatorByCustomerId()}
                                element={<AggregatorLedger />}
                            />
                            <Route
                                path={getRoutesEndPoints.finance_admin.ledger()}
                                element={<AllLedger />}
                            />
                            <Route
                                path={getRoutesEndPoints.finance_admin.transaction()}
                                element={<ViewAllTransaction />}
                            />
                        </Route>
                    </Route>

                    {/* DIAGNOSTICS CENTER ADMIN ROUTES */}
                    <Route
                        element={
                            <ProtectedRoute
                                allowedAccountType={
                                    CaslUserRoles.DIAGNOSTICS_CENTER
                                }
                                allowedRoles={CaslUserRoles.CENTER_ADMIN}
                            />
                        }
                    >
                        <Route element={<LayoutWrapper />}>
                            <Route
                                path={getRoutesEndPoints.center_admin.dashboard()}
                                element={<CenterViewOrder />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.addMachine()}
                                element={<AddOrEditMachine />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.editMachineByMachineId()}
                                element={<AddOrEditMachine edit />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.machineScheduler()}
                                element={<MachineCalendarView />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.transaction()}
                                element={<CenterViewTransaction />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.settings()}
                                element={<CenterSettings />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.invoiceSettings()}
                                element={<CenterInvoiceSettings />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.machineSettings()}
                                element={<CenterMachineSettings />}
                            />
                            <Route
                                path={getRoutesEndPoints.center_admin.ledgerByCustomerId()}
                                element={<CenterViewTransactionSummary />}
                            />
                        </Route>
                    </Route>
                    <Route
                        path={getRoutesEndPoints.patientFlow.patientFlowHome()}
                        element={
                            <Layout>
                                <HomePage />
                            </Layout>
                        }
                    />
                    <Route
                        path={getRoutesEndPoints.patientFlow.createOrder()}
                        element={
                            <Layout>
                                <PatientFlow />
                            </Layout>
                        }
                    >
                        <Route
                            path={getPatientFlowRoutes.testInfo()}
                            element={<TestInfo />}
                        />
                        <Route
                            path={getPatientFlowRoutes.centerInfo()}
                            element={<SelectCenter />}
                        />

                        <Route
                            path={getPatientFlowRoutes.timeSlotPage()}
                            element={<SelectTimeSlot />}
                        />
                        <Route
                            path={getPatientFlowRoutes.patientInfoPage()}
                            element={<PatientInfo />}
                        />
                        <Route
                            path={getPatientFlowRoutes.orderSummaryPage()}
                            element={<OrderSummary />}
                        />
                        <Route
                            path={getPatientFlowRoutes.paymentPage()}
                            element={<PaymentPage />}
                        />
                        <Route
                            path={getPatientFlowRoutes.paymentComplete()}
                            element={<PaymentCompletePage />}
                        />
                    </Route>

                    <Route element={<PublicLayoutWrapper />}>
                        <Route
                            path={getRoutesEndPoints.auth.login()}
                            element={<LoginPage />}
                        />
                        <Route
                            path={getRoutesEndPoints.public.paymentComplete()}
                            element={<PaymentComplete />}
                        />
                        <Route
                            path={getRoutesEndPoints.public.slotAvailability()}
                            element={<SlotAvailability />}
                        />
                        <Route
                            path={getRoutesEndPoints.public.success()}
                            element={<ThankYouPage />}
                        />
                    </Route>
                    <Route
                        path={getRoutesEndPoints.public.noMatch()}
                        element={<>No Match</>}
                    />
                </Routes>
            </Router>
        </AbilityContext.Provider>
    );
}

export default App;
