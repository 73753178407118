import React from 'react';
import prodigi_pass_logo from '../../assets/images/prodigi_pass_logo_xxl.png';

const Layout = ({ children }: { children: any }) => {
    return (
        <div className="">
            <div className="flex px-8 py-4 flex-row justify-between items-center">
                <div className="w-[200px]">
                    <img src={prodigi_pass_logo} alt="prodigi_pass" />
                </div>
                <div className="md:flex flex-row gap-4 items-center hidden ">
                    <div>Need Help?</div>
                    <div className="flex flex-row gap-2 items-center">
                        <div>
                            <img src="/PhoneIcon.jpg" alt="phoneIcon" />
                        </div>
                        <p>+9177607-71782</p>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div>
                            <img src="/WhatsappIcon.jpg" alt="whatsapp" />
                        </div>
                        <p>+9177607-71782</p>
                    </div>
                </div>
            </div>
            {children}
            <div className="md:hidden flex flex-col sm:flex-row gap-4 items-center justify-center max-[340px]:text-[10px] min-[340px]:text-[14px]  mb-5">
                <div>Need Help?</div>
                <div className="flex p-2 gap-4 ">
                    <div className="flex flex-row gap-2 items-center">
                        <div>
                            <img src="/PhoneIcon.jpg" alt="phoneIcon" />
                        </div>
                        <p>+9177607-71782</p>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div>
                            <img src="/WhatsappIcon.jpg" alt="whatsapp" />
                        </div>
                        <p>+9177607-71782</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
