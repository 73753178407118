import { useNavigate } from 'react-router-dom';
import ModalComponent from '../ModalTemplate/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import AddUserModal from '../aggregator/user/AddUserModal';
import AddCenterModal from '../admin/center/AddCenterModal';
import {
    MdOutlineDashboard,
    MdKeyboardArrowLeft,
    MdQrCodeScanner,
} from 'react-icons/md';
import { BiUserCircle } from 'react-icons/bi';
import { useCreateModalState } from '../hooks/useModalHooks';
import { userObject } from '../../redux/selectors';

const orderPageUrlList = ['/order', '/aggregator-order', '/admin-order'];

const Navbar = (props) => {
    const { handleQRModal } = props;
    // let params = useParams();
    const navigateTo = useNavigate();
    // const order_list = useFetchOrderByCustomerId(params.customerId);
    const { accountType, role } = userObject();

    // const aggData = useMemo(() => order_list, [order_list]);

    const userModal = useCreateModalState();
    const centerModal = useCreateModalState();

    // window.location.pathname === data.url

    return (
        <section className="h-[4.6rem] px-6 bg-white sticky hidden lg:flex items-center border-b border-gray-200 justify-between">
            {/* <div className="flex flex-col items-end px-2">
                <p className="font-semibold capitalize">{user?.name}</p>
                <p className="text-xs text-gray-500">{user?.role}</p>
            </div>
            <div className="hidden lg:block">
                <Menu>
                    <Menu.Button className="flex items-center p-1 rounded-full hover:shadow">
                        <FiUser className="w-12 h-12 p-1 rounded-full border-2 border-gray-200 object-cover object-right" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-6 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg border border-gray-200 focus:outline-none">
                            <div className="px-1 py-2">
                                <Menu.Item
                                    onClick={() => {
                                        logoutUser().then(() => {
                                            toast.success(
                                                'Logged out successfully'
                                            );
                                            navigateTo('/');
                                        });
                                    }}
                                >
                                    {({ active }) => (
                                        <button
                                            className={`${
                                                active
                                                    ? 'bg-primary-500 text-white'
                                                    : 'text-gray-500'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}
                                        >
                                            <MdLogout size={24} />
                                            Logout
                                        </button>
                                    )}
                                </Menu.Item>
    </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div> */}
            {orderPageUrlList.includes(window.location.pathname) ? (
                <button
                    onClick={() => navigateTo(-1)}
                    className="flex flex-row items-center gap-1 px-4 py-2 text-lg font-medium text-gray-700 hover:bg-gray-200 hover:text-gray-900 transition-all duration-200 rounded"
                >
                    <MdKeyboardArrowLeft size={24} />
                    <span>Create New Order</span>
                </button>
            ) : window.location.pathname.endsWith('dashboard') ? (
                <div className="flex flex-row items-center gap-2 px-4 py-2 text-lg font-medium rounded text-gray-700">
                    <MdOutlineDashboard size={24} />
                    <span>Dashboard</span>
                </div>
            ) : window.location.pathname === '/users' ? (
                <div className="flex flex-row items-center gap-2 px-4 py-2 text-lg font-medium rounded text-gray-700">
                    <BiUserCircle size={24} />
                    <span>All Users</span>
                </div>
            ) : window.location.pathname.startsWith('/users/') ? (
                <button
                    onClick={() => navigateTo(-1)}
                    className="flex flex-row items-center gap-1 px-4 py-2 text-lg font-medium text-gray-700 hover:bg-gray-200 hover:text-gray-900 transition-all duration-200 rounded"
                >
                    <MdKeyboardArrowLeft size={24} />
                    <span>Users Order Details</span>
                </button>
            ) : window.location.pathname.startsWith('/aggregators/packages') ? (
                <button
                    onClick={() => navigateTo(-1)}
                    className="flex flex-row items-center gap-1 px-4 py-2 text-lg font-medium text-gray-700 hover:bg-gray-200 hover:text-gray-900 transition-all duration-200 rounded"
                >
                    <MdKeyboardArrowLeft size={24} />
                    {/* <span>{aggData?.data?.customer?.businessName}</span> */}
                    <span>Back</span>
                </button>
            ) : window.location.pathname.startsWith(
                  '/aggregators/view/package'
              ) ? (
                <button
                    onClick={() => navigateTo(-1)}
                    className="flex flex-row items-center gap-1 px-4 py-2 text-lg font-medium text-gray-700 hover:bg-gray-200 hover:text-gray-900 transition-all duration-200 rounded"
                >
                    <MdKeyboardArrowLeft size={24} />
                    <span>Back</span>
                </button>
            ) : (
                <span></span>
            )}

            {((accountType === 'service_provider' && role === 'admin') ||
                accountType === 'aggregator') && (
                <div className="flex mr-8 hover:bg-primary-100 p-2 rounded border">
                    <MdQrCodeScanner
                        className="text-primary-600 justify-end"
                        size={32}
                        onClick={() => {
                            handleQRModal.modal
                                ? handleQRModal.closeModal()
                                : handleQRModal.openModal();
                        }}
                    />
                </div>
            )}

            <AnimatePresence initial={false} mode="wait">
                {userModal.modal && (
                    <ModalComponent
                        handleClose={userModal.closeModal}
                        width={'800px'}
                    >
                        <AddUserModal handleClose={userModal.closeModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode="wait">
                {centerModal.modal && (
                    <ModalComponent
                        handleClose={centerModal.closeModal}
                        width={'800px'}
                    >
                        <AddCenterModal handleClose={centerModal.closeModal} />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </section>
    );
};

export default Navbar;
