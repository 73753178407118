import { RiParkingFill } from 'react-icons/ri';
import { TbAmbulance } from 'react-icons/tb';
import { TfiWheelchair } from 'react-icons/tfi';
import ToolTip, { ToolTipPosition } from '../../components/utils/ToolTip';

type amenitiesType = {
    parkingAvailable: string;
    ambulanceAvailable: boolean;
    wheelchairAccessibility: boolean;
};

type AmenitiesType = {
    machineType: { MRI?: string; CT?: string };
    amenities: amenitiesType;
};
const Amenities = (props: AmenitiesType) => {
    const { amenities, machineType } = props;

    const parkingOption = ['At Center', 'Near Center'];

    return (
        <div className="flex gap-4 justify-between w-full items-center">
            <div>
                {(parkingOption.includes(amenities?.parkingAvailable) ||
                    amenities?.ambulanceAvailable ||
                    amenities?.wheelchairAccessibility) && (
                    <p className=" text-[#010423] font-medium text-sm mb-2">
                        Amenities
                    </p>
                )}
                <div className="flex gap-5 justify-start items-center flex-wrap">
                    {parkingOption.includes(amenities?.parkingAvailable) && (
                        <div className="flex gap-1 items-center">
                            <ToolTip
                                content={`Parking Available (${amenities?.parkingAvailable})`}
                                trigger={
                                    <div className="rounded-full p-1 border-[1px] border-[#1F6299] flex justify-center items-center">
                                        <RiParkingFill
                                            color="#1F6299"
                                            size={15}
                                        />
                                    </div>
                                }
                                position={ToolTipPosition.top}
                            />

                            <div className="hidden sm:flex flex-col text-xs text-[#707070]">
                                <p> Parking </p>
                                <p style={{ fontSize: 10 }}>
                                    {amenities?.parkingAvailable &&
                                        `(${amenities?.parkingAvailable})`}
                                </p>
                            </div>
                        </div>
                    )}

                    {amenities?.ambulanceAvailable && (
                        <div className="flex gap-1 items-center">
                            <ToolTip
                                content={`Ambulance Available`}
                                trigger={
                                    <div className="rounded-full p-1 border-[1px] border-[#1F6299] flex justify-center items-center">
                                        <TbAmbulance
                                            color="#1F6299"
                                            size={15}
                                        />
                                    </div>
                                }
                                position={ToolTipPosition.top}
                            />

                            <div className="hidden sm:flex flex-col text-xs text-[#707070]">
                                <p> Ambulance </p>
                            </div>
                        </div>
                    )}

                    {amenities?.wheelchairAccessibility && (
                        <div className="flex gap-1 items-center">
                            <ToolTip
                                content={`Wheelchair Assistance`}
                                trigger={
                                    <div className="rounded-full p-1 border-[1px] border-[#1F6299] flex justify-center items-center">
                                        <TfiWheelchair
                                            color="#1F6299"
                                            size={15}
                                        />
                                    </div>
                                }
                                position={ToolTipPosition.top}
                            />

                            <div className="hidden sm:flex flex-col text-xs text-[#707070]">
                                <p> Wheelchair </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-col items-end">
                {Boolean(machineType?.CT || machineType?.MRI) && (
                    <p className="text-[#010423] font-medium text-sm mb-2 ">
                        Machine Details
                    </p>
                )}
                <div className="flex text-xs text-[#1F6299] sm:text-[#707070]">
                    {machineType?.MRI && <p> {`${machineType?.MRI} MRI`} </p>}
                    {Boolean(machineType?.CT && machineType?.MRI) && (
                        <span className="border-l rotate-12 border-[#1F6299] sm:border-[#707070] mx-1">
                            {' '}
                        </span>
                    )}
                    {machineType?.CT && <p> {`${machineType?.CT} CT`} </p>}
                </div>
                </div>
        </div>
    );
};

export default Amenities;
