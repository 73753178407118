import { useEffect, useState } from 'react';
import { useGetStatusLogByOrderId } from '../../hooks/useOrder';
import ModalHeader from '../../ModalTemplate/components/ModalHeader';
import StatusNode from './StatusNode';

interface TrackOrdeProps {
    handleClose: () => void;
    orderId: string;
}

export interface allStatusType {
    id: string;
    order: any;
    orderId: string;
    statusName: string;
    updatedBy: string;
    statusLevel: string;
    bookingId: string;
    orderStudy: any;
}

const TrackOrder = (props: TrackOrdeProps) => {
    const [allStatus, setAllStatus] = useState<allStatusType[]>([]);
    const [filterStatus, setFilterStatus] = useState<allStatusType[]>([]);

    const allStatus_List = useGetStatusLogByOrderId(props.orderId);

    useEffect(() => {
        setAllStatus(allStatus_List?.data);
        setFilterStatus(allStatus_List?.data);
    }, [allStatus_List?.data]);

    let handleSelect = (event: any) => {
        if (event.target.value == 'all') {
            setFilterStatus(allStatus);
        } else {
            let filterData = allStatus?.filter((status: allStatusType) => {
                if (status.orderStudy?.study?.studyName) {
                    return (
                        status.orderStudy.study.studyName == event.target.value
                    );
                } else {
                    return false;
                }
            });
            setFilterStatus(filterData);
        }
    };

    return (
        <div>
            <ModalHeader
                title={'Order Progress'}
                handleClose={props.handleClose}
            />
            {allStatus?.[0]?.order?.type === 'individual' ? (
                <div className="flex flex-row px-4 mt-4 text-xs items-center font-medium justify-end gap-2 text-gray-500">
                    <label htmlFor="filterByStudy">Filter by booking:</label>
                    <select
                        name="filterByStudy"
                        id="filterByStudy"
                        className="border-2 focus:outline-none rounded-md p-2 bg-gray-100 border-none"
                        placeholder="Filter by study"
                        onChange={(e) => {
                            handleSelect(e);
                        }}
                    >
                        <option value="all">All</option>
                        {allStatus[0]?.order?.orderStudy?.map(
                            ({ study }: any, index: number) => {
                                return (
                                    <option
                                        value={study.studyName}
                                        key={`idd_${index}`}
                                    >
                                        {study.studyName}
                                    </option>
                                );
                            }
                        )}
                    </select>
                </div>
            ) : null}
            <StatusNode allStatus={filterStatus} />
        </div>
    );
};

export default TrackOrder;
