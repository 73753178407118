import { RadioGroup } from '@headlessui/react';
import { differenceInMinutes, format, isEqual } from 'date-fns';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type CenterDefaultScheduleType = {
    id: string;
    dayOfWeek: number;
    startTimes: string[];
    endTimes: string[];
};

export interface SlotsPropsType {
    timeSlotData: CenterDefaultScheduleType[] | undefined;
    bookedTimeSlots?: string[];
    isSchedulerEnabled: boolean | undefined;
    isMachineActive: boolean;
    selectedDate: Date;
    deferTime: number;
    isLoading: boolean;
    scanReportTime: number;
    selectedSlot: string;
    handleOnSlotSelect: (value: string) => void;
}

interface GetIntervalProps {
    startTime: string;
    endTime: string;
    interval?: number;
    date: string | Date;
    defaultStartTime: Date;
    isSchedulerEnabled: boolean;
}

/**
 * Generates an array of time intervals starting from a given start time until an end time with a specified interval.
 * @param {string} startTime - The starting time in format 'hh:mm A'.
 * @param {string} endTime - The ending time in format 'hh:mm A'.
 * @param {number} [interval=60] - The interval in minutes.
 * @param {string|Date} [date] - The date in format 'YYYY-MM-DD' or a Date object. If not provided, the current date will be used.
 * @param {Date} [defaultStartTime] - The default start time as a Date object.
 * @param {boolean} [isSchedulerEnabled=false] - Whether the scheduler is enabled or not.
 * @returns {string[]} An array of time intervals in format 'hh:mm A'.
 */

function getIntervalsOfStartTime({
    startTime,
    endTime,
    interval = 60,
    date,
    defaultStartTime,
    isSchedulerEnabled,
}: GetIntervalProps): string[] {
    // to show till eod time if start and end times are equals
    if (startTime === endTime) {
        endTime = '24:00';
    }
    const intervals: string[] = [];
    const selectedDateTime = new Date(date);
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const formattedSelectedDate = new Date(
        selectedDateTime.setHours(0, 0, 0, 0)
    );
    const deferDateTime = new Date(
        new Date(defaultStartTime).setHours(0, 0, 0, 0)
    );

    // deferTime is higher than selected datetime return null.
    if (deferDateTime > selectedDateTime) return intervals;

    // if current day and selected date OR defer DT and selected DT are equal
    // set defer end time as start time of slots.
    if (
        (isEqual(currentDate, formattedSelectedDate) ||
            isEqual(deferDateTime, formattedSelectedDate)) &&
        isSchedulerEnabled
    ) {
        startTime = format(defaultStartTime, 'p');
    } else if (
        new Date().getTime() >= new Date(`${date} ${startTime}`).getTime()
    ) {
        startTime = format(defaultStartTime, 'p');
    }
    // Used to merge date and time.
    const start = new Date(`${date} ${startTime}`);
    const end = new Date(`${date} ${endTime}`);

    let current = new Date(start);
    // If the current date is equal to selected date then slots start from
    // present time with interval period which is given.
    if (isEqual(currentDate, formattedSelectedDate)) {
        const minutes = current.getMinutes();
        const newMinutes = minutes + interval - (minutes % interval);
        if (newMinutes >= 60) {
            const extraHours = Math.floor(newMinutes / 60);
            current.setHours(current.getHours() + extraHours);
            current.setMinutes(newMinutes % 60);
        } else {
            current.setMinutes(newMinutes);
        }
    }

    let iteration = 0;
    while (current < end && iteration < 120) {
        intervals.push(formatTime(current));
        current = new Date(current.getTime() + interval * 60 * 1000);
        iteration++;
    }

    // Used to check minutes difference b/w last slot in intervals and interval minute
    const diffOfLastInterval = differenceInMinutes(
        end,
        new Date(`${date} ${intervals[Number(intervals.length - 1)]}`)
    );
    if (diffOfLastInterval < interval) {
        intervals.splice(-1);
    }
    return intervals;
}

function formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const handleGetSlotList = (
    defaultTimeSlots: CenterDefaultScheduleType[] | undefined,
    isSchedulerEnabled: boolean | undefined,
    isMachineActive: boolean,
    selectedDate: Date,
    deferTime: number,
    scanReportTime: number
) => {
    const dayOfWeek = new Date(selectedDate).getDay();
    const defaultTime: string[] = [];

    // If scheduled for this center enabled, will return default slot time
    // OR else, return the slots of that particular modality machine
    if (isSchedulerEnabled !== undefined && !isSchedulerEnabled) {
        defaultTime.push(
            ...getIntervalsOfStartTime({
                startTime: '08:00 AM',
                endTime: '10:00 PM',
                interval: 60,
                date: format(selectedDate, 'MM/dd/yyyy'),
                defaultStartTime: new Date(),
                isSchedulerEnabled: isSchedulerEnabled || false,
            })
        );
        return defaultTime;
    }

    if (isMachineActive === undefined && !isMachineActive) return defaultTime;

    const addedTime = new Date().getTime() + deferTime * 60 * 60 * 1000;

    let defaultDateTimeWithDefer = new Date(new Date().setTime(addedTime));

    defaultTimeSlots?.map(
        (value) =>
            dayOfWeek === value.dayOfWeek &&
            Array(value.startTimes.length)
                .fill(0)
                .map((_, index) =>
                    defaultTime.push(
                        ...getIntervalsOfStartTime({
                            startTime: value.startTimes[index],
                            endTime: value.endTimes[index],
                            interval: scanReportTime || 60,
                            date: format(selectedDate, 'MM/dd/yyyy'),
                            defaultStartTime: defaultDateTimeWithDefer,
                            isSchedulerEnabled: isSchedulerEnabled || false,
                        })
                    )
                )
    );

    const currentDate = `${
        new Date().getMonth() + 1
    }-${new Date().getDate()}-${new Date().getFullYear()}`;
    return [
        ...new Set(
            defaultTime.sort((a, b) => {
                return (
                    new Date(`${currentDate} ${a}`).getTime() -
                    new Date(`${currentDate} ${b}`).getTime()
                );
            })
        ),
    ];
};

export default function TimeSlots(props: SlotsPropsType) {
    const {
        timeSlotData,
        bookedTimeSlots,
        isSchedulerEnabled,
        isMachineActive,
        deferTime,
        isLoading,
        scanReportTime,
        handleOnSlotSelect,
        selectedDate,
        selectedSlot,
    } = props;

    const [slotsList, setSlotsList] = useState<string[]>([]);

    useEffect(() => {
        selectedDate &&
            setSlotsList(
                handleGetSlotList(
                    timeSlotData,
                    isSchedulerEnabled,
                    isMachineActive,
                    selectedDate,
                    deferTime,
                    scanReportTime
                )
            );
    }, [
        timeSlotData,
        isSchedulerEnabled,
        isMachineActive,
        selectedDate,
        deferTime,
        scanReportTime,
    ]);

    return (
        <ParentContainer
            className={`${
                isLoading && isSchedulerEnabled && 'animate-pulse'
            } w-full max-h-36 overflow-y-auto border border-gray-300 bg-white shadow-inner rounded-md`}
        >
            <div className="block text-sm text-gray-600  bg-gray-100 px-2 py-1 sticky top-0">
                Available slots
            </div>
            <div className="p-2">
                {slotsList?.length ? (
                    <RadioGroup
                        value={selectedSlot}
                        onChange={(val: any) => handleOnSlotSelect(val)}
                    >
                        <div className="flex flex-wrap justify-start">
                            {slotsList.map((slotStartTime) => {
                                return (
                                    <RadioGroup.Option
                                        disabled={bookedTimeSlots?.includes(
                                            slotStartTime
                                        )}
                                        key={slotStartTime}
                                        value={slotStartTime}
                                        className={({ checked, disabled }) =>
                                            `${
                                                checked
                                                    ? 'bg-primary-500'
                                                    : `border border-gray-200 ${
                                                          disabled
                                                              ? 'opacity-60 cursor-not-allowed bg-red-100'
                                                              : 'opacity-100 cursor-pointer'
                                                      }`
                                            } static flex justify-center cursor-pointer rounded py-1 m-1 focus:outline-none transition-all duration-200`
                                        }
                                    >
                                        {({ checked, disabled }) => (
                                            <div className="text-sm px-2.5 py-0.5">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-semibold ${
                                                        disabled &&
                                                        'text-red-600'
                                                    } ${
                                                        checked
                                                            ? 'text-white'
                                                            : 'text-gray-900'
                                                    }`}
                                                >
                                                    {slotStartTime}
                                                </RadioGroup.Label>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                );
                            })}
                        </div>
                    </RadioGroup>
                ) : (
                    <p className="py-2 text-xs text-gray-500 text-center">
                        {isLoading && isSchedulerEnabled
                            ? 'Fetching Slots'
                            : 'No slots available for this date!'}
                    </p>
                )}
            </div>
        </ParentContainer>
    );
}

const ParentContainer = styled.div`
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
