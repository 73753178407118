import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

type StepperPropsType = {
    activity: string[];
};

export const steps = {
    TEST_INFO: 'testInfo',
    SELECT_CENTER: 'selectCenter',
    SELECT_SLOT: 'selectSlot',
    PATIENT_DETAILS: 'patientDetails',
    ORDER_SUMMARY: 'orderSummary',
    PAYMENT: 'payment',
};
const Stepper = (props: StepperPropsType) => {
    const { activity } = props;

    const renderCompletedActivity = (step: string) => {
        return activity.includes(step) ? (
            <span className="flex flex-col items-center justify-center w-6 h-6 rounded-full lg:h-[30px] lg:w-[30px] shrink-0">
                <FaCheckCircle size={'100%'} color="#1F6299" />
            </span>
        ) : (
            <span className="flex items-center justify-center w-6 h-6 rounded-full lg:h-[30px] lg:w-[30px] shrink-0">
                <div className="bg-white rounded-full w-full h-full border-4 border-[#1F6299]"></div>
            </span>
        );
    };

    const renderCompletedBarStyle = (step: string) => {
        return activity.includes(step)
            ? 'after:border-[#1F6299]'
            : 'after:border-gray-200';
    };
    return (
        <div className="flex items-center w-full mb-[5%] text-[#707070] scale-90 sm:scale-100 sm:px-12">
            <div
                className={`flex w-full items-center after:content-[''] relative text-xs font-semibold after:w-full after:h-1 after:border-b ${renderCompletedBarStyle(
                    steps.TEST_INFO
                )} after:border-4 after:indivne-block`}
            >
                {renderCompletedActivity(steps.TEST_INFO)}
                <span className="hidden sm:block absolute top-12 left-[-15px] px-2">
                    Test Info
                </span>
            </div>
            <div
                className={`flex w-full items-center after:content-[''] relative text-xs font-semibold after:w-full after:h-1 after:border-b ${renderCompletedBarStyle(
                    steps.SELECT_CENTER
                )} after:border-4 after:indivne-block`}
            >
                {renderCompletedActivity(steps.SELECT_CENTER)}
                <span className="hidden sm:block absolute top-12 left-[-15px] px-2">
                    Select Center
                </span>
            </div>
            <div
                className={`flex w-full items-center after:content-[''] relative text-xs font-semibold after:w-full after:h-1 after:border-b ${renderCompletedBarStyle(
                    steps.SELECT_SLOT
                )} after:border-4 after:indivne-block`}
            >
                {renderCompletedActivity(steps.SELECT_SLOT)}
                <span className="hidden sm:block absolute top-12 left-[-15px] px-2">
                    Select Slot
                </span>
            </div>
            <div
                className={`flex w-full items-center after:content-[''] relative text-xs font-semibold after:w-full after:h-1 after:border-b ${renderCompletedBarStyle(
                    steps.PATIENT_DETAILS
                )} after:border-4 after:indivne-block`}
            >
                {renderCompletedActivity(steps.PATIENT_DETAILS)}
                <span className="hidden sm:block absolute top-12 left-[-15px] px-2">
                    Patient Details
                </span>
            </div>
            <div
                className={`flex w-full items-center after:content-[''] relative text-xs font-semibold after:w-full after:h-1 after:border-b ${renderCompletedBarStyle(
                    steps.ORDER_SUMMARY
                )} after:border-4 after:indivne-block`}
            >
                {renderCompletedActivity(steps.ORDER_SUMMARY)}
                <span className="hidden sm:block absolute top-12 left-[-15px] px-2">
                    Order Summary
                </span>
            </div>
            <div className="flex items-center relative text-xs font-semibold ">
                {renderCompletedActivity(steps.PAYMENT)}
                <span className="hidden sm:block absolute top-12">Payment</span>
            </div>
        </div>
    );
};

export default Stepper;
