import React from 'react';
import { IoIosSend } from 'react-icons/io';
import axiosInstance from '../../../services/axiosInstance';
import { toast } from 'react-toastify';
import { OrderLevelStatus, StudyLevelStatus } from '../../utils';

export const getLink = async (link) => {
    const data = {
        url: link,
    };
    return await axiosInstance
        .post('/pdf', data)
        .then((res) => {
            return res.data.data;
        })
        .catch(() => {
            toast.error("Couldn't generate pdf. Contact Admin.");
        });
};

const InvoiceCard = ({
    orderDetails,
    handleSendPayment,
    handleResendPayment,
    payStatus,
}) => {
    const renderInvoiceGenerated = ({ url }) => {
        return (
            <div className="w-full flex flex-row justify-between items-center text-sm p-3 gap-2 bg-green-50 border border-green-100 rounded-md">
                <p className="font-medium text-green-600">Invoice Generated</p>

                <button
                    className="flex flex-row items-center justify-center font-medium text-white bg-green-600 hover:bg-green-500 transition-all duration-200 gap-2 px-4 py-2 rounded"
                    onClick={() => {
                        if (url.includes('cloudfront')) {
                            window.open(url, '_blank');
                        } else {
                            getLink(url).then((res) => {
                                window.open(res.url, '_blank');
                            });
                        }
                    }}
                >
                    Download
                </button>
            </div>
        );
    };

    const renderPaymentPending = () => {
        const disableResendPaymentLink = [
            OrderLevelStatus.PAYMENT_PENDING,
            OrderLevelStatus.SLOT_UNAVAILABLE,
        ];
        return (
            <div className="w-full flex flex-row justify-between items-center text-sm p-3 gap-2 bg-amber-50 border border-amber-100 rounded-md">
                <p className="font-medium text-amber-600">Payment Pending</p>
                <button
                    onClick={() => handleResendPayment()}
                    disabled={
                        !disableResendPaymentLink.includes(
                            orderDetails?.statusName
                        )
                    }
                    className={`${
                        !disableResendPaymentLink.includes(
                            orderDetails?.statusName
                        )
                            ? 'bg-amber-300 cursor-not-allowed'
                            : 'bg-amber-500 hover:bg-amber-400'
                    } flex flex-row items-center justify-center font-medium text-white gap-2 px-4 py-2 rounded transition-all duration-200`}
                >
                    <IoIosSend />
                    Resend Payment Link
                </button>
            </div>
        );
    };

    const renderSendPayment = () => {
        return (
            <div className="w-full flex flex-row justify-between items-center text-sm p-3 gap-2 bg-primary-50 border border-primary-100 rounded-md">
                <p className="font-medium text-primary-600">
                    Send Payment Link
                </p>
                <button
                    onClick={() => handleSendPayment()}
                    disabled={payStatus}
                    className={`${
                        payStatus
                            ? 'bg-primary-300 cursor-not-allowed'
                            : 'bg-primary-500 hover:bg-primary-400'
                    } flex flex-row items-center justify-center font-medium text-white gap-2 px-4 py-2 rounded transition-all duration-200`}
                >
                    <IoIosSend />
                    Confirm &amp; Send Payment
                </button>
            </div>
        );
    };

    if (
        orderDetails?.patient.isPrepaid
            ? null
            : orderDetails?.razorpayData?.length === 0
    ) {
        return renderSendPayment();
    }

    switch (orderDetails?.statusName) {
        case OrderLevelStatus.PAYMENT_PENDING:
            return renderPaymentPending();
        case OrderLevelStatus.INVOICE_GENERATED:
        case StudyLevelStatus.COMPLETED:
        case StudyLevelStatus.REPORTED:
            return orderDetails?.invoice.length > 0
                ? renderInvoiceGenerated({
                      url: orderDetails?.invoice[0].invoiceLink,
                  })
                : null;

        default:
            break;
    }
};

export default InvoiceCard;
