import axiosInstance from '../../services/axiosInstance';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CenterMachineType } from '../center/machine/AddOrEditMachine';
import { getAPIEndPoints } from '../../router/utils/api';
import { useNavigate } from 'react-router-dom';
import { getRoutesEndPoints } from '../../router/utils/routes';
export interface Center {
    centerData: {
        id: string;
        pincode: string;
        centerName: string;
        location: string;
        city: string;
        address: string;
        description: string;
        latitude: string;
        longitude: string;
        mapLink: string;
        contactPerson?: string;
        phoneNumber: string;
        cityLat?: string;
        cityLong?: string;
        pincodeLat?: string;
        pincodeLong?: string;
        videoLink?: string;
        parkingAvailable: string;
        ambulanceAvailable: boolean;
        wheelchairAccessibility: boolean;
    };
    centerUserData: {
        name?: string;
        email: string;
        password: string;
        phoneNumber?: string;
        company?: string;
        role: string;
        prepaidFeature: boolean;
        customerId?: string;
    };
    customerId: string;
}

export interface CenterBank {
    id: string;
    ifscCode?: string;
    beneficiaryName?: string;
    accountNumber?: string;
}

export interface CenterOrderType {
    centerOrderId?: string;
    centerId: string;
    patientName: string;
    scheduledDateTime: string;
    centerMachineId: string;
    modalityId: string;
}

const createCenter = async (data: Center) => {
    const res = await axiosInstance.post('/center/create', data);
    return res.data.data;
};

const editCenter = async (data: any) => {
    const res = await axiosInstance.post('/center/edit', data);
    return res.data.data;
};

const editCenterBank = async (data: CenterBank) => {
    const res = await axiosInstance.post('/center/bank', data);
    return res.data.data;
};

const getAllCenter = async () => {
    const res = await axiosInstance.get('/center');
    return res.data.data;
};

const getCenterMachines = async (centerId: string) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.center.getCenterMachines(centerId)
    );
    return res.data.data;
};

const updateProviderPriceTypeForCenter = async (data: {
    centerId: string;
    isProviderPriceType: boolean;
}) => {
    const res = await axiosInstance.patch(
        `/center/price-type/${data.centerId}`,
        data
    );
    return res.data.data;
};

const getCenterMachinesWithOrder = async (centerId: string) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.center.getCenterMachinesWithOrder(centerId)
    );
    return res.data.data;
};

const getCenterTimeSlotByModality = async (
    centerId: string,
    modalityIds: string[]
) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.center.getCenterTimeSlotByModality(centerId),
        { params: { modalityIds } }
    );
    return res.data.data;
};

const getCenterTimeSlotByModalityV2 = async (
    centerId: string,
    modalityIds: string[],
    combinedSlot?: boolean,
    isEpoch?: boolean
) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.center.getCenterTimeSlotByModalityV2(centerId),
        { params: { modalityIds, isEpoch, combinedSlot } }
    );
    return res.data.data;
};

const createCenterMachineWithDefaultTimeSlot = async (
    data: { centerId: string } & CenterMachineType
) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.center.createMachineWithDefaultTimeSlot(),
        data
    );
    return res.data.data;
};

const updateCenterMachineWithDefaultTimeSlot = async (
    data: { centerId: string } & CenterMachineType
) => {
    const res = await axiosInstance.put(
        getAPIEndPoints.center.updateMachineWithDefaultTimeSlot(),
        data
    );
    return res.data.data;
};

const updateCenterSchedulerStatus = async (data: {
    centerId: string;
    status: boolean;
}) => {
    const res = await axiosInstance.put(
        getAPIEndPoints.center.updateCenterSchedulerStatus(data.centerId),
        {
            status: data.status,
        }
    );
    return res.data.status;
};

export const uploadFileToAWS = async (centerId: string, data: any) => {
    return await axiosInstance
        .post(`/center/upload/${centerId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res.data);
};

export const uploadPackagePriceFileToAWS = async (
    centerId: string,
    data: any
) => {
    return axiosInstance
        .post(`center/package/upload/${centerId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res.data);
};

export const useGetCenter = () => {
    return useQuery(['allCenter'], getAllCenter, {
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useCreateCenter = () => {
    const queryClient = useQueryClient();

    return useMutation(createCenter, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allCenter']).then(() => {
                toast.success('Center created successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't create center");
            }
        },
    });
};

export const useUpdateProviderPriceTypeForCenter = () => {
    const queryClient = useQueryClient();

    return useMutation(updateProviderPriceTypeForCenter, {
        onSuccess: () => {
            queryClient.invalidateQueries(['allOrdersByCenterId']);
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't update 5c MRP Price Type");
            }
        },
    });
};

export const useEditCenter = () => {
    const queryClient = useQueryClient();

    return useMutation(editCenter, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allOrdersByCenterId']).then(() => {
                toast.success('Center updated successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't update center");
            }
        },
    });
};

const createUserForCenter = async (data: any) => {
    const res = await axiosInstance.post('/center/user/create', data);
    return res.data.data;
};

export const useCreateUserForCenter = () => {
    const queryClient = useQueryClient();

    return useMutation(createUserForCenter, {
        onSuccess: (data) => {
            queryClient.invalidateQueries().then(() => {
                toast.success('User created successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't create user for center");
            }
        },
    });
};

export const useEditCenterBank = () => {
    const queryClient = useQueryClient();

    return useMutation(editCenterBank, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allOrdersByCenterId']).then(() => {
                toast.success('Center bank details updated successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't update bank details for center");
            }
        },
    });
};

const updateActivationCenter = async (data: {
    centerId: string;
    isEnabled: boolean;
}) => {
    const res = await axiosInstance.post(
        `/center/activation/${data.centerId}`,
        data
    );
    return res.data.data;
};

export const useActivateCenter = () => {
    const queryClient = useQueryClient();
    return useMutation(updateActivationCenter, {
        onSuccess: () => {
            queryClient.invalidateQueries(['allOrdersByCenterId']);
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't activate center");
            }
        },
    });
};

export const useGetCenterMachines = (centerId: string) => {
    return useQuery(['getCenterMachines'], () => getCenterMachines(centerId), {
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useGetCenterMachinesWithOrder = (centerId: string) => {
    return useQuery(
        ['useGetCenterMachinesWithOrder'],
        () => getCenterMachinesWithOrder(centerId),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );
};

export const useGetCenterTimeSlotByModality = (
    centerId: string,
    modalityIds: string[],
    isSchedulerEnabled: boolean
) => {
    return useQuery(
        ['getCenterTimeSlotByModality'],
        () => getCenterTimeSlotByModality(centerId, modalityIds),
        {
            cacheTime: 0,
            onError: (error) => {
                console.log(error);
            },
            enabled: !!modalityIds.length && !!centerId && !!isSchedulerEnabled,
        }
    );
};

export const useGetCenterTimeSlotByModalityV2 = (
    centerId: string,
    modalityIds: string[],
    combinedSlot?: boolean,
    isEpoch?: boolean
) => {
    return useQuery(
        ['getCenterTimeSlotByModality-V2'],
        () =>
            getCenterTimeSlotByModalityV2(
                centerId,
                modalityIds,
                combinedSlot,
                isEpoch
            ),
        {
            cacheTime: 0,
            onError: (error) => {
                console.log(error);
            },
            enabled: !!modalityIds?.length && !!centerId,
        }
    );
};

export const useCreateCenterMachineWithDefaultTimeSlot = (props: {
    centerId: string | undefined;
}) => {
    const navigate = useNavigate();

    return useMutation(createCenterMachineWithDefaultTimeSlot, {
        onSuccess: (data) => {
            navigate(
                props.centerId
                    ? getRoutesEndPoints.admin.centerMachineSettings(
                          props.centerId
                      )
                    : getRoutesEndPoints.center_admin.machineSettings(),
                {
                    replace: true,
                }
            );
            toast.success('Machine with time slot created successfully');
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else if (error.response.status === 409) {
                toast.error(error.response.data.status);
            } else {
                toast.error("Couldn't create machine");
            }
        },
    });
};

export const useUpdateCenterMachineWithDefaultTimeSlot = (props: {
    centerId: string | undefined;
}) => {
    const navigate = useNavigate();

    return useMutation(updateCenterMachineWithDefaultTimeSlot, {
        onSuccess: (data) => {
            navigate(
                props.centerId
                    ? getRoutesEndPoints.admin.centerMachineSettings(
                          props.centerId
                      )
                    : getRoutesEndPoints.center_admin.machineSettings(),
                {
                    replace: true,
                }
            );
            toast.success('Updated Machine values successfully');
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't update machine");
            }
        },
    });
};

export const useUpdateCenterSchedulerStatus = () => {
    const queryClient = useQueryClient();

    return useMutation(updateCenterSchedulerStatus, {
        onSuccess: (message) => {
            queryClient.invalidateQueries();
            toast.success(message);
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error("Couldn't update machine");
            }
        },
    });
};

const getCenterStudyList = async (centerId: string) => {
    const res = await axiosInstance.get(`/center/study/${centerId}`);
    return res.data.data;
};

export const useGetCenterStudyList = (centerId: string) => {
    return useQuery(
        ['getCenterStudyList'],
        () => getCenterStudyList(centerId),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );
};

export const createCenterOrder = async (data: CenterOrderType) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.center.createCenterOrder(),
        {
            ...data,
        }
    );
    return res.data.status;
};

export const useCreateCenterOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(createCenterOrder, {
        onSuccess: (message) => {
            queryClient
                .invalidateQueries(['useGetCenterMachinesWithOrder'])
                .then(() => {
                    toast.success(message);
                });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Failed to book the slot');
            }
        },
    });
};

export const updateCenterOrder = async (data: CenterOrderType) => {
    const res = await axiosInstance.put(
        getAPIEndPoints.center.updateCenterOrder(),
        {
            ...data,
        }
    );
    return res.data.status;
};

export const useUpdateCenterOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(updateCenterOrder, {
        onSuccess: (message) => {
            queryClient
                .invalidateQueries(['useGetCenterMachinesWithOrder'])
                .then(() => {
                    toast.success(message);
                });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Failed to update the slot');
            }
        },
    });
};

const getCenterPackageList = async (centerId: string) => {
    const res = await axiosInstance.get(`/center/package/${centerId}`);
    return res.data.data;
};

export const useGetCenterPackageList = (centerId: string) => {
    return useQuery(
        ['getCenterPackageList'],
        () => getCenterPackageList(centerId),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );
};

//patientFlow

const getCenterTimeSlotByModalityPublic = async (
    centerId: string,
    modalityIds: string[],
    user_id: string
) => {
    const res = await axiosInstance.get(`/public/center/timeslot/${centerId}`, {
        params: { modalityIds, user_id },
    });
    return res.data.data;
};

export const useGetCenterTimeSlotByModalityPublic = (
    centerId: string,
    modalityIds: string[],
    isSchedulerEnabled: boolean,
    userId: string
) => {
    return useQuery(
        ['getCenterTimeSlotByModality'],
        () => getCenterTimeSlotByModalityPublic(centerId, modalityIds, userId),
        {
            cacheTime: 0,
            onError: (error) => {
                console.log(error);
            },
            enabled:
                !!modalityIds?.length && !!centerId && !!isSchedulerEnabled,
        }
    );
};
