import { CaslActions, CaslSubjects, CaslUserRoles } from '../../casl/ability';
import * as actions from './actionTypes';

export const verifyUserAuth = (value) => {
    return {
        type: actions.VERIFY_USER_AUTH,
        payload: {
            value: value,
        },
    };
};

export const setFirebaseToken = (value) => {
    return {
        type: actions.FIREBASE_TOKEN,
        payload: {
            value: value,
        },
    };
};

export const setFirebaseUser = (value) => {
    return {
        type: actions.FIREBASE_USER_OBJECT,
        payload: {
            value: value,
        },
    };
};

export const setUserData = (value) => {
    return {
        type: actions.USER_DATA,
        payload: {
            value: value,
        },
    };
};

export const setUserObject = (value) => {
    return {
        type: actions.USER_OBJECT,
        payload: {
            value: value,
        },
    };
};

export const setUserClaims = (value) => {
    let claims = [];
    if (Object.keys(value)) {
        // ADMIN
        if ([CaslUserRoles.SERVICE_PROVIDER].includes(value.accountType)) {
            claims = [
                ...claims,
                {
                    action: CaslActions.READ,
                    subject: CaslSubjects.DRAFT_AGGREGATOR_FILTER,
                },
                {
                    action: CaslActions.READ,
                    subject: CaslSubjects.ORDER_STUDY_CANCEL_OPTION,
                },
            ];
        }
    }
    return {
        type: actions.USER_CLAIMS,
        payload: {
            value: claims,
        },
    };
};

export const setSelectedStudies = (value) => {
    return {
        type: actions.STUDIES_SELECTED,
        payload: {
            value: value,
        },
    };
};

export const setSelectedCenters = (value) => {
    return {
        type: actions.CENTERS_SELECTED,
        payload: {
            value: value,
        },
    };
};

export const setCreatePatientDetails = (value) => {
    return {
        type: actions.PATIENT_DETAILS,
        payload: {
            value: value,
        },
    };
};

export const setUserDetails = (value) => {
    return {
        type: actions.USER_DETAILS,
        payload: {
            value: value,
        },
    };
};
export const setDraftDetails = (value) => {
    return {
        type: actions.DRAFT_DETAILS,
        payload: {
            value: value,
        },
    };
};
