import { Action } from 'redux';
import allReducer, { patientFlowInitialState } from './reducers/reducers';
import { ThunkAction, configureStore } from '@reduxjs/toolkit';

/*----Save states to  Session Storage to persist state for page reloading---*/
function saveToSessionStorage(state: any) {
    try {
        const passStateToLocal = JSON.stringify(state);
        localStorage.setItem('state', passStateToLocal);
    } catch (e) {
        console.log(e);
    }
}

/*--------Load states from the Session Storage-------*/
function loadFromSessionStorage() {
    try {
        const passLocalToState = localStorage.getItem('state');
        if (passLocalToState === null) {
            return undefined;
        }
        return JSON.parse(passLocalToState);
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

const persistedState = loadFromSessionStorage();
export const store = configureStore({
    reducer: allReducer,
    preloadedState: {
        ...persistedState,
        VerifyUserAuthReduxState: { isLoading: true },
        PatientFlowState: patientFlowInitialState,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(() => saveToSessionStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export default store;
