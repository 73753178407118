import axios from 'axios';
import { getAuth } from 'firebase/auth';

const base_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: base_URL,
});

// Adding some information before sending request.
axiosInstance.interceptors.request.use(
    async (conf) => {
        const user = getAuth().currentUser;
        if (user) {
            const token = await user.getIdToken(true); // Get user token from Firebase Auth
            conf.headers.Authorization = `Bearer ${token}`; // Attach token to header
        }
        // conf.headers['role'] = userRole();
        return conf;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);

export default axiosInstance;
