export const STATUS_COLORS = {
    PAYMENT_PENDING: '#F5A600',
    PAYMENT_RECEIVED: '#52A1FF',
    SLOT_CONFIRMED: '#5EA73C',
    SLOT_UNAVAILABLE: '#ED4343',
    ORDER_RESCHEDULED: '#F5A600',
    ORDER_CANCELLED: '#e70000',
    ORDER_COMPLETED: '#34b600',
    INVOICE_GENERATED: '#5EA73C',
    NO_SHOW: '#000000',
    SCHEDULED: '#5EA73C',
    RESCHEDULED: '#ED4343',
    CHECKED_IN: '#5EA73C',
    CHECKED_OUT: '#5EA73C',
    REPORTED: '#52A1FF',
    COMPLETED: '#34b600',
    CANCELLED: '#e70000',
    RESCHEDULE_REQUESTED: '#F5A600',
    CANCEL_REQUESTED: '#e70000',
    // DRAFT ORDER STATUS
    DRAFTED: '#000000',
    ACTIVATED: '#34b600',
    DELETED: '#e70000',
};

export const isEnabledColor = {
    ACTIVATED: '#34b600',
    DEACTIVATE: '#ED4343',
};
