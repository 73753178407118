import axiosInstance from '../../services/axiosInstance';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface PhysicianProps {
    name: String;
    phoneNumber: String;
    degree: String;
    specialization: String;
    email: String;
}

interface PhysicianUpdateProps {
    id: string;
    name: String;
    phoneNumber: String;
    degree: String;
    specialization: String;
    email: String;
    customerId: string;
}

interface UsersProps {
    name: string;
    email: string;
    role: string;
    password: string;
    phoneNumber: string;
    company: string;
    prepaidFeature: Boolean;
    customerId: string;
}

interface PhysicianProps {
    name: String;
    phoneNumber: String;
    degree: String;
    specialization: String;
    email: String;
}

interface UsersUpdateProps {
    displayName: string;
    email: string;
    role: string;
    phoneNumber: string;
    company: string;
    prepaidFeature: Boolean;
    customerId: string;
}

export interface AggBankProps {
    id: string;
    ifscCode?: string;
    beneficiaryName?: string;
    accountNumber?: string;
}

interface AggProps {
    name: string;
    accType: string;
}

const createUser = (data: UsersProps) => {
    return axiosInstance
        .post('/users/create', data)
        .then((res) => res.data.data);
};
const createPhysician = (data: PhysicianProps) => {
    return axiosInstance
        .post('/physician/create', data)
        .then((res) => res.data.data);
};

const updatePhysician = async (data: PhysicianUpdateProps) => {
    const res = await axiosInstance.post('/physicians/update', data);
    return res.data.data;
};

const updateUser = async (data: UsersUpdateProps) => {
    const res = await axiosInstance.post('/users/update', data);
    return res.data.data;
};

const updateUserBank = async (data: AggBankProps) => {
    const res = await axiosInstance.post('/users/bank', data);
    return res.data.data;
};

const delBankAcc = async (customerId: string) => {
    const res = await axiosInstance.delete(`/customer/bank/${customerId}`);
    return res.data.data;
};

const createAgg = async (data: AggProps) => {
    const res = await axiosInstance.post('/aggregators/create', data);
    return res.data.data;
};

const updateAgg = async (data: AggProps) => {
    const res = await axiosInstance.post('/aggregators/update', data);
    return res.data.data;
};

const getAggregators = () => {
    return axiosInstance.get('/aggregators').then((res) => res.data.data);
};

const getUsers = () => {
    return axiosInstance.get('/users').then((res) => res.data.data);
};

const getPhysicians = () => {
    return axiosInstance.get('/physicians').then((res) => res.data.data);
};

const getAllPhysicians = () => {
    return axiosInstance.get('/allphysicians').then((res) => res.data.data);
};

const getPhysicianName = (physicianId: any) => {
    return axiosInstance
        .get(`/physician/${physicianId}`)
        .then((res) => res.data.data);
};

//Get all order by customer Id for aggregator
const getBankDetails = async (customerId: any) => {
    const res = await axiosInstance.get(`/customer/bank-details/${customerId}`);
    return res.data.data;
};

export const useCreateAggregator = () => {
    const queryClient = useQueryClient();

    return useMutation(createAgg, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allAggregators']).then(() => {});
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh the page.');
            } else {
                toast.error("Couldn't create user");
            }
        },
    });
};

export const useUpdateAggregator = () => {
    const queryClient = useQueryClient();

    return useMutation(updateAgg, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allAggregators']).then(() => {});
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh the page.');
            } else {
                toast.error("Couldn't update aggregator");
            }
        },
    });
};

export const useCreatePhysician = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(createPhysician, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allPhysicians']).then(() => {
                toast.success('Referring physician created successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useCreateUser = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(createUser, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allUsers']).then(() => {
                toast.success('User created successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useUpdateUser = () => {
    const queryClient = useQueryClient();

    return useMutation(updateUser, {
        onSuccess: (data) => {
            queryClient
                .invalidateQueries(['allOrdersByCustomerId'])
                .then(() => {
                    toast.success('User updated successfully');
                });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useUpdatePhysician = () => {
    const queryClient = useQueryClient();

    return useMutation(updatePhysician, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allPhysicians']).then(() => {
                toast.success('Physician data updated successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useUpdateUserBank = () => {
    const queryClient = useQueryClient();

    return useMutation(updateUserBank, {
        onSuccess: (data) => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Updated successfully');
            });
        },
        onError: (error: any) => {
            // console.error(error.response?.data);
            // console.log(error.response.data.data instanceof Object);
            error.response.data.data instanceof Object
                ? toast.error(error.response.data.data.error.description)
                : toast.error(error.response.data.data);

            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useDeleteBankAccount = () => {
    const queryClient = useQueryClient();

    return useMutation(delBankAcc, {
        onSuccess: (data) => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Removed successfully');
            });
        },
        onError: (error: any) => {
            toast.error(error.response.data.data);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export function useFetchBankDetails(customerId: any) {
    return useQuery(
        ['bankDetailsByCustomerId', customerId],
        () => getBankDetails(customerId),
        {
            enabled: !!customerId,
            onError: (err) => {
                toast.error('Error fetching details');
                console.log(err);
                // @ts-ignore
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}

export const useGetAggregators = (enabled?: boolean) => {
    return useQuery(['allAggregators'], getAggregators, {
        enabled: enabled,
        cacheTime: 60,
        onError: (error) => {
            console.log(error);
            toast.error("Couldn't fetch Aggregators");
        },
    });
};

export const useGetUsers = () => {
    return useQuery(['allUsers'], getUsers, {
        onError: (error) => {
            console.log(error);
            toast.error("Couldn't fetch Users");
        },
    });
};

export const useGetPhysicians = () => {
    return useQuery(['allPhysicians'], getPhysicians, {
        onError: (error) => {
            console.log(error);
            toast.error("Couldn't fetch Physicians");
        },
    });
};

export const useGetAllPhysicians = () => {
    return useQuery(['allPhysiciansForAdmin'], getAllPhysicians, {
        onError: (error) => {
            console.log(error);
            toast.error("Couldn't fetch Physicians");
        },
    });
};

export function useGetPhysicianName(physicianId: any) {
    return useQuery(
        ['PhysicianName', physicianId],
        () => getPhysicianName(physicianId),
        {
            enabled: !!physicianId,
            onError: (err) => {
                toast.error('Error fetching Physician name');
                console.log(err);
            },
        }
    );
}
