import React, { ReactChild } from 'react';
import styled from 'styled-components';
import ReactTooltip, { Place } from 'react-tooltip';

export enum ToolTipPosition {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
}

type ToolTipProps = {
  content: string;
  position?: ToolTipPosition;
  trigger: ReactChild;
};

const ToolTip = (props: ToolTipProps) => {
  const { content, position, trigger } = props;
  const formattedContent = content && content.replace(/;/g, ',<br>');
  return (
    <>
      <TooltipWrapper data-tip={formattedContent} data-for={content}>
        {trigger}
      </TooltipWrapper>
      {formattedContent && (
        <ReactTooltip id={content} effect="solid" place={position as Place}>
          <ContentWrapper dangerouslySetInnerHTML={{ __html: formattedContent }} />
        </ReactTooltip>
      )}
    </>
  );
};

export default ToolTip;

const TooltipWrapper = styled.span`
  display: inline-block;
`
const ContentWrapper = styled.div`
  white-space: pre-wrap;
`