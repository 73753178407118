import React from 'react';

const CenterImageCard = ({ cardData }: { cardData: string }) => {
    return (
        <div className="h-[200px] flex items-center justify-center">
            <img
                src={cardData}
                className="max-h-[100%] max-w-[100%] object-contain"
            />
        </div>
    );
};

export default CenterImageCard;
