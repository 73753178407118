import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userObject } from '../redux/selectors';
import Loader from '../components/utils/Loader';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ allowedAccountType, allowedRoles }) => {
    const user = userObject();
    const location = useLocation();
    const { VerifyUserAuthReduxState } = useSelector((state) => state);

    if (
        user?.accountType === allowedAccountType &&
        user?.role === allowedRoles
    ) {
        return (
            <Loader isLoading={VerifyUserAuthReduxState?.isLoading}>
                <Outlet />
            </Loader>
        );
    } else {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
};

export default ProtectedRoute;
