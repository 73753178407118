import Navbar from './Navbar';
import UserSidebar from './UserSidebar';
import { userData, userObject } from '../../redux/selectors';
import {
    adminMenuItems,
    aggregatorMenuItems,
    centerMenuItems,
    financeMenuItems,
    userMenuItems,
} from './config';
import { CaslUserRoles } from '../../casl/ability';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import Loader from '../utils/Loader';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import ShareQRFlowLink from '../admin/modals/ShareQRFlowLink';
import ModalComponent from '../ModalTemplate/ModalComponent';
import { useCreateModalState } from '../hooks/useModalHooks';

const LayoutWrapper = () => {
    const user = userObject();
    const { VerifyUserAuthReduxState } = useSelector((state) => state);
    const { displayName } = userData();
    const qrModal = useCreateModalState();

    const returnMenuItem = (accountType, role) => {
        if (
            accountType === CaslUserRoles.SERVICE_PROVIDER &&
            role === CaslUserRoles.ADMIN
        ) {
            return adminMenuItems;
        }
        if (
            accountType === CaslUserRoles.SERVICE_PROVIDER &&
            role === CaslUserRoles.FINANCE_ADMIN
        ) {
            return financeMenuItems;
        }
        if (
            accountType === CaslUserRoles.AGGREGATOR &&
            role === CaslUserRoles.ADMIN
        ) {
            return aggregatorMenuItems;
        }
        if (
            accountType === CaslUserRoles.DIAGNOSTICS_CENTER &&
            role === CaslUserRoles.CENTER_ADMIN
        ) {
            return centerMenuItems;
        }
        return userMenuItems;
    };

    return (
        <Loader isLoading={VerifyUserAuthReduxState?.isLoading}>
            <div className="h-full bg-gray-50">
                <div className="sticky z-10 left-0 top-0 bg-white w-full">
                    <UserSidebar
                        menuItems={returnMenuItem(
                            user?.accountType,
                            user?.role
                        )}
                    />
                </div>

                <div className="mx-auto lg:ml-64">
                    <Navbar handleQRModal={qrModal} />

                    <>
                        <div className="m-4 lg:ml-8 lg:mr-8 pb-16 ml-2 mr-2">
                            <Suspense fallback={<Loader isLoading />}>
                                <Outlet />
                            </Suspense>
                        </div>
                        <div className="container px-4 mx-auto pb-8">
                            <p className="text-gray-400 font-medium text-xs text-center">
                                &copy; {new Date().getFullYear()} 5C Network.
                                All rights reserved.
                            </p>
                        </div>
                    </>
                </div>
            </div>

            <AnimatePresence initial={false} mode="wait">
                {qrModal.modal && (
                    <ModalComponent
                        handleClose={qrModal.closeModal}
                        width={'400px'}
                    >
                        <ShareQRFlowLink
                            handleClose={qrModal.closeModal}
                            QRDetails={{ userId: user.user_id, displayName }}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </Loader>
    );
};

export default LayoutWrapper;
