import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Select, { SingleValue } from 'react-select';
import { useCreateUser } from '../../hooks/useUsers';
import { userObject } from '../../../redux/selectors';
import ModalHeader from '../../ModalTemplate/components/ModalHeader';

interface AddUserProps {
    handleClose?: () => void;
}

const AddUserModal = (props: AddUserProps) => {
    const { customerID } = userObject();

    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: '',
        phoneNumber: '',
        company: '',
        role: '',
        prepaidFeature: true,
        customerId: customerID,
    });

    const createUser = useCreateUser();

    const handleCreateUser = (e: {
        preventDefault: () => void;
        target: any;
    }) => {
        e.preventDefault();

        if (userData.phoneNumber.length < 10) {
            toast.error('Contact number must be 10 digits');
            return;
        }

        if (userData.password.length < 6) {
            toast.error('Password must be at least 6 characters');
            return;
        }

        if (userData.role === '') {
            toast.error('Please select a role');
            return;
        }

        createUser
            .mutateAsync(userData)
            .then(() => {
                // @ts-ignore
                props.handleClose();
            })
            .catch((err) => {
                toast.error(err.response.data.data);
            });
    };

    const roles = ['admin', 'member'];

    const roleOptions = roles.map((d) => {
        return {
            value: d,
            label: d,
        };
    });

    const handleRoleChange = (
        roleVal: SingleValue<string | { value: string; label: string }>
    ) => {
        if (typeof roleVal !== 'string' && roleVal) {
            setUserData({
                ...userData,
                role: roleVal.value,
            });
        }
    };

    return (
        <div>
            <form
                onSubmit={handleCreateUser}
                className="flex flex-col justify-between items-start gap-4"
            >
                <ModalHeader
                    title={'User Details'}
                    handleClose={props.handleClose}
                />
                <div className="w-full px-4">
                    <div className="bg-white flex flex-col gap-2 p-4">
                        <div className="flex  gap-2 md:flex-row flex-col">
                            <div className="flex-1 ">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Name
                                </label>
                                <input
                                    required
                                    value={userData.name}
                                    onChange={(e) => {
                                        setUserData((prev) => {
                                            return {
                                                ...prev,
                                                name: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email
                                </label>
                                <input
                                    required
                                    value={userData.email}
                                    onChange={(e) => {
                                        setUserData((prev) => {
                                            return {
                                                ...prev,
                                                email: e.target.value,
                                            };
                                        });
                                    }}
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2 md:flex-row flex-col">
                            <div className="flex-1">
                                <label
                                    htmlFor="phone"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Phone
                                </label>
                                <input
                                    required
                                    value={userData.phoneNumber}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    phoneNumber: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    maxLength={10}
                                    autoComplete="off"
                                    placeholder="00000 00000"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Password
                                </label>
                                <input
                                    required
                                    value={userData.password}
                                    onChange={(e) => {
                                        setUserData((prev) => {
                                            return {
                                                ...prev,
                                                password: e.target.value,
                                            };
                                        });
                                    }}
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2 md:flex-row flex-col">
                            <div className="flex-1">
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        required
                                        value={userData.company}
                                        onChange={(e) => {
                                            setUserData((prev) => {
                                                return {
                                                    ...prev,
                                                    company: e.target.value,
                                                };
                                            });
                                        }}
                                        type="text"
                                        name="company"
                                        id="company"
                                        autoComplete="off"
                                        className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="role"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Role
                                </label>
                                <Select
                                    id={'role'}
                                    name={'role'}
                                    placeholder="Select Role"
                                    options={roleOptions}
                                    onChange={(e) => handleRoleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-8 py-4 w-full flex justify-center flex-wrap items-center gap-4 border-t border-gray-200 sm:justify-end md:justify-end lg:justify-end">
                    <div className="flex flex-row items-center gap-2 px-4">
                        <input
                            id={'prepaid'}
                            name={'prepaid'}
                            type="checkbox"
                            className={'w-5 h-5 cursor-pointer'}
                            checked={userData.prepaidFeature}
                            value={userData.prepaidFeature ? 'true' : 'false'}
                            onChange={(e) => {
                                setUserData((prev) => {
                                    return {
                                        ...prev,
                                        prepaidFeature: e.target.checked,
                                    };
                                });
                            }}
                        />
                        <label
                            htmlFor="prepaid"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Prepaid Feature?
                        </label>
                    </div>
                    {/* <button
                        type="button"
                        onClick={props.handleClose}
                        className="py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded text-gray-900 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                    >
                        Cancel
                    </button> */}
                    <button
                        disabled={createUser.isLoading}
                        className={`bg-primary-500 hover:bg-primary-700
                         py-3 px-12 font-medium rounded text-white focus:outline-none disabled:opacity-20`}
                    >
                        Add User
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddUserModal;
