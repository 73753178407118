import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../services/axiosInstance';
import { toast } from 'react-toastify';

export const updateCustomer = async (customerId: string, customerData: any) => {
    return axiosInstance
        .put(`/customers/${customerId}`, customerData)
        .then((res) => res.data.data);
};

const getCustomer = async (customerId: string) => {
    return axiosInstance
        .get(`/customers/${customerId}`)
        .then((res) => res.data.data);
};

export const useGetCustomer = (customerId: any) => {
    return useQuery(['customers', customerId], () => getCustomer(customerId), {
        onError: (error) => {
            console.log(error);
        },
    });
};

//Get customer details from public endpoint
const getUserDetailPublic = async ({ userId }: { userId: string | null }) => {
    return await axiosInstance
        .get(`/public/user`, {
            params: { user_id: userId },
        })
        .then((res) => res.data.data);
};

// Get all Customers
const getAllCustomer = async () => {
    return axiosInstance.get(`/customers`).then((res) => res.data.data);
};

export const useGetAllCustomer = () => {
    return useQuery(['customers'], () => getAllCustomer(), {
        onError: (error) => {
            console.log(error);
        },
    });
};

export function useGetUserDetailPublic(userId: string | null) {
    return useQuery(
        ['userDetailPublic'],
        () => getUserDetailPublic({ userId }),
        {
            enabled: userId !== null,
            onError: (err: any) => {
                toast.error('Error loading test details');
            },
        }
    );
}
