import React from 'react';
import { BiCalendarPlus, BiTimeFive } from 'react-icons/bi';
import { GrEdit } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdLocationOn } from 'react-icons/md';
import { useCreateModalState } from '../../components/hooks/useModalHooks';
import { patientFlowStateObject } from '../../redux/selectors';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { getFullPatientRoute } from '../helpers/contants';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const formateDateAndTimeInIST = (date: Date, formatString: string) => {
    const istDate = utcToZonedTime(date, `Asia/Kolkata`);
    const time = format(istDate, formatString);
    return time;
};

const TestSelected = () => {
    const { selectedCenter, patientDetails, selectedStudies } =
        patientFlowStateObject();

    const {
        modal: Accordion,
        openModal: openAccordion,
        closeModal: closeAccordion,
    } = useCreateModalState();
    const navigate = useNavigate();

    const location = useLocation();
    const renderEditButton = (route: string) => {
        return (
            <span className="px-2">
                <GrEdit
                    color="#1F6299"
                    size={13}
                    onClick={() => navigate(route)}
                />
            </span>
        );
    };

    const renderPatientCaseDetails = () => {
        return (
            <div className="flex flex-col gap-6 h-full ">
                {selectedCenter.center && (
                    <div className="flex flex-row gap-2 font-semibold text-[#707070] text-xs">
                        <div>
                            <MdLocationOn color="#1F6299" size={20} />
                        </div>
                        <div className="flex flex-col gap-1 font-semibold text-[#707070] text-xs">
                            <p className="text-justify">
                                {selectedCenter?.center}
                            </p>
                            <div className="text-[10px]">
                                <p>
                                    <span>{selectedCenter.city}, </span>
                                    <span>{selectedCenter.pincode}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <ParentContainer>
                    {selectedStudies?.study?.length > 0 &&
                        selectedStudies?.study?.map(
                            (study: any, index: number) => (
                                <div
                                    className="flex flex-row items-start gap-2 leading-5 text-sm mb-5"
                                    key={`i_${index}`}
                                >
                                    <span className="px-2 bg-gray-200 rounded-md w-7 flex justify-center items-center">
                                        {index + 1}
                                    </span>
                                    <div className="border shadow rounded gap-2 flex flex-col px-4 py-3 w-full">
                                        <p>{study.label}</p>
                                        {selectedCenter.studyList &&
                                            selectedCenter.studyList.length >
                                                0 && (
                                                <>
                                                    <div className="flex items-center gap-2 ">
                                                        <BiCalendarPlus
                                                            color={'#1F6299'}
                                                            size="15"
                                                        />
                                                        <p className="text-xs text-[#6B7280]">
                                                            {formateDateAndTimeInIST(
                                                                selectedCenter
                                                                    .studyList[
                                                                    index
                                                                ]
                                                                    .scheduledDateTime,
                                                                'dd MMM yyy'
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <BiTimeFive
                                                            color="#1F6299"
                                                            size="15"
                                                        />
                                                        <p className="text-xs text-[#6B7280]">
                                                            {formateDateAndTimeInIST(
                                                                selectedCenter
                                                                    .studyList[
                                                                    index
                                                                ]
                                                                    .scheduledDateTime,
                                                                'hh:mm a'
                                                            )}
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                </div>
                            )
                        )}
                </ParentContainer>
                {patientDetails.email && (
                    <>
                        <div className="justify-between flex items-center text-base font-semibold">
                            <p className="">Contact Information</p>
                            {[getFullPatientRoute.orderInfo()].includes(
                                location.pathname
                            ) &&
                                renderEditButton(
                                    getFullPatientRoute.patientInfo()
                                )}
                        </div>
                        <div className="flex flex-col text-xs gap-2">
                            <div className="flex flex-row gap-2">
                                <span className="text-[#2C2B2B]">
                                    Mobile No:
                                </span>
                                <p className="text-[#707070]">
                                    {patientDetails.phone}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <span className="text-[#2C2B2B]">
                                    Email Id:
                                </span>
                                <p className="text-[#707070]">
                                    {patientDetails.email}
                                </p>
                            </div>
                        </div>
                        {/* )} */}
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="w-full">
            <div className="flex lg:hidden flex-col justify-between">
                <div className="flex items-center justify-between py-5 font-medium text-left">
                    <button
                        onClick={() => {
                            Accordion ? closeAccordion() : openAccordion();
                        }}
                        type="button"
                    >
                        <div className="justify-between flex items-center text-base font-semibold gap-6">
                            <p className=" flex gap-4 items-center">
                                {' '}
                                Tests Selected{' '}
                                {selectedStudies?.study?.length > 0 && (
                                    <span className="bg-[#EE6958] w-6 h-6 rounded-full flex justify-center items-center text-white text-xs">
                                        {selectedStudies?.study?.length}
                                    </span>
                                )}
                            </p>
                            {Accordion ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>
                    </button>
                    {[getFullPatientRoute.orderInfo()].includes(
                        location.pathname
                    ) && renderEditButton(getFullPatientRoute.testInfo())}
                </div>

                <div className={`${Accordion ? 'flex' : 'hidden'} flex-col`}>
                    <hr className="border-[1px]" />
                    <div className="py-6 px-4 w-full">
                        {renderPatientCaseDetails()}
                    </div>
                    <hr className="border-[1px]" />
                </div>
            </div>

            <div className="flex-col border p-8 gap-5 hidden lg:flex rounded-2xl shadow">
                <div className="justify-between flex items-center text-base font-semibold">
                    <p className=" flex gap-4 items-center">
                        Tests Selected{' '}
                        {selectedStudies?.study?.length > 0 && (
                            <span className="bg-[#EE6958] w-6 h-6 rounded-full flex justify-center items-center text-white text-xs">
                                {selectedStudies?.study?.length}
                            </span>
                        )}
                    </p>
                    {[getFullPatientRoute.orderInfo()].includes(
                        location.pathname
                    ) && renderEditButton(getFullPatientRoute.testInfo())}
                </div>
                {renderPatientCaseDetails()}
            </div>
        </div>
    );
};

export default TestSelected;

const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    max-height: 50vh;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
