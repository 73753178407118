import { useState } from 'react';
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
    setPersistence,
    getAuth,
    browserLocalPersistence,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import {
    setFirebaseToken,
    setUserObject,
    setFirebaseUser,
    setUserData,
    setUserClaims,
} from '../redux/actions/actions';
import { toast } from 'react-toastify';
import { UserContext } from './useAuth';
import axiosInstance from '../services/axiosInstance';
import * as END_POINTS from '../constants/endpoints';
import posthog from 'posthog-js';

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useDispatch();

    const createUser = async (data) => {
        setLoading(true);
        if (data.name === '') return;

        return await createUserWithEmailAndPassword(
            getAuth(),
            data.email,
            data.password
        ).then(async function (res) {
            const idToken = await res.user.getIdToken();
            setUser(res.user);

            dispatch(setFirebaseToken(idToken));
            dispatch(setFirebaseUser(res.user.uid));

            await axiosInstance
                .post(END_POINTS.SIGN_UP, data)
                .then(() => {
                    updateProfile(getAuth().currentUser, {
                        displayName: data.name,
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error('Something went wrong. Try again!');
                });

            return data;
        });
    };

    const loginUser = async (email, password) => {
        await setPersistence(getAuth(), browserLocalPersistence);
        return await signInWithEmailAndPassword(getAuth(), email, password)
            .then(async (res) => {
                const idToken = await res.user.getIdToken();
                setUser(res.user);
                dispatch(setFirebaseToken(idToken));

                const claims = (await res.user.getIdTokenResult()).claims;
                dispatch(setUserObject(claims));
                dispatch(setUserClaims(claims));

                const user = await axiosInstance.get('/sign-in');

                dispatch(setUserData(user.data.data));

                if (process.env.NODE_ENV === 'production')
                    posthog.identify(email, {
                        claims,
                    });

                return user.status === 200;
            })
            .catch((res) => {
                dispatch(setFirebaseToken(''));
                dispatch(setFirebaseUser(''));
                dispatch(setUserObject(''));
                dispatch(setUserClaims(''));
                switch (res.code) {
                    case 'auth/invalid-email':
                        toast.error('Invalid Email');
                        return false;
                    case 'auth/user-disabled':
                        toast.error('User has been disabled');
                        return false;
                    case 'auth/user-not-found':
                        toast.error("User doesn't exist");
                        return false;
                    case 'auth/wrong-password':
                        toast.error('Wrong password');
                        return false;
                    default:
                        // toast.error('Internal Server error!');
                        return false;
                }
            });
    };

    const logoutUser = async () => {
        if (process.env.NODE_ENV === 'production') posthog.reset();

        return await signOut(getAuth()).then(() => {
            localStorage.removeItem('state');
            dispatch(setFirebaseToken(''));
            dispatch(setUserObject(''));
            dispatch(setUserClaims(''));
        });
    };

    const forgotPassword = (email) => {
        return sendPasswordResetEmail(getAuth(), email, {
            url: 'http://localhost:3000',
        });
    };

    const resetPassword = (email) => {
        return sendPasswordResetEmail(getAuth(), email);
    };

    const contextValue = {
        user,
        loading,
        loginUser,
        createUser,
        logoutUser,
        forgotPassword,
        emailError,
        passwordError,
        setEmailError,
        setPasswordError,
    };
    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};
