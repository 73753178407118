import React, { ReactElement } from 'react';

import { AiFillCloseCircle } from 'react-icons/ai';

interface Props {
    title: string;
    handleClose: any;
    customComponent?: ReactElement<any, any>;
}

const ModalHeader = ({ title, handleClose, customComponent }: Props) => {
    return (
        <div className="w-full p-6 flex flex-row justify-between items-center border-b border-gray-200">
            <div className={'flex gap-4 items-center '}>
                <p className="text-lg font-medium">{title}</p>
                {customComponent ? customComponent : null}
            </div>
            <AiFillCloseCircle
                size={24}
                className="cursor-pointer hover:drop-shadow-lg mr-2 rounded-[50%]"
                onClick={() => handleClose()}
            />
        </div>
    );
};

export default ModalHeader;
