import React from 'react';

const NoAccess = ({ text }: { text?: string }) => {
    return (
        <div className="flex justify-center items-center mx-auto my-auto mt-20">
            <div className="flex flex-col text-red-500 bg-gray-100 rounded-lg justify-center items-center p-4 sm:p-12 md:-20 lg-24">
                {text ?? 'Oops! Something went wrong.'}
                <p className="text-black">Please contact us for any queries</p>
            </div>
        </div>
    );
};

export default NoAccess;
