import { useState } from 'react';

export const useCreateModalState = () => {
    const [modal, setModal] = useState<boolean>(false);
    const openModal: () => void = () => setModal(true);
    const closeModal: () => void = () => setModal(false);
    return {
        modal,
        openModal,
        closeModal,
    };
};
