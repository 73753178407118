import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

const TestimonialCard = ({ cardData }: any) => {
    return (
        <div className="flex flex-col justify-between gap-4">
            <p className="text-sm text-[#707070] text-justify leading-6 px-5 ">
                <span className='text-3xl text-[#1F6299] mr-2'>&#10077;</span>
                {cardData?.testimonial} <span>&#10078;</span>
            </p>
            {/* <img
                src={
                    cardData?.gender.toLowerCase() == 'female'
                        ? '../Avatar_Women.png'
                        : '../Avatar_Men.png'
                }
                alt="Avatar"
                className="w-[40%] rounded-full mx-auto my-2"
            /> */}
            <div>
                <p className="font-poppins text-sm text-[#010423] text-end px-5">
                    -{cardData?.name}
                </p>
                <p className="font-poppins text-sm text-[#707070] text-end px-5">
                    {cardData?.location}
                </p>
            </div>
        </div>
    );
};

export default TestimonialCard;
