import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import {
    useGetCitiesPublic,
    useSearchCenterByStudyPublic,
} from '../../components/hooks/useTempOrder';
import { patientFlowStateObject } from '../../redux/selectors';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { steps } from '../common/Stepper';
import { useDispatch } from 'react-redux';
import { setSelectedStudies } from '../../redux/actions/actions';
import { getFullPatientRoute } from '../helpers/contants';
import NoAccess from '../common/NoAccess';
import PincodeSearchInputBox, {
    PincodeObjType,
} from '../../components/utils/PincodeSearchInputBox';
import Loader from '../../components/utils/Loader';
import StudySelect from '../../components/utils/StudySelect';

type studyObjectType = {
    id: string;
    studyName: string;
    modalityId: string;
};
type reduxStudyObjectType = {
    value: string;
    label: string;
    modalityId: string;
};

const TestInfo = () => {
    const navigate = useNavigate();
    const { selectedStudies, userDetails, draftDetails } =
        patientFlowStateObject();
    const { handleStepper, updateCenterList } = useOutletContext<any>();
    const dispatch = useDispatch();
    const location = useLocation();

    const [pincodeObj, setPincodeObj] = useState<PincodeObjType>({
        value: '',
        lat: '',
        long: '',
        error: false,
    });

    //default study selected by the physician or studies selected by user which is stored in redux
    const defaultStudyOptions = useMemo(() => {
        const defaultStudyOptions2 = selectedStudies?.study?.map(
            (eachStudy: reduxStudyObjectType) => {
                return {
                    value: eachStudy.value,
                    label: eachStudy.label,
                    modalityId: eachStudy.modalityId,
                };
            }
        );
        return defaultStudyOptions2;
    }, [selectedStudies?.study]);

    //state for study selection
    const [study, setStudy] = useState(
        selectedStudies?.study?.length > 0
            ? defaultStudyOptions
            : {
                  value: null,
                  label: null,
                  modalityId: null,
              }
    );

    const handleStudyChange = (studyVal: any) => {
        setStudy(studyVal);
    };

    const {
        data: { cities: cityList, defaultCity: defaultCity } = {},
        isLoading: cityListLoading,
    } = useGetCitiesPublic(userDetails?.id);

    //useMemo is used here because only upon changes from redux/query should be recreated, because it is created by an iteration.

    //creation of citylist
    const cityOptions = useMemo(() => {
        const cityOptions2 = cityList?.map((eachCity: any) => {
            return { value: eachCity.city, label: eachCity.city };
        });
        return cityOptions2;
    }, [cityList]);

    //default city selected by the physician or cities selected by user which is stored in redux
    const defaultCityOptions = useMemo(() => {
        return selectedStudies?.patientCity;
    }, [selectedStudies?.patientCity]);

    //state for city select
    const [city, setCity] = useState(
        selectedStudies?.patientCity?.value
            ? defaultCityOptions
            : {
                  value: null,
                  label: null,
              }
    );

    //incase no city selected by physician, set aggregators default city as defualt value
    let defaultCityOption = useMemo(() => {
        if (cityList?.length) {
            let selectedCityObj = {
                value: cityList?.[0]?.city,
                label: cityList?.[0]?.city,
            };
            if (defaultCity && !defaultCityOptions?.value) {
                cityList.forEach((item: any) => {
                    if (item.city === defaultCity) {
                        selectedCityObj = {
                            value: item.city,
                            label: item.city,
                        };
                    }
                });
            }
            if (defaultCityOptions?.value) {
                selectedCityObj = defaultCityOptions;
            }
            setCity(selectedCityObj);
            return selectedCityObj;
        }
        return null;
    }, [defaultCity]);

    const {
        mutateAsync: searchCenterByStudy,
        isLoading: searchCenterByStudyLoading,
    } = useSearchCenterByStudyPublic();

    const handleSubmit = () => {
        dispatch(
            setSelectedStudies({
                study: study,
                patientCity: city,
                patientPincode: pincodeObj,
            })
        );
        searchCenterByStudy({
            searchData: {
                location: city.label,
                selectedStudy: study,
                discount: draftDetails.draftDiscount ?? 0,
                pincode: pincodeObj,
                customerId: userDetails.customerId,
            },
            userId: userDetails?.id,
        }).then((res) => {
            updateCenterList(res);
            handleStepper(steps.TEST_INFO);
            navigate(getFullPatientRoute.centerInfo(), {
                state: { from: location.pathname },
            });
        });
    };

    if (!userDetails?.id && !draftDetails?.draftOrderId) {
        return <NoAccess />;
    }

    return (
        <Loader isLoading={cityListLoading}>
            <div>
                <p className="text-[16px] font-medium mb-10">Test Info</p>
                <form>
                    <div className="flex flex-col justify-between gap-10 items-center sm:items-start">
                        <div className="w-[95%] sm:w-[85%]">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Select Test(s):
                            </label>
                            <StudySelect
                                onChange={handleStudyChange}
                                defaultValue={defaultStudyOptions}
                                isPublicSearch={true}
                                userId={userDetails?.id}
                            />
                        </div>

                        <div className="w-[95%] sm:w-[85%]">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Select City:
                            </label>
                            <Select
                                placeholder="Select City"
                                options={cityOptions}
                                onChange={(value: any) => {
                                    setCity(value);
                                }}
                                isLoading={cityListLoading}
                                defaultValue={
                                    defaultCityOptions || defaultCityOption
                                }
                            />
                        </div>

                        <div className="w-[95%] sm:w-[85%]">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Select Pincode:
                            </label>
                            <PincodeSearchInputBox
                                pincodeState={pincodeObj}
                                setPincodeState={setPincodeObj}
                                disabled={!city?.value}
                            />
                        </div>
                    </div>
                </form>
                <div className="flex justify-center items-center mt-10">
                    <button
                        disabled={
                            searchCenterByStudyLoading ||
                            !study?.[0]?.value ||
                            !city?.value ||
                            !pincodeObj?.value
                        }
                        onClick={handleSubmit}
                        className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md flex align-center disabled:bg-[#5d97c6] hover:bg-[#022e53]"
                    >
                        {' '}
                        View Center
                    </button>
                </div>
            </div>
        </Loader>
    );
};

export default TestInfo;
