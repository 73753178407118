import React from 'react';
import { BiSearch } from 'react-icons/bi';

const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <div className="flex gap-2 sm:border-solid grow">
            <div className="h-10 flex flex-row text-gray-800 items-center overflow-hidden relative w-full">
                <BiSearch className={'absolute ml-2 text-gray-500'} />
                <input
                    onChange={(e) => setFilter(e.target.value)}
                    type="text"
                    value={filter || ''}
                    placeholder="Search any column"
                    className="block grow w-full h-full pl-6 xl:pl-8 text-sm focus:outline-gray-300 border-2 border-gray-200 rounded"
                />
            </div>
        </div>
    );
};

export default GlobalFilter;
