import React from 'react';
import { calculateTotalBasePrice, calculateTotalContrastPrice } from '../../slots/Common';

const PatientDetailAndPriceCard = ({ orderDetail, discountedPrice }) => {
    return (
        <div className="grid md:grid-flow-col grid-flow-row mb-4 md:grid-cols-2 grid-cols-1 items-center">
            <div className="flex flex-col justify-center items-center w-full gap-2 md:m-0 my-3 ml-2">
                <div className="w-full flex flex-row">
                    <div className="w-full flex flex-row justify-between items-center">
                        <p className="w-24 text-xs text-gray-500">Name:</p>
                        <p className="w-full text-sm font-medium text-gray-900 capitalize">
                            {orderDetail?.patient.name}
                        </p>
                    </div>
                </div>

                <div className="w-full flex flex-row">
                    <div className="w-full flex flex-row items-center justify-between">
                        <p className="w-24 text-xs text-gray-500">Email:</p>
                        <p className="w-full text-sm font-medium text-gray-900">
                            {orderDetail?.patient.email}
                        </p>
                    </div>
                </div>

                <div className="w-full flex flex-row">
                    <div className="w-full flex flex-row items-center justify-between">
                        <p className="w-24 text-xs text-gray-500">Phone:</p>
                        <p className="w-full text-sm font-medium text-gray-900 ">
                            {orderDetail?.patient.phoneNumber}
                        </p>
                    </div>
                </div>

                <div className="w-full flex flex-row">
                    <div className="w-full flex flex-row items-center justify-between">
                        <p className="w-24 text-xs text-gray-500">Age:</p>
                        <p className="w-full text-sm font-medium text-gray-900">
                            {orderDetail?.patient.age}
                        </p>
                    </div>
                </div>

                <div className="w-full flex flex-row">
                    <div className="w-full flex flex-row items-center justify-between">
                        <p className="w-24 text-xs text-gray-500">Gender:</p>
                        <p className="w-full text-sm font-medium text-gray-900 capitalize">
                            {orderDetail?.patient.gender}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col bg-gray-100 rounded gap-2 pt-4">
                <div className="w-full flex flex-row justify-between items-center px-4 py-1">
                    <p className="w-full text-sm text-gray-500">
                        {orderDetail?.type !== 'group' ? 'Study' : 'Package'}{' '}
                        Price:
                    </p>
                    <p className="w-full font-medium text-right text-gray-900">
                        {/*{JSON.stringify(orderDetail)}*/}
                        Rs.{orderDetail?.mrpTotalPrice}/-
                    </p>
                </div>
                <div className="w-full flex flex-row justify-between items-center px-4 py-1">
                    <p className="w-full text-sm text-gray-500">
                        Discount Applied:
                    </p>
                    <p className="w-full text-sm font-medium text-right text-gray-900">
                        - Rs.
                        {orderDetail?.mrpTotalPrice - orderDetail.totalPrice}
                        <span className="pl-1 text-green-600">
                            ({orderDetail?.discountPercent}
                            %)
                        </span>
                    </p>
                </div>
                <div className="w-full flex flex-row justify-between items-center px-4 py-1">
                    <p className="w-full text-sm text-gray-500">
                        Already Paid:
                    </p>
                    <p className="w-full font-medium text-right text-gray-900">
                        {orderDetail?.patient.isPrepaid ? 'Yes' : 'No'}
                    </p>
                </div>
                <div className="w-full flex flex-row justify-between items-center border-t border-gray-300 p-4">
                    <p className="w-full text-sm text-gray-500">To be paid:</p>
                    <p className="w-full font-medium text-right text-gray-900">
                        Rs.
                        {!orderDetail?.patient.isPrepaid
                            ? parseInt(orderDetail?.totalPrice)
                            : 0}
                        /-
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PatientDetailAndPriceCard;
