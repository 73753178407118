import React from 'react';
import { ActionMeta, GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useGetAllModStudyPublic } from '../hooks/useTempOrder';
import { StudyObjType, useGetAllStudyWithPaginationMutation } from '../hooks/useStudy';

type StudySelectProps = {
    defaultValue: any;
    onChange: (newValue: MultiValue<StudyListOptionType>, actionMeta: ActionMeta<StudyListOptionType>) => void;
    isPublicSearch?: boolean;
    userId?: any;
}

export type StudyListOptionType = {
    value: string;
    label: string;
    modalityId: string;
    tags: string[];
};

const StudySelect = ({
    defaultValue,
    onChange,
    isPublicSearch,
    userId,
}: StudySelectProps) => {
    const { mutateAsync: getPublicStudyList, isLoading: isPublicStudyListLoading } =
        useGetAllModStudyPublic();
    const { mutateAsync: getStudyList, isLoading: isStudyListLoading } =
        useGetAllStudyWithPaginationMutation();

    // Used to contruct react-select options
    const studyListOptionsConstructor = (
        studyList: StudyObjType[]
    ): StudyListOptionType[] => {
        return studyList?.map((study: any) => {
            return {
                value: study.id,
                label: study.studyName,
                modalityId: study.modalityId,
                tags: study.tags,
            };
        });
    };

    const loadStudyListOptions = async (
        search: string,
        prevOptions: OptionsOrGroups<
            StudyListOptionType,
            GroupBase<StudyListOptionType>
        >
    ) => {
        let studyList = [];
        const studySearch = search?.toLowerCase()?.trim();

        if (isPublicSearch) {
            studyList = await getPublicStudyList({
                userId: userId,
                limit: 20,
                offset: prevOptions.length,
                studySearch,
            });
        } else {
            const { study_list } = await getStudyList({
                limit: 20,
                offset: prevOptions.length,
                studySearch,
                isEnabled: true,
            });
            studyList = study_list
        }

        studyList = studyListOptionsConstructor(studyList ?? []);
        // if newely fetched studyList has less than 20 length
        // then hasMore will be false.
        const hasMore = studyList?.length === 20;

        return {
            options: studyList,
            hasMore,
        };
    };

    return (
        <AsyncPaginate
            menuPlacement="bottom"
            defaultValue={defaultValue}
            maxMenuHeight={250}
            menuShouldScrollIntoView
            debounceTimeout={300}
            isLoading={isStudyListLoading || isPublicStudyListLoading}
            loadOptions={loadStudyListOptions}
            isMulti
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={onChange}
        />
    );
};

export default StudySelect;
