import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Backdrop } from './Backdrop';

interface ModalStyleProps {
    min_width?: string;
    width?: string;
    variants?: any;
    initial?: string;
    animate?: string;
    exit?: string;
    height?: string;
    min_height?: string;
}

interface ModalProps {
    open?: () => void;
    handleClose?: () => void;
    children: React.ReactNode;
    min_width?: string;
    width?: string;
    title?: string;
    footer?: React.ReactNode;
    min_height?: string;
    height?: string;
}

const ModalContainer = styled(motion.div)<ModalStyleProps>`
    width: clamp(
        ${(props) => props.min_width || 30}%,
        ${(props) => props.width || 400},
        100%
    );
    height: min-content;
    min-height: ${(props) => props.min_height || 'auto'};
    max-height: 80%;
    margin: auto 10px;
    border-radius: 8px;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 500;
    position: relative;
`;

const ModalBody = styled.div`
    overflow-y: auto;
    width: auto;
    /* padding: 1.5rem; */
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */

    &::-webkit-scrollbar {
        width: 4px;
    }
`;

const dropIn = {
    hidden: {
        y: '100vh',
        opacity: 0,
    },
    visible: {
        y: '0',
        opacity: 1,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        y: '100vh',
        opacity: 0,
        transition: {
            duration: 0.5,
        },
    },
};

const ModalComponent = (props: ModalProps) => {
    return (
        <Backdrop
        // onClick={props.handleClose}
        >
            <ModalContainer
                onClick={(e) => e.stopPropagation()}
                variants={dropIn}
                initial={'hidden'}
                animate={'visible'}
                exit={'exit'}
                min_width={props.min_width}
                width={props.width}
                height={props.height}
                min_height={props.min_height}
            >
                {/* {!!props.title ? <ModalTitle>{props.title}</ModalTitle> : null}*/}
                <ModalBody>{props.children}</ModalBody>
                {/*{props.footer}*/}
            </ModalContainer>
        </Backdrop>
    );
};

const ModalComponentForDropdown = (props: ModalProps) => {
    return (
        <Backdrop
        // onClick={props.handleClose}
        >
            <ModalContainer
                onClick={(e) => e.stopPropagation()}
                variants={dropIn}
                initial={'hidden'}
                animate={'visible'}
                exit={'exit'}
                min_width={props.min_width}
                width={props.width}
                height={props.height}
                min_height={props.min_height}
            >
                {/* {!!props.title ? <ModalTitle>{props.title}</ModalTitle> : null}*/}
                <>{props.children}</>
                {/*{props.footer}*/}
            </ModalContainer>
        </Backdrop>
    );
};
export default ModalComponent;
export { ModalComponentForDropdown };
