import React, { useRef, useState } from 'react';
import {
    AiFillCloseCircle,
    AiOutlineDelete,
    AiOutlineUpload,
    AiOutlineSave,
} from 'react-icons/ai';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../ModalComponent';
import PreviewDocuments from '../../utils/PreviewDocuments';
import { IoOpenOutline } from 'react-icons/io5';
import { useCreateModalState } from '../../hooks/useModalHooks';
import styled from 'styled-components';

interface Props {
    handleClose: () => void;
    selectedFile?: string[];
    setSelectedFile?: React.Dispatch<React.SetStateAction<string[]>>;
    selectedFileToUpload: File[];
    setSelectedFileToUpload: React.Dispatch<React.SetStateAction<File[]>>;
}

const SelectDocumentsModal = ({
    selectedFileToUpload,
    selectedFile,
    setSelectedFile,
    setSelectedFileToUpload,
    handleClose,
}: Props) => {
    const inputElement = useRef<any>();

    const [selectedNewFile, setSelectedNewFile] = useState<string[]>([]);
    const [selectedNewFileToUpload, setSelectedNewFileToUpload] = useState<
        File[]
    >([]);

    const preview = useCreateModalState();

    const closeModal = () => {
        preview.closeModal();
        setSelectedNewFile([]);
        setSelectedNewFileToUpload([]);
        inputElement.current.value = null;
    };

    const handleDocumentUpload = (files: FileList | null) => {
        if (!files) return '';
        let selectedNewFileArray: string[] = [...selectedNewFile];
        let selectedNewFileToUploadArray: File[] = [...selectedNewFileToUpload];
        let arr = Object.keys(files);
        arr.map((ele: any) => {
            selectedNewFileArray.push(URL.createObjectURL(files[Number(ele)]));
            selectedNewFileToUploadArray.push(files[Number(ele)]);
        });
        setSelectedNewFile(selectedNewFileArray);
        setSelectedNewFileToUpload(selectedNewFileToUploadArray);
        preview.modal ? closeModal() : preview.openModal();
        inputElement.current.value = null;
    };

    const handleOnClick = async (
        selectedFile: File[],
        setUploadingBtn: any
    ) => {
        let selectedFileToUploadArray: File[] = [...selectedFileToUpload];
        selectedFile.map((ele: File) => {
            selectedFileToUploadArray.push(ele);
        });

        //Removing duplicate files
        const fileNames = selectedFileToUploadArray.map(
            (obj: File) => obj.name
        );
        const uniqueFiles = selectedFileToUploadArray.filter(
            ({ name }: File, index: number) =>
                !fileNames.includes(name, index + 1)
        );

        let selectedFileArray: string[] = uniqueFiles.map((file: File) => {
            return URL.createObjectURL(file);
        });

        setSelectedFile?.([...selectedFileArray]);
        setSelectedFileToUpload([...uniqueFiles]);

        inputElement.current.value = null;
        closeModal();
    };
    return (
        <div className="mt-0 md:col-span-2 h-full overflow-y-scroll">
            <div className="flex w-full justify-between mt-4 px-2  border-b-2 border-gray-200 sticky bottom-0 z-10">
                <h3 className="text-md font-bold leading-6 text-gray-900 mb-4 ml-6">
                    Documents
                </h3>
                <div className="cursor-pointer mr-2" onClick={handleClose}>
                    <AiFillCloseCircle
                        className={'hover:drop-shadow-lg rounded-[50%] '}
                        size={24}
                    />
                </div>
            </div>
            <div className="w-full flex flex-col justify-between ">
                <ParentContainer className="mx-auto my-4  grid grid-cols-1 sm:grid-cols-2 overflow-y-scroll overflow-x-hidden mb-20">
                    {selectedFile?.map((ele: string, index: number) => {
                        return (
                            <div
                                key={`${ele}`}
                                className=" w-[300px] justify-center items-center flex flex-col mx-auto  border-2 border-gray-500 pb-10s scale-90 rounded-lg"
                            >
                                {selectedFileToUpload[index].name.split('.')[
                                    selectedFileToUpload[index].name.split('.')
                                        .length - 1
                                ] === 'jpg' ||
                                selectedFileToUpload[index].name.split('.')[
                                    selectedFileToUpload[index].name.split('.')
                                        .length - 1
                                ] === 'jpeg' ||
                                selectedFileToUpload[index].name.split('.')[
                                    selectedFileToUpload[index].name.split('.')
                                        .length - 1
                                ] === 'png' ||
                                selectedFileToUpload[index].name.split('.')[
                                    selectedFileToUpload[index].name.split('.')
                                        .length - 1
                                ] === 'svg' ? (
                                    <img
                                        src={ele}
                                        style={{
                                            height: '250px',
                                        }}
                                        alt="documents"
                                    />
                                ) : (
                                    <iframe
                                        src={ele}
                                        width="300px"
                                        height="250px"
                                        frameBorder="0"
                                        style={{
                                            borderTopRightRadius: '10px',
                                            borderTopLeftRadius: '10px',
                                        }}
                                        title="render_docs"
                                    ></iframe>
                                )}
                                <div className="w-full flex justify-between items-center my-2 px-2">
                                    <p className=" font-medium ml-2">
                                        Document - {index + 1}
                                    </p>
                                    <div className="flex gap-0">
                                        <button
                                            className="  p-2 rounded-md"
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Are you sure you want to Remove this document ?'
                                                    )
                                                ) {
                                                    let cloneSelectedFile = [
                                                        ...selectedFile,
                                                    ];
                                                    let cloneSelectedFileToUpload =
                                                        [
                                                            ...selectedFileToUpload,
                                                        ];
                                                    cloneSelectedFile.splice(
                                                        index,
                                                        1
                                                    );
                                                    cloneSelectedFileToUpload.splice(
                                                        index,
                                                        1
                                                    );
                                                    setSelectedFile?.([
                                                        ...cloneSelectedFile,
                                                    ]);
                                                    setSelectedFileToUpload([
                                                        ...cloneSelectedFileToUpload,
                                                    ]);
                                                }
                                            }}
                                        >
                                            <AiOutlineDelete size={20} />
                                        </button>

                                        <button
                                            className="  p-2 rounded-md"
                                            onClick={() => {
                                                window.open(ele, '_blank');
                                            }}
                                        >
                                            <IoOpenOutline size={20} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </ParentContainer>
                <div
                    className={
                        'w-full h-20  flex rounded-sm justify-end items-center gap-2  bg-white border-2 border-zinc-200 z-10 absolute bottom-0'
                    }
                >
                    <div>
                        <input
                            ref={inputElement}
                            id="add document"
                            type="file"
                            onChange={(e) => {
                                handleDocumentUpload(e.target.files);
                            }}
                            style={{
                                display: 'none',
                                margin: 0,
                                padding: 0,
                            }}
                            accept="image/*,.pdf,.txt"
                            multiple
                        />

                        <label
                            htmlFor="add document"
                            className="flex gap-2 justify-between items-center cursor-pointer text-sm px-3 py-3.5 overflow-hidden  font-medium bg-primary-500 text-white transition-all duration-200 border mx-4 rounded-md"
                        >
                            Upload
                            <AiOutlineUpload size={18} />
                        </label>
                    </div>
                    <div>
                        <label
                            className="flex gap-2 justify-between items-center cursor-pointer text-sm px-6 py-3.5  overflow-hidden  font-medium bg-green-600 text-white transition-all duration-200 border mx-4 rounded-md"
                            onClick={handleClose}
                        >
                            Save
                            <AiOutlineSave size={18} />
                        </label>
                    </div>
                </div>
            </div>
            {selectedFile?.length === 0 && (
                <div className="flex h-full w-full absolute justify-center translate-y-[25%]">
                    <p className="text-gray-500">No documents uploaded !</p>
                </div>
            )}
            <AnimatePresence initial={false} mode="wait">
                {preview.modal && (
                    <ModalComponent
                        handleClose={closeModal}
                        width={'850px'}
                        min_height={'90vh'}
                    >
                        <PreviewDocuments
                            selectedFile={selectedNewFile}
                            handleClose={closeModal}
                            onClick={handleOnClick}
                            selectedFileToUpload={selectedNewFileToUpload}
                            setSelectedFile={setSelectedNewFile}
                            setSelectedFileToUpload={setSelectedNewFileToUpload}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default SelectDocumentsModal;

const ParentContainer = styled.div`
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
