import React, { Suspense } from 'react';
import Loader from '../utils/Loader';
import { Outlet } from 'react-router-dom';

const PublicLayoutWrapper = () => {
    return (
        <Suspense fallback={<Loader isLoading />}>
            <Outlet />
        </Suspense>
    );
};

export default PublicLayoutWrapper;
