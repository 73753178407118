import React, { useState } from 'react';
import { useCreateModalState } from '../../components/hooks/useModalHooks';
import { AnimatePresence } from 'framer-motion';
import ModalComponent, {
    ModalComponentForDropdown,
} from '../../components/ModalTemplate/ModalComponent';
import ImageViewModal from '../modal/ImageViewModal';

const ImageGridView = ({
    imageArray,
    centerName,
}: {
    imageArray: string[];
    centerName: string;
}) => {
    const [images, setImages] = useState<string[]>(imageArray);
    const imageViewModal = useCreateModalState();

    const makeItFirstImage = (index: number) => {
        let oldArray = [...images];
        oldArray.splice(index, 1);
        oldArray.unshift(images[index]);
        setImages([...oldArray]);
    };

    return (
        <div className="flex flex-col p-4 gap-2 justify-center items-center h-[100%]">
            {images?.length > 0 ? (
                <img src={images[0]} alt="image_1" className="w-full" />
            ) : (
                <img src={'../../DefaultImage.png'} alt="defaultImage" />
            )}

            {images?.length > 1 && (
                <div className="flex w-full gap-2">
                    {images?.length > 1 && (
                        <img
                            src={images[1]}
                            alt="image 2"
                            className="w-[35%]"
                            onClick={() => {
                                makeItFirstImage(1);
                            }}
                        />
                    )}
                    {images?.length > 2 && (
                        <img
                            src={images[2]}
                            alt=" image 3"
                            className="w-[35%]"
                            onClick={() => {
                                makeItFirstImage(2);
                            }}
                        />
                    )}
                    {images?.length > 3 && (
                        <button
                            className="w-[20%] bg-[#1F6299] text-white rounded-sm p-1 font-semibold text-lg"
                            onClick={imageViewModal.openModal}
                        >
                            {'>'}
                        </button>
                    )}
                </div>
            )}
            <AnimatePresence initial={false} mode="wait">
                {imageViewModal.modal && (
                    <ModalComponentForDropdown
                        handleClose={imageViewModal.closeModal}
                        width={'85%'}
                        min_height={'80%'}
                    >
                        <ImageViewModal
                            handleClose={imageViewModal.closeModal}
                            images={images}
                            centerName={centerName}
                        />
                    </ModalComponentForDropdown>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ImageGridView;
