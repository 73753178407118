import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { steps } from '../common/Stepper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { patientFlowStateObject } from '../../redux/selectors';
import { getFullPatientRoute } from '../helpers/contants';
import { SiRazorpay } from 'react-icons/si';
import NoAccess from '../common/NoAccess';
import { useEffect } from 'react';
import { formatPriceINR } from '../helpers/helper';
import styled from 'styled-components';

const PaymentPage = () => {
    const { handleStepper } = useOutletContext<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedCenter } = patientFlowStateObject();
    // const handlePayment = () => {
    //     handleStepper(steps.PAYMENT);
    //     navigate(getFullPatientRoute.paymentComplete(), {
    //         state: { from: location.pathname },
    //     });
    // };
    useEffect(() => {
        window.history.pushState(null, null as any, window.location.href);
    }, []);

    window.onpopstate = function (event) {
        window.history.pushState(null, null as any, window.location.href);
    };

    // if (location?.state?.from !== getFullPatientRoute.orderInfo()) {
    //     return <NoAccess text='Important Message! Test is already created, please complete the payment.'/>;
    // }

    return (
        <ParentContainer className="shadow-lg rounded-2xl">
            <div className="px-8 py-4">
                <div className="flex flex-col items-center">
                    <div className="border w-1/12 rounded-full mb-2">
                        <img
                            className="w-full rounded-full"
                            src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                            alt="image"
                        />
                    </div>
                    <div className="text-md font-bold">
                        <p>{selectedCenter.center}</p>
                    </div>
                </div>

                {/* <div className="flex justify-center h-[400px] overflow-hidden mb-2">
                    <LazyLoadImage
                        src={location?.state?.qrLink}
                        alt="image"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center', // Adjust to crop from the top and bottom as desired
                            width: '60%',
                            height: '100%',
                        }}
                        className="w-1/2 border"
                        // wrapperClassName="lazy-load-image-crop"
                    />
                </div> */}

                <div className="text-md font-semibold flex items-center mb-2 justify-center gap-2">
                    <p>Total: </p>
                    <p className="text-lg text-[#5EA73C]">
                        <span className="font-bold">₹</span>{' '}
                        {formatPriceINR(
                            +selectedCenter.priceDetails?.priceAfterDiscount
                        )}
                    </p>
                </div>

                <div
                    className="flex py-4 pl-6 border shadow rounded-2xl items-center gap-4 hover:bg-gray-100"
                    onClick={() => {
                        window.open(location.state?.paymentLink);
                    }}
                >
                    <span>
                        <SiRazorpay color="#0D94FB" size="20" />
                    </span>
                    <p className="text-sm font-semibold"> Pay via Link</p>
                </div>
            </div>
            {/* <div className="flex justify-center mt-16">
                <button
                    onClick={handlePayment}
                    className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md hover:bg-[#022e53]"
                >
                    Pay Now
                </button>
            </div> */}
        </ParentContainer>
    );
};

export default PaymentPage;

const ParentContainer = styled.div`
    max-height: 75vh;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
