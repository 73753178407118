import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { steps } from '../common/Stepper';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useGetCenterTimeSlotByModalityPublic } from '../../components/hooks/useCenter';
import { useGetBookedSlotsByCenterPublic } from '../../components/hooks/useBooking';
import {
    handleStudyDateSelect,
    handleStudySlotSelect,
    returnBookedSlots,
} from '../../components/slots/Common';
import {
    StudySlotType,
    handleStudySlotData,
} from '../../components/slots/BookSlotsModal';
import TimeSlots from '../../components/slots/TimeSlots';
import { useDispatch } from 'react-redux';
import { patientFlowStateObject } from '../../redux/selectors';
import { toast } from 'react-toastify';
import { mergeDateAndTime } from '../../components/utils';
import { setSelectedCenters } from '../../redux/actions/actions';
import { getFullPatientRoute } from '../helpers/contants';
import NoAccess from '../common/NoAccess';
import styled from 'styled-components';
import { formatISO } from 'date-fns';

type SelectTimeSlot = {
    bookSlotsData: any;
};

const SelectTimeSlot = () => {
    const navigator = useNavigate();

    const { selectedCenter, userDetails } = patientFlowStateObject();
    const { handleStepper } = useOutletContext<any>();
    const dispatch = useDispatch();
    const location = useLocation();

    const [studySlotsData, setStudySlotsData] = useState<StudySlotType>(
        handleStudySlotData(
            selectedCenter?.study,
            selectedCenter?.priceDetails?.studyList,
            selectedCenter.centerId
        )
    );

    const {
        data: centerMachineTimeSlotsData,
        isLoading: centerMachineTimeSlotLoader,
        isError: centerMachineTimeSlotError,
    } = useGetCenterTimeSlotByModalityPublic(
        selectedCenter.centerId,
        selectedCenter?.priceDetails?.studyList?.map((study: any) => {
            return study.modalityId;
        }),
        selectedCenter?.isSchedulerEnabled,
        userDetails.id
    );

    const {
        data: centerBookedSlotsData,
        isLoading: centerBookedSlotLoader,
        isError: centerBookedSlotError,
    } = useGetBookedSlotsByCenterPublic({
        centerId: selectedCenter.centerId,
        selectedDate: new Date(),
        centerMachineIds: centerMachineTimeSlotsData?.map(
            (centerTimeSlot: any) => centerTimeSlot.id
        ),
        isSchedulerEnabled: selectedCenter?.isSchedulerEnabled,
        user_id: userDetails.id,
    });

    const handleSlotSubmit = () => {
        if (
            Object.values(studySlotsData).filter((value) => value.slot === '')
                .length
        ) {
            return toast.error('Timeslot is empty. Please pick a slot');
        }
        Object.values(studySlotsData).forEach((value) => {
            if (!value.slot) {
                return toast.error('Timeslot is empty. Please pick a slot.');
            }
            const slotDateTime = mergeDateAndTime(value.date, value.slot);
            if (new Date() > slotDateTime) {
                return toast.error(
                    'Select timeslot is not valid. Please pick different slot.'
                );
            }
        });

        const getMachineId = (orderStudyId: string) => {
            let centerMachineId = null;
            if (
                centerMachineTimeSlotsData &&
                centerMachineTimeSlotsData?.length
            ) {
                centerMachineId = centerMachineTimeSlotsData.find(
                    (machine: any) =>
                        machine.modalityId ===
                        studySlotsData[orderStudyId].modalityId
                )?.id;
            }
            return centerMachineId ? centerMachineId : null;
        };

        const updatedCenterDetails = {
            studyList: selectedCenter.study.map((study: any) => {
                const studyTimeSlot = studySlotsData[study.id];
                return {
                    studyId: study.id,
                    centerId: selectedCenter.centerId,
                    scheduledDateTime: formatISO(
                        mergeDateAndTime(studyTimeSlot.date, studyTimeSlot.slot)
                    ),
                    centerMachineId: getMachineId(study.id),
                };
            }),
            ...selectedCenter,
        };

        dispatch(setSelectedCenters(updatedCenterDetails));

        handleStepper(steps.SELECT_SLOT);
        navigator(getFullPatientRoute.patientInfo(), {
            state: { from: location.pathname },
        });
    };

    const renderTestPreparation = () => {
        return (
            <div className="border shadow-lg rounded-2xl p-8 md:mt-8">
                <p className="text-md font-bold leading-6">Test Preparation</p>
                <ParentContainer className="px-2 md:h-[45vh] overflow-scroll">
                    {Object.values(studySlotsData)?.map((study, index) => {
                        const testPrep =
                            study.testPreparation &&
                            study.testPreparation.trim().split(';');
                        return (
                            <div className="" key={`i_${index}`}>
                                <div className="flex flex-col gap-2 text-xs my-2 text-justify">
                                    {testPrep && (
                                        <>
                                            <div className="flex flex-row items-center text-xs font-semibold">
                                                <li className="px-2 text-[12px] list-disc">
                                                    {study.studyName}
                                                </li>
                                            </div>
                                            <div className="pl-8">
                                                <ul className="list-disc">
                                                    {testPrep.map(
                                                        (item, index) => (
                                                            <li
                                                                key={`i_${index}`}
                                                            >
                                                                {item.trim()}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </ParentContainer>
            </div>
        );
    };

    if (location?.state?.from !== getFullPatientRoute.centerInfo()) {
        return <NoAccess />;
    }
    return (
        <div className="">
            <div className="flex flex-col md:flex-row gap-8">
                {Object.values(studySlotsData)?.filter((study, index) => {
                    return study.testPreparation;
                })?.length > 0 && (
                    <div className="block md:hidden">
                        {renderTestPreparation()}
                    </div>
                )}
                <div className="flex flex-col flex-1">
                    <p className="text-base text-[#2C2B2B] leading-6 font-semibold mb-2">
                        Select Date and Time Slot
                    </p>
                    <ParentContainer className="max-h-[60vh] overflow-scroll pt-4 rounded-2xl mb-4">
                        {Object.values(studySlotsData)?.map((study, index) => {
                            const defaultTimeSlotObj =
                                centerMachineTimeSlotsData?.filter(
                                    (centerTimeSlot: any) =>
                                        centerTimeSlot.modalityId ===
                                        study.modalityId
                                )![0];

                            return (
                                <div
                                    key={`i_${index}`}
                                    className="flex flex-col gap-4 mb-8"
                                >
                                    <div className="flex flex-row items-center text-xs font-semibold">
                                        <span className="px-2 bg-gray-200 rounded-md">
                                            {index + 1}
                                        </span>
                                        <p className="px-2 text-[12px]">
                                            {study.studyName.toUpperCase()}
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-4 md:px-4">
                                        <div>
                                            <p className="text-xs font-normal text-[#636369]">
                                                Choose a date
                                            </p>
                                            <DatePicker
                                                selected={study.date}
                                                minDate={new Date()}
                                                onChange={(date: Date) => {
                                                    handleStudyDateSelect(
                                                        date,
                                                        Object.keys(
                                                            studySlotsData
                                                        )[index],
                                                        studySlotsData,
                                                        setStudySlotsData
                                                    );
                                                }}
                                                useWeekdaysShort={true}
                                                dateFormat="dd/MM/yyyy (EEEE)"
                                                className="w-full border border-gray-200 bg-white text-sm font-semibold tracking-wider z-[0] p-2 rounded focus:ring-1 ring-primary-500 outline-none cursor-pointer relative"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-4 w-full flex flex-col md:flex-row gap-2 justify-between md:px-4 py-2">
                                        <div className="w-full">
                                            <TimeSlots
                                                timeSlotData={
                                                    defaultTimeSlotObj
                                                        ? defaultTimeSlotObj?.centerMachineDefaultSchedule
                                                        : []
                                                }
                                                bookedTimeSlots={returnBookedSlots(
                                                    study.date,
                                                    defaultTimeSlotObj?.id,
                                                    defaultTimeSlotObj?.enableWalkIn,
                                                    centerBookedSlotsData
                                                )}
                                                isSchedulerEnabled={
                                                    defaultTimeSlotObj?.center
                                                        ?.isSchedulerEnabled ||
                                                    false
                                                }
                                                isMachineActive={
                                                    defaultTimeSlotObj?.isActive
                                                }
                                                scanReportTime={
                                                    defaultTimeSlotObj
                                                        ? defaultTimeSlotObj?.machineReportTime
                                                        : 60
                                                }
                                                deferTime={
                                                    defaultTimeSlotObj?.deferTime
                                                }
                                                selectedDate={study.date}
                                                selectedSlot={study.slot}
                                                isLoading={
                                                    centerBookedSlotLoader ||
                                                    centerBookedSlotError ||
                                                    centerMachineTimeSlotLoader ||
                                                    centerMachineTimeSlotError
                                                }
                                                handleOnSlotSelect={(val) =>
                                                    handleStudySlotSelect(
                                                        val,
                                                        Object.keys(
                                                            studySlotsData
                                                        )[index],
                                                        studySlotsData,
                                                        setStudySlotsData
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </ParentContainer>

                    <div className="flex justify-end">
                        <button
                            onClick={handleSlotSubmit}
                            className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md flex align-center hover:bg-[#022e53]"
                        >
                            Continue
                        </button>
                    </div>
                </div>
                {Object.values(studySlotsData)?.filter((study, index) => {
                    return study.testPreparation;
                })?.length > 0 && (
                    <div className="hidden md:block flex-1">
                        {renderTestPreparation()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectTimeSlot;

const ParentContainer = styled.div`
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
