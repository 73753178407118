import { motion } from 'framer-motion';
import styled from 'styled-components';
import React from 'react';

const BackdropContainer = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    overflow: hidden;
`;

interface Props {
    children: React.ReactNode;
    onClick?: () => void;
}

export const Backdrop = (props: Props) => {
    return (
        <BackdropContainer
            onClick={props.onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {props.children}
        </BackdropContainer>
    );
};
