import { format } from 'date-fns-tz';

export enum OrderLevelStatus {
    PAYMENT_PENDING = 'PAYMENT_PENDING',
    PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
    SLOT_CONFIRMED = 'SLOT_CONFIRMED',
    SLOT_UNAVAILABLE = 'SLOT_UNAVAILABLE',
    ORDER_CANCELLED = 'ORDER_CANCELLED',
    INVOICE_GENERATED = 'INVOICE_GENERATED',
    ORDER_COMPLETED = 'ORDER_COMPLETED',
    RESCHEDULED = 'RESCHEDULED',
}

export enum StudyLevelStatus {
    SCHEDULED = 'SCHEDULED',
    RESCHEDULED = 'RESCHEDULED',
    CHECKED_IN = 'CHECKED_IN',
    PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
    CHECKED_OUT = 'CHECKED_OUT',
    REPORTED = 'REPORTED',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
}

export enum OrderType {
    INDIVIDUAL = 'individual',
    GROUP = 'group',
    BULK = 'bulk',
}

export const statusEnabledForEditPatient = [
    OrderLevelStatus.PAYMENT_PENDING,
    OrderLevelStatus.PAYMENT_RECEIVED,
    OrderLevelStatus.SLOT_CONFIRMED,
];

export const StatusForChangeCenter = [
    OrderLevelStatus.PAYMENT_RECEIVED,
    OrderLevelStatus.SLOT_CONFIRMED,
    StudyLevelStatus.CHECKED_IN,
];

export const StatusForNoChangeCenter = [
    StudyLevelStatus.REPORTED,
    StudyLevelStatus.CHECKED_OUT,
];

export const OneMgCustomerIds = [
    '88959d93-aa90-4a34-aa77-dff284ff7e89',
    'e0c70e0a-fc4a-408e-9aae-437bc8299b2d',
];

export enum SearchQueries {
    TYPE = 'order_type',
    START_DATE = 'start_date',
    END_DATE = 'end_date',
    DATE = 'date',
    STATUS = 'status',
    SEARCH = 'search',
    AGGREGATOR = 'aggregator_id',
    CENTER = 'center_id',
}

export const mergeDateAndTime = (date: Date, time: string) => {
    return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${time}`
    );
};

export const returnISTTime = (
    dateTime: string,
    timeFormat: string | undefined = 'dd MMM, hh:mm a'
) => {
    if (!dateTime) return dateTime;
    return format(new Date(dateTime), timeFormat, { timeZone: 'Asia/Kolkata' });
};

export const formatStatusName = (status: string) => {
    return status
        .split('_')
        .map((str) => {
            return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
        })
        .join(' ');
};

// info : building param string to set as search params
export const buildParams = (paramObject: {
    startDate: string;
    endDate: string;
    type?: string;
    aggregator_id?: string;
    center_id?: string;
    statuses?: string | string[];
    search?: string;
}) => {
    const {
        statuses,
        startDate,
        endDate,
        aggregator_id,
        center_id,
        type,
        search,
    } = paramObject;
    let paramString = '?';
    if (search) {
        paramString += `${SearchQueries.SEARCH}=${search}&`;
    }
    if (aggregator_id) {
        paramString += `${SearchQueries.AGGREGATOR}=${aggregator_id}&`;
    }
    if (center_id) {
        paramString += `${SearchQueries.CENTER}=${center_id}&`;
    }
    for (const status of statuses ?? []) {
        paramString += `${SearchQueries.STATUS}=${status}&`;
    }
    if (startDate && endDate) {
        paramString += `${SearchQueries.START_DATE}=${startDate}&${SearchQueries.END_DATE}=${endDate}`;
    } else {
        paramString += `${SearchQueries.DATE}=all`;
    }
    return paramString;
};
