import React, { useRef, useState } from 'react';

import {
    AiFillCloseCircle,
    AiOutlineFileAdd,
    AiOutlineDelete,
} from 'react-icons/ai';
import { toast } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../ModalComponent';
import PreviewDocuments from '../../utils/PreviewDocuments';
import { getLink } from './InvoiceCard';
import { IoOpenOutline } from 'react-icons/io5';
import { userObject } from '../../../redux/selectors';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useCreateModalState } from '../../hooks/useModalHooks';
import { OrderLevelStatus } from '../../utils';
import styled from 'styled-components';

interface Props {
    id: string;
    handleClose: () => void;
    documents: {
        customerId: string | null;
        id: string;
        link: string;
        orderId: string | null;
        createdAt: string;
    }[];
    draftId?: string;
    userId?: string;
    uploadDocument: UseMutateAsyncFunction<
        any,
        unknown,
        { id: string; formData: any; userId?: any },
        unknown
    >;
    deleteDocument: UseMutateAsyncFunction<
        any,
        unknown,
        { documentId: string; userId?: any },
        unknown
    >;
    orderStatus?: string;
}

const UploadDocumentsModal = ({
    uploadDocument,
    id,
    handleClose,
    documents,
    deleteDocument,
    draftId,
    userId,
    orderStatus,
}: Props) => {
    const inputElement = useRef<any>();
    const user = userObject();

    const [selectedFile, setSelectedFile] = useState<string[]>([]);
    const [selectedFileToUpload, setSelectedFileToUpload] = useState<File[]>(
        []
    );

    const preview = useCreateModalState();

    const closeModal = () => {
        preview.closeModal();
        setSelectedFile([]);
        setSelectedFileToUpload([]);
        inputElement.current.value = null;
    };
    const handleDocumentUpload = (files: FileList | null) => {
        if (!files) return '';
        let selectedFileArray: string[] = [...selectedFile];
        let selectedFileToUploadArray: File[] = [...selectedFileToUpload];
        let arr = Object.keys(files);
        arr.map((ele) => {
            selectedFileArray.push(URL.createObjectURL(files[Number(ele)]));
            selectedFileToUploadArray.push(files[Number(ele)]);
        });
        setSelectedFile([...selectedFileArray]);
        setSelectedFileToUpload([...selectedFileToUploadArray]);
        preview.openModal();
        inputElement.current.value = null;
    };

    const uploadReportToServer = async (files: File[], id: string) => {
        if (files.length > 0) {
            const formData = new FormData();
            files.map((file: File, index: number) =>
                formData.append(`${index}`, file)
            );
            formData.append('bookingId', id);
            await uploadDocument({
                formData: formData,
                id: id,
                userId,
            });
        }
        closeModal();
    };

    const handleOnClick = async (
        selectedFile: File[],
        setUploadingBtn: any
    ) => {
        uploadReportToServer(selectedFile, id)
            .then(() => {
                closeModal();
                setUploadingBtn(false);
            })
            .catch((err) => {
                setUploadingBtn(false);
                toast.error('Failed to upload document');
            });
    };
    return (
        <div className="mt-0 md:col-span-2 h-full overflow-y-scroll">
            <div className="flex w-full justify-between mt-4 px-2  border-b-2 border-gray-200 sticky bottom-0 z-10">
                <h3 className="text-md font-bold leading-6 text-gray-900 mb-4 ml-6">
                    Documents
                </h3>
                <div className="cursor-pointer mr-2" onClick={handleClose}>
                    <AiFillCloseCircle
                        className={'hover:drop-shadow-lg rounded-[50%] '}
                        size={24}
                    />
                </div>
            </div>
            <div className="w-full flex flex-col justify-between ">
                <ParentContainer className="w-full h-full mx-auto my-4  grid grid-cols-1 sm:grid-cols-2 overflow-y-scroll overflow-x-hidden mb-20">
                    {documents?.map(
                        (
                            ele: {
                                customerId: string | null;
                                id: string;
                                link: string;
                                orderId: string | null;
                                createdAt: string;
                            },
                            index: number
                        ) => {
                            return (
                                <div
                                    key={`${ele.id}+1`}
                                    className=" w-[300px] justify-center items-center flex flex-col mx-auto border-2 border-gray-500 pb-10s scale-90 rounded-lg"
                                >
                                    {ele.link?.split('.')[3] === 'jpg' ||
                                    ele.link?.split('.')[3] === 'jpeg' ||
                                    ele.link?.split('.')[3] === 'png' ||
                                    ele.link?.split('.')[3] === 'svg' ? (
                                        <img
                                            src={ele.link}
                                            style={{
                                                height: '250px',
                                            }}
                                            alt={`uploaded_document_${ele.id}`}
                                        />
                                    ) : (
                                        <iframe
                                            //@ts-ignore
                                            src={ele.link}
                                            width="300px"
                                            height="250px"
                                            frameBorder="0"
                                            style={{
                                                borderTopRightRadius: '10px',
                                                borderTopLeftRadius: '10px',
                                            }}
                                            title={`frame_${ele.id}`}
                                        ></iframe>
                                    )}
                                    <div className="flex flex-col w-full">
                                        <div className=" flex justify-between items-center mt-2 px-2">
                                            <p className=" font-medium ml-2">
                                                Document - {index + 1}
                                            </p>
                                            <div className="flex gap-0">
                                                {((user.role === 'admin' &&
                                                    user.accountType ===
                                                        'service_provider') ||
                                                    draftId) && (
                                                    <button
                                                        className="  p-2 rounded-md"
                                                        onClick={() => {
                                                            if (
                                                                window.confirm(
                                                                    'Are you sure you want to delete this document ?'
                                                                )
                                                            ) {
                                                                deleteDocument({
                                                                    documentId:
                                                                        ele.id,
                                                                    userId: userId,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <AiOutlineDelete
                                                            size={20}
                                                        />
                                                    </button>
                                                )}
                                                <button
                                                    className="  p-2 rounded-md"
                                                    onClick={() => {
                                                        if (
                                                            ele.link.includes(
                                                                'cloudfront'
                                                            )
                                                        ) {
                                                            window.open(
                                                                ele.link,
                                                                '_blank'
                                                            );
                                                        } else {
                                                            getLink(
                                                                ele.link
                                                            ).then((res) => {
                                                                window.open(
                                                                    res.url,
                                                                    '_blank'
                                                                );
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <IoOpenOutline size={20} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex justify-start gap-3 text-sm px-4 text-stone-500">
                                            <p>
                                                {
                                                    new Date(ele.createdAt)
                                                        .toLocaleString(
                                                            undefined,
                                                            {
                                                                timeZone:
                                                                    'Asia/Kolkata',
                                                            }
                                                        )
                                                        ?.split(',')[0]
                                                }
                                            </p>
                                            <p>
                                                {
                                                    new Date(ele.createdAt)
                                                        .toLocaleString(
                                                            undefined,
                                                            {
                                                                timeZone:
                                                                    'Asia/Kolkata',
                                                            }
                                                        )
                                                        ?.split(',')[1]
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </ParentContainer>
                {(!orderStatus ||
                    orderStatus !== OrderLevelStatus.ORDER_CANCELLED) && (
                    <div
                        className={
                            'w-full h-20  flex rounded-sm justify-end items-center gap-2  bg-white border-2 border-zinc-200 z-10 absolute bottom-0'
                        }
                    >
                        <input
                            ref={inputElement}
                            id="add document"
                            type="file"
                            onChange={(e) => {
                                handleDocumentUpload(e.target.files);
                            }}
                            style={{
                                display: 'none',
                                margin: 0,
                                padding: 0,
                            }}
                            accept="image/*,.pdf,.txt"
                            multiple
                        />

                        <label
                            htmlFor="add document"
                            className="flex  justify-between items-center cursor-pointer text-sm p-3 overflow-hidden  font-medium bg-primary-500 text-white transition-all duration-200 border mx-4 rounded-md"
                        >
                            Add Document
                            <AiOutlineFileAdd
                                className={'text-[22px] m-0.5 p-0.5'}
                            />
                        </label>
                    </div>
                )}
            </div>
            {documents.length === 0 && (
                <div className="flex h-full w-full absolute justify-center translate-y-[25%]">
                    <p className="text-gray-500">No documents uploaded !</p>
                </div>
            )}

            <AnimatePresence initial={false} mode="wait">
                {preview.modal && (
                    <ModalComponent
                        handleClose={closeModal}
                        width={'850px'}
                        min_height={'90vh'}
                    >
                        <PreviewDocuments
                            selectedFile={selectedFile}
                            handleClose={closeModal}
                            onClick={handleOnClick}
                            selectedFileToUpload={selectedFileToUpload}
                            setSelectedFile={setSelectedFile}
                            setSelectedFileToUpload={setSelectedFileToUpload}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default UploadDocumentsModal;

const ParentContainer = styled.div`
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
