import ModalHeader from '../../components/ModalTemplate/components/ModalHeader';

type TermsConditionsProps = {
    handleClose: () => void;
};

export const TermsConditions = (props: TermsConditionsProps) => {
    return (
        <div>
            <ModalHeader
                title={'Terms and Condition'}
                handleClose={props.handleClose}
            />

            <div className=" mx-4 md:mx-16 mt-8 text-justify">
                <div className="text-md mb-4">
                    <p className="text-sm">
                        &nbsp;&nbsp;&nbsp;&nbsp;This Patient Consent Form is presented to you in respect of the usage of your Data (defined below) by 5C Network (India) Private Limited (<b>5C</b>) on its AI-powered digital diagnostic platform, Prodigi.ai. In relation to the foregoing, 5C will be collecting, storing, and processing your Data, in accordance with the terms below. The purpose of this Consent Form is to seek your consent to utilize your health data for research and development of artificial intelligence (AI) applications in healthcare. If you are not in agreement with any of the below terms, we advise you not to proceed further.
                    </p>
                </div>
                <div>
                    <p className="text-sm font-semibold mb-2">
                        1. Collection of Data:
                    </p>
                    <div className="text-sm pl-4 md:pl-12 leading-6 mb-4">
                        <p>
                            1.1) <b>Data</b> shall mean the following information that you are required to share and from which all personally identifiable information will be removed and de-identified by replacing it with a unique identifier. This process ensures that your data is rendered anonymous, preventing any direct association with your identity.
                        </p>
                        <p className='ml-4'>
                            - Age<br />
                            - Gender<br />
                            - Radiology Images<br />
                            - Radiology Reports<br />
                        </p>
                    </div>
                </div>
                <div>
                    <p className="text-sm font-semibold mb-2">
                        2. Purpose of Collection:
                    </p>
                    <div className="text-sm pl-4 md:pl-12 leading-6 mb-4">
                        <p>
                            &nbsp;&nbsp;At all times we will use your Data for lawful purposes only. Without limiting the foregoing, your Data collected by us will be used for the following purpose(s):
                        </p>
                        <br/>
                        <p>
                            2.1) To develop AI algorithms that can aid medical professionals in making accurate diagnoses, personalized treatment plans, and recommendations based on individual patient data;
                        </p>
                        <p>
                            2.2) To train machine learning algorithms to enhance, develop, and improve healthcare services;
                        </p>
                        <p>
                            2.3) To identify patterns and trends that may lead to breakthroughs in disease understanding, treatment protocols, and the improvement of healthcare practices;
                        </p>
                        <p>
                            2.4) To validate the performance and reliability of AI models to ensure their accuracy and safety in assisting medical decision-making;
                        </p>
                        <p>
                            2.5) To further refine AI applications in healthcare by using your Data in future research and development activities;
                        </p>
                        <p>
                            2.6) To share with our successors-in-interest, assignees, and any other entities consequent upon a corporate restructuring of our organisation, including by way of merger, amalgamation, acquisition, sale of assets, and so on.
                        </p>
                    </div>
                </div>
                <div>
                    <p className="text-sm font-semibold mb-2">
                        3. Security, Disclosure, and Transfer of Data:
                    </p>
                    <div className="text-sm pl-4 md:pl-12 leading-6 mb-4">
                        <p>
                            3.1) 5C is committed to maintaining the security and confidentiality of your Data. Access to your Data will be restricted to authorized personnel directly for the purposes set forth hereinabove.
                        </p>
                        <p>
                            3.2) To the extent permitted by applicable law, we may share, disclose and transfer all or any of your Data, to our employees, affiliates, consultants, advisors, auditors, lawyers, and third party partners, including but not limited research organizations, healthcare institutions, and regulatory authorities (<b>Recipients</b>).
                        </p>
                        <p>
                            3.3) You agree and acknowledge that some of the purposes set forth in the foregoing paragraphs may involve the transfer of such Data to Recipients outside India. You agree, acknowledge and consent to the same.
                        </p>
                        <p>
                            3.4) You agree that on the basis of your consent, Recipients may collect and process your Data for the purposes set forth hereinabove and that you will not hold such Recipients liable for receiving and processing your Data.
                        </p>
                    </div>
                </div>
                <div>
                    <p className="text-sm font-semibold mb-2">
                        4. Period of retention
                    </p>
                    <div className="text-sm pl-4 md:pl-12 leading-6 mb-4">
                        <p>
                            4.1) We will keep the Data collected from you in the following circumstances:
                        </p>
                        <p className='ml-6'>
                            <p>
                                a. As may be required under any law and to fulfil applicable legal and regulatory mandates.
                            </p>
                            <p>
                                b. For as long as your Data is deemed useful as evidence.
                            </p>
                        </p>
                    </div>
                </div>
                <div>
                    <p className="text-sm font-semibold mb-2">
                        5. Your Rights:
                    </p>
                    <div className="text-sm pl-4 md:pl-12 leading-6 mb-4">
                        <p>
                            5.1) You understand that you can contact privacy@5cnetwork.com for any queries, grievances, or concerns on how we are collecting and processing your Data and in general to exercise the rights provided in this section.
                        </p>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;However, in cases of you refusing to provide consent for the collection, storage, processing, and sharing of your Data, you agree that you may not be able to avail the services you opted for and through which you were re-directed to this form
                        </p>
                    </div>
                </div>
                <div>
                    <p className="text-sm font-semibold mb-2">
                        6. Disclaimer:
                    </p>
                    <div className="text-sm pl-4 md:pl-12 leading-6 mb-4">
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;By agreeing to these terms, you consent to receiving communications via email and WhatsApp for booking updates.
                            You agree and acknowledge that your acceptance of these terms is completely voluntary, without any coercion and you agree that such a decision is at your own risk.
                        </p>
                    </div>
                </div>
                <div>
                    <p className="text-sm">
                        &nbsp;&nbsp;&nbsp;&nbsp;In the event that any part of these terms shall be held by a court of competent jurisdiction to be unlawful or otherwise unenforceable, the remainder of these terms shall remain in full force and effect to the maximum extent possible, and an attempt shall be made to substitute the unenforceable provision with an enforceable provision of similar intent.
                    </p>
                </div>
                <div>
                    <p className='text-sm font-semibold underline pt-6'>CLICKING 'I ACCEPT' CONFIRMS YOUR UNCONDITIONAL ACCEPTANCE OF THESE TERMS.</p>
                </div>
                <div className="flex justify-center py-8 px-16">
                    <button
                        onClick={props.handleClose}
                        className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md hover:bg-[#022e53]"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};
