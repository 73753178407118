import { returnISTTime } from '../utils';
import { StudySlotType } from './BookSlotsModal';
import { isEqual } from 'date-fns';

export const handleStudyDateSelect = (
    date: Date,
    studyId: string,
    studySlotsData: StudySlotType,
    setStudySlotsData: React.Dispatch<React.SetStateAction<StudySlotType>>,
    isCombinedSlot?: boolean
) => {
    if (isCombinedSlot) {
        setStudySlotsData((prevVal) => {
            for (let studyId in prevVal) {
                prevVal[studyId].date = date;
                prevVal[studyId].slot = '';
            }
            return {
                ...prevVal,
            };
        });
    } else {
        setStudySlotsData((prevVal) => {
            return {
                ...prevVal,
                [studyId]: {
                    ...studySlotsData[studyId],
                    date: date,
                    slot: '',
                },
            };
        });
    }
};

export const handleStudySlotSelect = (
    slot: string,
    studyId: string,
    studySlotsData: StudySlotType,
    setStudySlotsData: React.Dispatch<React.SetStateAction<StudySlotType>>,
    isCombinedSlot?: boolean
) => {
    if (isCombinedSlot) {
        setStudySlotsData((prevVal) => {
            for (let studyId in prevVal) {
                prevVal[studyId].slot = slot;
            }
            return {
                ...prevVal,
            };
        });
    } else {
        setStudySlotsData((prevVal) => {
            return {
                ...prevVal,
                [studyId]: {
                    ...studySlotsData[studyId],
                    slot: slot,
                },
            };
        });
    }
};

export const returnBookedSlots = (
    selectedDate: Date,
    machineId: string,
    walkIn: boolean,
    centerBookedSlotsData: any
) => {
    if (walkIn) return [];
    const bookedSlots: string[] = [];
    centerBookedSlotsData?.map(
        (value: any) =>
            value.centerMachineId === machineId &&
            isEqual(
                new Date(selectedDate.setHours(0, 0, 0, 0)),
                new Date(returnISTTime(value.scheduledDateTime, 'MM/dd/yyyy'))
            ) &&
            bookedSlots.push(returnISTTime(value.scheduledDateTime, 'p'))
    );
    return bookedSlots;
};

export const calculateTotalContrastPrice = (order: any) => {
    const totalContrastPrice = order?.study
        ?.map((study: any) => {
            return parseInt(study.contrastPrice);
        })
        ?.reduce((a: number, b: number) => a + b, 0);
    return Math.ceil(totalContrastPrice);
};

export const calculateTotalBasePrice = (order: any) => {
    const totalBasePrice = order?.study
        ?.map((study: any) => {
            return parseInt(study.basePrice);
        })
        ?.reduce((a: number, b: number) => a + b, 0);
    return Math.ceil(totalBasePrice);
};
