import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import axiosInstance from '../../services/axiosInstance';
import { getAPIEndPoints } from '../../router/utils/api';

//Get all order
const getAllOrders = (uid) => {
    return axiosInstance.get(`/orders`).then((res) => res.data.data);
};

//Get modality
const getAllModality = () => {
    return axiosInstance.get('/modality').then((res) => res.data.data);
};

//Get modality by city
const getModalityByCity = (city) => {
    if (city) {
        return axiosInstance
            .get(`/modality?search=${city}`)
            .then((res) => res.data.data);
    } else {
        return axiosInstance.get(`/modality`).then((res) => res.data.data);
    }
};

//Get city
const getAllCities = () => {
    return axiosInstance.get('/city').then((res) => res.data.data);
};

//Get pincode
const getAllPincode = (city) => {
    return axiosInstance.get(`/pincode/${city}`).then((res) => res.data.data);
};

//Get study
const getAllStudy = (modalityId) => {
    return axiosInstance
        .get(`/study/modality/${modalityId}`)
        .then((res) => res.data.data);
};

//Get all centers by study
const searchCenterByStudy = async (searchData) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.center.searchCenterByStudy(),
        searchData
    );
    return res.data.data;
};

//Get all centers by study
const searchCenterByPackage = async (searchData) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.center.searchCenterByPackage(),
        searchData
    );
    return res.data.data;
};

export function useFetchOrders() {
    return useQuery(['allOrders'], () => getAllOrders(), {
        onError: (err) => {
            if (err.response?.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching orders');
                console.log(err);
            }
        },
    });
}

export function useGetModality() {
    const navigate = useNavigate();
    return useQuery(['modality'], getAllModality, {
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching modality');
                console.log(err);
            }
        },
    });
}

export function useGetModalityByCity(city) {
    const navigate = useNavigate();
    return useQuery(['modalityByCity', city], () => getModalityByCity(city), {
        enabled: city !== null,
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching pincode list');
                console.log(err);
            }
        },
    });
}

export function useGetCities() {
    const navigate = useNavigate();
    return useQuery(['cities'], getAllCities, {
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching city list');
                console.log(err);
            }
        },
    });
}

export function useGetPincode(city) {
    const navigate = useNavigate();
    return useQuery(['pincode', city], () => getAllPincode(city), {
        enabled: city !== null,
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching pincode list');
                console.log(err);
            }
        },
    });
}

export function useGetStudy(modalityId) {
    const navigate = useNavigate();

    return useQuery(['study', modalityId], () => getAllStudy(modalityId), {
        enabled: !!modalityId,
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching study');
                console.log(err);
            }
        },
    });
}

export function useSearchCenterByStudy() {
    const queryClient = useQueryClient();

    return useMutation(searchCenterByStudy, {
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                queryClient.invalidateQueries().then(() => {
                    console.log(err.response.data);
                    toast.error(err.response.data.data);
                });
            }
        },
    });
}

export function useSearchCenterByPackage() {
    const queryClient = useQueryClient();

    return useMutation(searchCenterByPackage, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                queryClient.invalidateQueries().then(() => {
                    console.log(err.response.data);
                    toast.error(err.response.data.data);
                });
            }
        },
    });
}

//patient flow
//Get city
const getAllCitiesPublic = async (userId) => {
    return await axiosInstance
        .get('/public/city', {
            params: {
                user_id: userId,
            },
        })
        .then((res) => res.data.data);
};

//Get pincode
const getAllPincodePublic = async (city, userId) => {
    return await axiosInstance
        .get(`/public/pincode/${city}`, {
            params: {
                user_id: userId,
            },
        })
        .then((res) => res.data.data);
};

//Get study
const getAllModStudyPublic = async ({ userId, studySearch, limit, offset }) => {
    return await axiosInstance
        .get(`/public/study`, {
            params: {
                user_id: userId,
                ...(limit && { limit }),
                ...(offset && { offset }),
                ...(studySearch && { studyName: studySearch }),
            },
        })
        .then((res) => res.data.data);
};

//Get study
const getDraftOrderPublic = async ({ draftId }) => {
    return await axiosInstance
        .get(`/public/order/draft?draft_order_id=${draftId}`)
        .then((res) => res.data.data);
};

//Get all centers by study
const searchCenterByStudyPublic = async (data) => {
    const { userId, searchData } = data;
    const res = await axiosInstance.post(
        '/public/search/center/study',
        searchData,
        {
            params: {
                role: 'admin',
                account_type: 'aggregator',
                user_id: userId,
            },
        }
    );
    return res.data.data;
};

export function useGetAllModStudyPublic() {
    return useMutation(getAllModStudyPublic, {
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('something went wrong');
            } else {
                toast.error('Error fetching study');
                console.log(err);
            }
        },
    });
}

export function useGetDraftOrderPublic(draftId) {
    return useQuery(
        ['draftOrderPublic'],
        () => getDraftOrderPublic({ draftId }),
        {
            enabled: draftId !== null,
            onError: (err) => {
                if (err.response.status === 401) {
                    toast.error('something went wrong');
                } else {
                    toast.error('Error fetching study');
                    console.log(err);
                }
            },
        }
    );
}

export function useGetPincodePublic(city, userId) {
    const navigate = useNavigate();
    return useQuery(
        ['pincodePublic', city],
        () => getAllPincodePublic(city, userId),
        {
            enabled: city !== null && userId !== null,
            onError: (err) => {
                if (err.response.status === 401) {
                    toast.error('something went wrong');
                } else {
                    toast.error('Error fetching pincode list');
                    console.log(err);
                }
            },
        }
    );
}

export function useGetCitiesPublic(userId) {
    return useQuery(['citiesPublic'], () => getAllCitiesPublic(userId), {
        enabled: userId !== null,
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('something went wrong');
            } else {
                toast.error('Error fetching city list');
                console.log(err);
            }
        },
    });
}

export function useSearchCenterByStudyPublic() {
    const queryClient = useQueryClient();

    return useMutation(searchCenterByStudyPublic, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
        onError: (err) => {
            if (err.response.status === 401) {
                toast.error('something went wrong');
            } else {
                queryClient.invalidateQueries().then(() => {
                    console.log(err.response.data);
                    toast.error(err.response.data.data);
                });
            }
        },
    });
}
