import React from 'react';
import { ImLocation } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import { patientFlowStateObject } from '../../redux/selectors';
import { getFullPatientRoute } from '../helpers/contants';
import NoAccess from '../common/NoAccess';
import { removeDrTitleFromDoctor } from '../helpers/helper';

const PaymentCompletePage = () => {
    const { selectedCenter, patientDetails, userDetails } =
        patientFlowStateObject();
    const location = useLocation();

    if (location?.state?.from !== getFullPatientRoute.payment()) {
        return <NoAccess />;
    }

    const doctorName = removeDrTitleFromDoctor(
        userDetails?.physicianName ?? ''
    );

    return (
        <div>
            <div className="shadow-lg rounded-2xl px-8 py-4">
                <div className="flex justify-center">
                    <img
                        src="/payment_success.gif"
                        alt="payment-success"
                        className="w-1/4"
                    />
                </div>

                <div className="my-4">
                    <p className="text-[#1F6299] text-sm font-bold text-center mb-6">
                        Your Booking has been Confirmed
                    </p>
                    <div className="flex gap-4 items-start mb-4">
                        <div className="border w-1/12 rounded-full">
                            <img
                                className="w-full rounded-full"
                                src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                                alt="image"
                            />
                        </div>
                        <div>
                            <div className="text-md font-bold">
                                <p>{selectedCenter.center}</p>
                            </div>
                            <div className="text-xs leading-5 flex items-baseline gap-2">
                                <ImLocation color="#1890FF" />
                                <p>{selectedCenter.location}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between mb-4">
                        <div className="text-sm font-bold">
                            <p>{patientDetails.name}</p>
                        </div>

                        <div className="text-[#1F6299] text-sm">
                            {doctorName
                                ? `Referred By Dr. ${doctorName}`
                                : `Referred By ${userDetails?.displayName}`}
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-4 flex justify-center text-sm text-[#1F6299] font-bold">
                <p>
                    You will be notified everything about your test booking on
                    your Email ID and whatsapp
                </p>
            </div>
            <div className="flex justify-center mt-4">
                <button
                    onClick={() => {
                        window.close();
                    }}
                    className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md hover:bg-[#022e53]"
                >
                    Close Window
                </button>
            </div>
        </div>
    );
};

export default PaymentCompletePage;
