import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import 'react-datetime/css/react-datetime.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UserContextProvider } from './context/UserContext';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_POST_HOG_TOKEN) {
    posthog.init(process.env.REACT_APP_POST_HOG_TOKEN, {
        api_host: process.env.REACT_APP_POST_HOG_URL,
    });
}

// This will stop re-fetch on when you switch tabs
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <UserContextProvider>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div>Loading</div>}>
                    <App />
                </Suspense>
                <ReactQueryDevtools
                    initialIsOpen={false}
                    position={'bottom-right'}
                />
            </QueryClientProvider>
        </UserContextProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
