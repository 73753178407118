import React, { useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import {
    IoMdArrowDroprightCircle,
    IoMdArrowDropleftCircle,
} from 'react-icons/io';

type ImageViewModalType = {
    handleClose: () => void;
    images: string[];
    centerName: string;
};
const ImageViewModal = (props: ImageViewModalType) => {
    const { handleClose, images, centerName } = props;
    const [imagesData, setImagesData] = useState<string[]>(images);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

    const loadNextImage = () => {
        setCurrentImageIndex((prev) => prev + 1);
    };
    const loadPrevImage = () => {
        setCurrentImageIndex((prev) => prev - 1);
    };

    return (
        <div className="flex flex-col grow h-[50vh] border-4">
            {/* Header of the modal--------------- */}
            <div className="flex w-full justify-between mt-4 px-2  border-b-2 border-gray-200">
                <h3 className="text-md font-bold leading-6 text-gray-900 mb-4 ml-6">
                    {centerName}
                </h3>
                <div className="cursor-pointer mr-2" onClick={handleClose}>
                    <AiFillCloseCircle
                        className={'hover:drop-shadow-lg rounded-[50%] '}
                        size={24}
                    />
                </div>
            </div>

            {/* Body of Header--------------------- */}
            <div className="py-2  px-4   flex flex-col justify-between items-center grow h-[80%] ">
                <div className="flex flex-row justify-between w-full h-[85%]">
                    <div className="h-full w-[13%] flex flex-col justify-center items-center">
                        {' '}
                        <button
                            disabled={currentImageIndex === 0}
                            className="disabled:opacity-25"
                            onClick={loadPrevImage}
                            border-2
                        >
                            <IoMdArrowDropleftCircle size={40} />
                        </button>
                    </div>
                    <div className="w-full h-full">
                        <div className="flex flex-col  justify-center items-center h-full">
                            <img
                                src={images[currentImageIndex]}
                                className="object-contain h-[100%]"
                            />
                        </div>
                    </div>
                    <div className="h-full w-[13%] flex flex-col justify-center items-center">
                        <button
                            disabled={currentImageIndex === images.length - 1}
                            className="disabled:opacity-25"
                            onClick={loadNextImage}
                        >
                            <IoMdArrowDroprightCircle size={40} />
                        </button>
                    </div>
                    {/* 
         
         
           */}
                </div>
                <div className="flex justify-center items-center p-2 text-lg w-full h-[15%] font-medium">
                    {currentImageIndex + 1}
                    {' of '}
                    {images.length}
                </div>
            </div>
        </div>
    );
};

export default ImageViewModal;
