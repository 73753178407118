import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useUserContext } from '../../context/useAuth';
import { userObject } from '../../redux/selectors';
import { useGetCustomer } from '../hooks/useCustomers';
import { ImCancelCircle } from 'react-icons/im';

import prodigi_pass_logo from '../../assets/images/prodigi_pass_logo_xxl.png';

const UserSidebar = ({ menuItems }: any) => {
    const navigateTo = useNavigate();
    const { logoutUser }: any = useUserContext();

    const [isNavOpen, setIsNavOpen] = useState(false); //this state will be used for hamburger menu

    const customer = useGetCustomer(userObject().customerID);

    return (
        <div>
            <nav className="lg:hidden py-6 px-6 border-b drop-shadow-sm">
                <div className="flex items-center justify-between">
                    <Link className="text-2xl font-semibold" to="/">
                        <img
                            src={prodigi_pass_logo}
                            alt="prodigi_logo"
                            className="w-[200px]"
                        />
                    </Link>
                    <button
                        className="navbar-burger flex items-center rounded focus:outline-none"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <MdMenu size={24} />
                    </button>
                </div>
            </nav>
            {/* hamburger starts here */}
            <section className="lg:hidden w-full flex ">
                <div
                    className={`${
                        isNavOpen
                            ? 'block fixed w-full h-screen top-0 z-10 right-0 bg-white pt-4 drop-shadow flex-col sm:w-1/2'
                            : 'hidden'
                    }`}
                >
                    <div className="flex flex-row items-center">
                        {customer?.data?.logoUrl ? (
                            <img
                                className="w-[100px]"
                                src={customer?.data?.logoUrl}
                                alt=""
                            />
                        ) : null}
                    </div>
                    <div
                        className="absolute top-0 right-0 px-8 py-8 "
                        onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
                    >
                        <ImCancelCircle size={20} />
                    </div>
                    <div>
                        {menuItems?.map((data: any, index: any) =>
                            data.url ? (
                                <button
                                    key={`i_${index}`}
                                    className={`flex w-full cursor-pointer font-medium text-sm items-center p-4 ${
                                        window.location.pathname === data.url ||
                                        window.location.pathname ===
                                            data.url +
                                                '/' +
                                                userObject().customerID
                                            ? 'bg-primary-100 text-primary-600'
                                            : 'bg-white  hover:bg-gray-100 hover:text-gray-900 transition-all duration-200'
                                    }`}
                                    onClick={() => {
                                        if (data.url === '/logout') {
                                            logoutUser().then(() => {
                                                toast.success(
                                                    'Logged out successfully'
                                                );
                                                navigateTo('/');
                                            });
                                        } else if (data.url === '/') {
                                        } else if (
                                            data.url === '/aggregator-ledger'
                                        ) {
                                            navigateTo(
                                                `/aggregator-ledger/${
                                                    userObject().customerID
                                                }`
                                            );
                                        } else {
                                            navigateTo(
                                                data.url?.replace(
                                                    ':customerId',
                                                    userObject().customerID
                                                )
                                            );
                                        }
                                    }}
                                >
                                    <span className="inline-block mr-3">
                                        <data.icon size={24} />
                                    </span>

                                    {data.subtitle ? (
                                        <div className={'grid text-left'}>
                                            <span>{userObject()?.name}</span>
                                            {data.subtitle && (
                                                <span
                                                    className={
                                                        'text-[12px] italic text-gray-500 font-normal'
                                                    }
                                                >
                                                    {data.subtitle}
                                                </span>
                                            )}
                                        </div>
                                    ) : (
                                        <span>{data.title}</span>
                                    )}
                                </button>
                            ) : (
                                <h3
                                    key={`i_${index}`}
                                    className="m-4 mb-1 text-xs uppercase text-gray-400 font-semibold"
                                >
                                    {data.title}
                                </h3>
                            )
                        )}
                    </div>
                </div>
            </section>
            {/* hamburger ends */}

            <div className="hidden lg:block navbar-menu z-50">
                <nav className="fixed top-0 left-0 bottom-0 flex flex-col justify-between items-center w-3/4 lg:w-64 max-w-xs bg-white border-r overflow-hidden overflow-y-auto">
                    <div className="w-full">
                        <Link
                            className="h-[4.6rem] px-4 flex flex-col items-start justify-center font-semibold lg:border-b border-gray-200"
                            to="#"
                        >
                            <img
                                src={prodigi_pass_logo}
                                alt="prodigi_logo"
                                className="w-[60%]"
                            />
                            {/* <div className="flex flex-row items-center">
                                {customer?.data?.logoUrl ? (
                                    <img
                                        className="w-[80px]"
                                        src={customer?.data?.logoUrl}
                                        alt=""
                                    />
                                ) : null}
                            </div> */}
                        </Link>
                    </div>
                    <div className="w-full h-full pb-6">
                        {menuItems?.map((data: any, index: any) =>
                            data.url ? (
                                <button
                                    key={`i_${index}`}
                                    className={`flex w-full cursor-pointer font-medium text-sm items-center p-4 ${
                                        window.location.pathname === data.url ||
                                        window.location.pathname ===
                                            data.url +
                                                '/' +
                                                userObject().customerID
                                            ? 'bg-primary-100 text-primary-600'
                                            : 'bg-white  hover:bg-gray-100 hover:text-gray-900 transition-all duration-200'
                                    }`}
                                    onClick={() => {
                                        if (data.url === '/logout') {
                                            logoutUser().then(() => {
                                                toast.success(
                                                    'Logged out successfully'
                                                );
                                                navigateTo('/');
                                            });
                                        } else if (data.url === '/') {
                                        } else if (
                                            data.url === '/aggregator-ledger'
                                        ) {
                                            navigateTo(
                                                `/aggregator-ledger/${
                                                    userObject().customerID
                                                }`
                                            );
                                        } else {
                                            navigateTo(
                                                data.url?.replace(
                                                    ':customerId',
                                                    userObject().customerID
                                                )
                                            );
                                        }
                                    }}
                                >
                                    <span className="inline-block mr-3">
                                        <data.icon size={24} />
                                    </span>

                                    {data.subtitle ? (
                                        <div className={'grid text-left'}>
                                            <span>{userObject()?.name}</span>
                                            {data.subtitle && (
                                                <span
                                                    className={
                                                        'text-[12px] italic text-gray-500 font-normal'
                                                    }
                                                >
                                                    {data.subtitle}
                                                </span>
                                            )}
                                        </div>
                                    ) : (
                                        <span>{data.title}</span>
                                    )}
                                </button>
                            ) : (
                                <h3
                                    key={`i_${index}`}
                                    className="m-4 mb-1 text-xs uppercase text-gray-400 font-semibold"
                                >
                                    {data.title}
                                </h3>
                            )
                        )}
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default UserSidebar;
