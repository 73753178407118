import { toast } from 'react-toastify';

export const copyToClipboard = (data: string) => {
    navigator.clipboard
        .writeText(data)
        .then((r) => toast.success('Copied to clipboard'))
        .catch((e) => {
            toast.error('Failed to copy');
        });
};
