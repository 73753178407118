import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TestSelected from './common/TestSelected';
import Stepper from './common/Stepper';
import { IoIosArrowBack } from 'react-icons/io';
import { getFullPatientRoute } from './helpers/contants';
import { getRoutesEndPoints } from '../router/utils/routes';
import { patientFlowStateObject } from '../redux/selectors';

const PatientFlow = () => {
    const [completedActivity, setCompletedActivity] = useState<string[]>([]);
    const [centerList, setCenterList] = useState<Array<{}>>([]);
    const [selectedFile, setSelectedFile] = useState<string[]>([]);
    const [selectedFileToUpload, setSelectedFileToUpload] = useState<File[]>(
        []
    );
    const [fcmToken, setFcmToken] = useState<string>('');
    const location = useLocation();
    const navigate = useNavigate();
    const { draftDetails, userDetails } = patientFlowStateObject();

    window.addEventListener('beforeunload', function (event) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to refresh the page?';
        if (draftDetails?.draftOrderId) {
            this.sessionStorage.setItem(
                'lastRoute',
                `${getRoutesEndPoints.patientFlow.patientFlowHome()}?draft_order_id=${
                    draftDetails.draftOrderId
                }`
            );
        } else if (userDetails?.id) {
            this.sessionStorage.setItem(
                'lastRoute',
                `${getRoutesEndPoints.patientFlow.patientFlowHome()}?user_id=${
                    userDetails.id
                }`
            );
        }
    });

    useEffect(() => {
        const lastRoute = sessionStorage.getItem('lastRoute');
        if (lastRoute) {
            sessionStorage.removeItem('lastRoute');
            window.history.pushState(null, null as any, lastRoute);
            navigate(lastRoute);
        }
    }, []);

    const updateCenterList = (centers: [{}]) => {
        const filteredCenters = centers.map((data: any) => {
            return {
                centerId: data.id,
                center: data.centerName,
                isSchedulerEnabled: data.isSchedulerEnabled,
                areaLocation: data.location,
                city: data.city,
                location: data.address,
                pincode: data.pincode,
                distance: data.distance,
                priceDetails: {
                    id: data.id,
                    totalPrice: data.totalPrice,
                    priceAfterDiscount: data.priceAfterDiscount,
                    priceToCenter: data.priceToCenter,
                    priceToProvider: data.priceToProvider,
                    providerCommission: data.providerCommission,
                    aggCommission: data.aggCommission,
                    studyList: data.priceBreakdown,
                    discountPercentage: '0',
                },
                description: data.description ? data.description : '',
                study: data.study,
                slots: '',
                amenities: data?.centerDetails?.amenities,
                machineType:data?.centerDetails?.machineType,
                centerImages: data?.centerDetails?.images,
                modalityDiscount: data?.modalityDiscount,
            };
        });
        setCenterList(filteredCenters);
    };

    const handleStepper = (activity: string) => {
        if (!completedActivity.includes(activity)) {
            setCompletedActivity((prev) => [...prev, activity]);
        }
    };
    return (
        <div className="py-4 px-[1%] flex gap-8 items-start justify-between flex-col lg:flex-row">
            <div className="hidden lg:block w-1/3">
                <TestSelected />
            </div>

            <div className="border lg:w-2/3 w-full rounded-2xl p-[2%] shadow min-h-[90vh]">
                {![getFullPatientRoute.payment()].includes(
                    location.pathname
                ) && (
                    <div className="mb-8">
                        <button
                            className="flex gap-2 items-center font-semibold text-sm active:bg-gray-200 rounded"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <IoIosArrowBack />
                            Back
                        </button>
                    </div>
                )}
                <Stepper activity={completedActivity} />
                <div className="flex lg:hidden">
                    <TestSelected />
                </div>
                <Outlet
                    context={{
                        handleStepper,
                        updateCenterList,
                        centerList,
                        selectedFile,
                        setSelectedFile,
                        selectedFileToUpload,
                        setSelectedFileToUpload,
                        setCenterList,
                        setFcmToken,
                        fcmToken,
                    }}
                />
            </div>
        </div>
    );
};

export default PatientFlow;
