import { useMemo, useState } from 'react';
import PincodeSearchInputBox, {
    PincodeObjType,
} from '../../utils/PincodeSearchInputBox';
import ModalHeader from './ModalHeader';
import { toast } from 'react-toastify';
import SearchResults from '../../order/AddOrder/SearchResults';
import { useSearchCenterByStudy } from '../../hooks/useTempOrder';
import { OrderType } from '../../utils';
import { StudyObjType } from '../../hooks/useStudy';
import Loader from '../../utils/Loader';

type ChangeCenterModalPropsType = {
    handleClose: () => void;
    orderDetails: any;
};

const ChangeCenterModal = (props: ChangeCenterModalPropsType) => {
    const { handleClose, orderDetails } = props;
    const {
        user,
        discountPercentage,
        orderStudy,
        centerId,
        center: { pincode, pincodeLong, pincodeLat, isProviderPriceType, city },
    } = orderDetails;

    const studyItems = orderStudy.map(({ study }: { study: StudyObjType }) => {
        return {
            label: study.studyName,
            value: study.id,
            modalityId: study.modality.id,
            tags: study.tags,
        };
    });

    const [pincodeObj, setPincodeObj] = useState<PincodeObjType>({
        value: pincode,
        lat: String(pincodeLat),
        long: String(pincodeLong),
        error: false,
    });

    const {
        mutateAsync: searchCenterByStudy,
        data: searchCenterByStudyData,
        isLoading: searchCenterByStudyLoading,
    } = useSearchCenterByStudy();

    // removes current order center from list
    const filterCenterListForReplace = useMemo(() => {
        return searchCenterByStudyData?.filter(
            (centerData: any) => centerData.id !== centerId
        );
    }, [searchCenterByStudyData, centerId]);

    const handleSearch = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (pincodeObj.lat === null || pincodeObj.long === null) {
            toast.error('Please add valid pincode');
            return;
        }

        searchCenterByStudy({
            location: city,
            selectedStudy: studyItems,
            discount: discountPercentage ?? '0',
            pincode: pincodeObj,
            customerId: user.customerId,
            isProviderPriceType,
        });
    };

    const changeCenterDetails = {
        orderDetails: orderDetails,
        isChangeCenter: true,
        searchModalClose: handleClose,
    };

    return (
        <div className="flex flex-col justify-between">
            <ModalHeader
                handleClose={handleClose}
                title="Change Center"
            ></ModalHeader>
            <div className="flex flex-row p-6 justify-between gap-2 items-end">
                <div className="flex flex-col grow">
                    <label htmlFor="pincode">Enter Pincode</label>
                    <PincodeSearchInputBox
                        pincodeState={pincodeObj}
                        setPincodeState={setPincodeObj}
                    />
                </div>
                <button
                    onClick={(e) => {
                        handleSearch(e);
                    }}
                    disabled={
                        searchCenterByStudyLoading ||
                        pincodeObj.value?.length !== 6
                    }
                    className="px-8 py-2 text-white rounded text-sm bg-primary-600 hover:bg-primary-700 hover:drop-shadow-lg transition duration-300 ease-in-out disabled:opacity-70"
                >
                    Search
                </button>
            </div>
            <Loader isLoading={searchCenterByStudyLoading}>
                {!!filterCenterListForReplace ? (
                    <SearchResults
                        data={filterCenterListForReplace}
                        typeAndStudy={{
                            type: OrderType.INDIVIDUAL,
                        }}
                        changeCenterDetails={changeCenterDetails}
                    />
                ) : (
                    <></>
                )}
            </Loader>
        </div>
    );
};

export default ChangeCenterModal;
