import axiosInstance from '../../services/axiosInstance';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MultiValue } from 'react-select';

import { getAPIEndPoints } from '../../router/utils/api';
import { DraftOrderStatusType } from '../../constants/constant';
import { StudyListOptionType } from '../utils/StudySelect';
import { STATUS_COLORS } from '../../constants/statusColors';
import { ORDER_TYPES } from '../templates/DashboardFilter';

type useGetOrderListObjType = {
    offset: number;
    limit: number;
    start_date?: Date;
    end_date?: Date;
    search?: string;
    type?: ORDER_TYPES;
    customer_ids?: string[];
    center_ids?: string[];
    statuses?: string[];
};

type useGetOrderCountObjType = Omit<
    useGetOrderListObjType,
    'offset' | 'limit' | 'pageIndex' | 'pageRowCount' | 'totalRows'
>;

export type OrderObjType = {
    id: string;
    type: string;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
    userId: string;
    centerId: string;
    patientId: string;
    totalPrice: number;
    centerTimeSlotId: string;
    discountPercent: string;
    statusName: keyof typeof STATUS_COLORS;
    invoiceLink: string;
    invoiceLinkPdf: string;
    aggCommission: number;
    mrpTotalPrice: number;
    patientRoutingRate: number;
    priceToProvider: number;
    providerCommission: number;
    invoiceNumber: number;
    priceToCenter: number;
    isPaid: boolean;
    isSentToOms: boolean;
    facilitationCharge: number;
    payload: Object;
    transactionCreated: boolean;
    physicianId: string;
    cancelReason: string;
    couponId: string;
    center: [Object];
    packages: [];
    user: [Object];
    patient: [Object];
    centerTimeSlot: string;
    orderStudy: [];
    razorpayData: [];
};

type GetDraftOrderType = {
    limit: number;
    offset: number;
    search?: string;
    start_date?: Date;
    end_date?: Date;
    user_id?: string[];
    statuses?: (keyof typeof DraftOrderStatusType)[];
};

export type CustomerObj = {
    customerId: string;
    name: string;
};

export type DraftOrderObjType = {
    id?: string;
    user_id?: string;
    customerObj?: CustomerObj;
    patient_id?: string;
    patient_name: string;
    patient_contact: number;
    patient_email: string;
    patient_age: number;
    patient_gender: string;
    tests: MultiValue<StudyListOptionType> | StudyListOptionType[];
    discount: string;
    files: any;
    clinician_notes: string;
};

export type DocumentObjType = {
    createdAt: string;
    customerId?: string;
    draftOrderId: string;
    id: string;
    link: string;
    orderId?: string;
};

export type PatientObjType = {
    age: string;
    createdAt: string;
    email: string;
    fcm_token: string;
    gender: string;
    id: string;
    isPrepaid: string;
    name: string;
    note: string;
    otherDetails: {};
    phoneNumber: string;
    updatedAt: string;
    userId: string;
};

export type RawDraftOrderObjType = {
    centerId: string;
    createdAt: string;
    documents: DocumentObjType[];
    id: string;
    orderId: string;
    patient: PatientObjType;
    patientId: string;
    physicianId: string;
    status: keyof typeof DraftOrderStatusType;
    updatedAt: string;
    userId: string;
    discount: string;
    user: {
        id: string;
        customer: CustomerObj;
    };
    study: {
        id: string;
        studyName: string;
        modalityId: string;
        tags: string[];
    }[];
    clinician_notes: string;
};

export type OrderStatusCountObj = { status: string; count: number };
export type CustomerStatusCountObj = { userId: string; count: number };
export type CenterStatusCountObj = { centerId: string; count: number };

//Get order list
const getOrderList = async (params: useGetOrderListObjType) => {
    if (params?.search) {
        delete params.start_date;
        delete params.end_date;
    }
    const res = await axiosInstance.get(getAPIEndPoints.common.getOrderList(), {
        params: params,
    });

    return res.data.data as {
        count: number;
        order_list: OrderObjType[];
    };
};

//Get order status count
const getOrderStatusCount = async (params: useGetOrderCountObjType) => {
    if (params?.search) {
        delete params.start_date;
        delete params.end_date;
    }
    const res = await axiosInstance.get(
        getAPIEndPoints.admin.getOrderStatusCount(),
        {
            params: params,
        }
    );

    return res.data.data as OrderStatusCountObj[];
};

//Get order customer count
const getOrderCustomerCount = async (params: useGetOrderCountObjType) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.admin.getOrderCustomerCount(),
        {
            params: params,
        }
    );

    return res.data.data as CustomerStatusCountObj[];
};

//Get order center count
const getOrderCenterCount = async (params: useGetOrderCountObjType) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.admin.getOrderCenterCount(),
        {
            params: params,
        }
    );

    return res.data.data as CenterStatusCountObj[];
};

//Get all order
const getPincodeDetails = async (pincode: string) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${pincode}.json?country=in&language=en&access_token=${process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}`;
    const res = await axios.get(url);
    return res.data;
};

//Get all order
const getOrderById = async (orderId: string) => {
    const res = await axiosInstance.get(`/order/${orderId}`);
    return res.data.data;
};

//Get all order by customer Id for aggregator
const getOrderByCId = async (customerId: string) => {
    const res = await axiosInstance.get(`/customer-order/${customerId}`);
    return res.data.data;
};

//Get all order by Center ID for admin
const getOrderByCenterId = async (centerId: string) => {
    const res = await axiosInstance.get(`/center-order/${centerId}`);
    return res.data.data;
};

//Get all order by Center ID for admin
const getCenterByCenterId = async (centerId: string) => {
    const res = await axiosInstance.get(`/center/${centerId}`);
    return res.data.data;
};

//Get all order by user Id for aggregator
const getAllOrderByUId = async (userId: string) => {
    const res = await axiosInstance.get(`/user-order/${userId}`);
    return res.data.data;
};

//Get all transactions by order Id for patients
const getAllOrdersForPatient = async (orderId: string, centerId: string) => {
    const res = await axiosInstance.get(
        `/patient/orders/${orderId}/${centerId}`
    );
    return res.data.data;
};

// Add order
const addOrder = async (data: any) => {
    const res = await axiosInstance.post(`/order`, data);
    return res.data.data;
};

const updateOrder = async (data: any) => {
    const res = await axiosInstance.patch(`/order/${data.orderId}`, data);
    return res.data.data;
};

// Update time slot
const updateOrderTimeSlot = async (data: any) => {
    const res = await axiosInstance.post(`/order/reschedule`, data);
    return res.data.data;
};

const updateOrderPhysician = async (data: any) => {
    const res = await axiosInstance.post(`/order/update/physician`, data);
    return res.data.data;
};

const uploadDocumentOrder = async ({ id: orderId, formData }: any) => {
    const res = await axiosInstance.post(
        `documents/upload/order/${orderId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res.data;
};

const uploadDocumentCustomer = async ({ id: customerId, formData }: any) => {
    const res = await axiosInstance.post(
        `documents/upload/customer/${customerId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res.data;
};

const uploadDocumentDraftOrder = async ({
    id: draftId,
    formData,
    userId,
}: any) => {
    const res = await axiosInstance.post(
        `public/documents/upload/draft-order/${draftId}`,
        formData,
        {
            params: {
                user_id: userId,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res.data;
};

const uploadImageCenter = async ({ id: centerId, formData }: any) => {
    const res = await axiosInstance.post(
        `center/upload/images/${centerId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res.data;
};

const deleteImageCenter = async ({ id: centerId, images }: any) => {
    const res = await axiosInstance.put(
        `center/delete/images/${centerId}`,
        images
    );
    return res.data;
};

const deleteDocument = async ({ documentId }: any) => {
    const res = await axiosInstance.post(`document/delete/${documentId}`);
    return res.data;
};

// Draft Order

const getDraftOrders = async (params: GetDraftOrderType) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.aggregator.getDraftOrder(),
        {
            params,
        }
    );
    return res.data?.data;
};

const createDraftOrder = async (formData: any) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.aggregator.createDraftOrder(),
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res.data;
};

const updateDraftOrderById = async (draftData: any) => {
    const res = await axiosInstance.patch(
        getAPIEndPoints.aggregator.updateDraftOrder(draftData.id),
        draftData
    );
    return res.data;
};

const deleteDraftOrderById = async (draftId: string) => {
    const res = await axiosInstance.delete(
        getAPIEndPoints.aggregator.deleteDraftOrder(draftId)
    );
    return res.data;
};

//patient flow

const deletePublicDocument = async ({ documentId, userId }: any) => {
    console.log({ userId });
    const res = await axiosInstance.post(
        `public/document/delete/${documentId}`,
        {},
        {
            params: {
                user_id: userId,
            },
        }
    );
    return res.data;
};

export function useFetchOrderList(params: useGetOrderListObjType) {
    return useQuery(['orderList', params], () => getOrderList(params), {
        keepPreviousData: true,
        onError: (err: any) => {
            if (err.response?.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                toast.error('Error fetching orders');
                console.log(err);
            }
        },
    });
}

export function useFetchOrderStatusCount(params: useGetOrderCountObjType) {
    return useQuery(
        ['orderStatusCount', params],
        () => getOrderStatusCount(params),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                if (err.response?.status === 401) {
                    toast.error('Session expired. Please refresh page');
                } else {
                    toast.error('Error fetching orders');
                    console.log(err);
                }
            },
        }
    );
}

export function useFetchOrderCustomerCount(params: useGetOrderCountObjType) {
    return useQuery(
        ['orderCustomerCount', params],
        () => getOrderCustomerCount(params),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                if (err.response?.status === 401) {
                    toast.error('Session expired. Please refresh page');
                } else {
                    toast.error('Error fetching orders');
                    console.log(err);
                }
            },
        }
    );
}

export function useFetchOrderCenterCount(params: useGetOrderCountObjType) {
    return useQuery(
        ['orderCenterCount', params],
        () => getOrderCenterCount(params),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                if (err.response?.status === 401) {
                    toast.error('Session expired. Please refresh page');
                } else {
                    toast.error('Error fetching orders');
                    console.log(err);
                }
            },
        }
    );
}

export function useGeocoding() {
    return useMutation(getPincodeDetails, {
        onError: (err: any) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
}

export function useFetchOrderById(orderId: string) {
    return useQuery(['orderByOrderId', orderId], () => getOrderById(orderId), {
        onError: (err: any) => {
            toast.error('Error fetching order');
            console.log(err);
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
}

export function useGetOrderByCustomerId() {
    return useMutation(getOrderByCId, {
        onError: (err: any) => {
            toast.error('Error fetching order');
            console.log(err);
        },
    });
}

export function useFetchOrderByCustomerId(customerId?: string) {
    return useQuery(
        ['allOrdersByCustomerId', customerId],
        () => getOrderByCId(customerId || ''),
        {
            enabled: !!customerId,
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}

export const useUploadDocumentDraftOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(uploadDocumentDraftOrder, {
        onSuccess: () => {
            queryClient.invalidateQueries(['draftOrderPublic']).then(() => {
                toast.success('Document uploaded successfully');
            });
        },
        onError: (err) => {
            toast.error('Error uploading document');
            console.log(err);
        },
    });
};

export function useFetchOrderByCenterId(centerId?: string) {
    return useQuery(
        ['allOrdersByCenterId', centerId],
        () => getOrderByCenterId(centerId || ''),
        {
            enabled: !!centerId,
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}
export function useFetchCenterByCenterId(centerId?: string) {
    return useQuery(
        ['getDetailsByCenterId', centerId],
        () => getCenterByCenterId(centerId || ''),
        {
            enabled: !!centerId,
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}

export function useAllOrdersByUserId(userId?: string) {
    return useQuery(
        ['allOrdersByUserId', userId],
        () => getAllOrderByUId(userId || ''),
        {
            enabled: !!userId,
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}

export function useGetOrdersForPatientInvoice(
    orderId: string,
    centerId: string
) {
    return useQuery(
        ['allTransactionsForPatient', orderId],
        () => getAllOrdersForPatient(orderId, centerId),
        {
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}

export function useFetchOMSOrderById(orderId: string) {
    const navigate = useNavigate();
    return useQuery(
        ['omsOrderByOrderId', orderId],
        () =>
            axiosInstance
                .get(`oms-order/${orderId}`)
                .then((res) => res.data.data),
        {
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please login again');
                    navigate('/', { replace: true });
                }
            },
        }
    );
}

export function useAddOrder() {
    const queryClient = useQueryClient();
    return useMutation(addOrder, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Slot Booked!');
                return;
            });
        },
        onError: (err: any) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                queryClient.invalidateQueries().then(() => {
                    toast.error('Error creating order');
                });
            }
        },
    });
}

export function useUpdateOrder() {
    const queryClient = useQueryClient();
    return useMutation(updateOrder, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Center and Slot Updated!');
                return;
            });
        },
        onError: (err: any) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                queryClient.invalidateQueries().then(() => {
                    toast.error('Error updating center');
                });
            }
        },
    });
}

export function useRescheduleSlot() {
    const queryClient = useQueryClient();
    return useMutation(updateOrderTimeSlot, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
        onError: (err: any) => {
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            } else {
                queryClient.invalidateQueries().then(() => {
                    toast.error('Error updating slot');
                });
            }
        },
    });
}

export const useUpdateOrderPhysician = (physicianId: string) => {
    const queryClient = useQueryClient();

    return useMutation(updateOrderPhysician, {
        onSuccess: (data) => {
            queryClient
                .invalidateQueries(['PhysicianName', physicianId])
                .then(() => {
                    toast.success('Physician Changed successfully');
                });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useUploadDocumentOrder = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(uploadDocumentOrder, {
        onSuccess: () => {
            queryClient
                .invalidateQueries(['documents', 'fetch', 'order'])
                .then(() => {
                    toast.success('Document uploaded successfully');
                });
        },
        onError: (err: any) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error uploading document');
                console.log(err);
            }
        },
    });
};

export const useUploadDocumentsCustomer = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(uploadDocumentCustomer, {
        onSuccess: () => {
            queryClient
                .invalidateQueries(['documents', 'fetch', 'customer'])
                .then(() => {
                    toast.success('Document uploaded successfully');
                });
        },
        onError: (err: any) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error uploading document');
                console.log(err);
            }
        },
    });
};

export const useUploadImageCenter = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(uploadImageCenter, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Images uploaded successfully');
            });
        },
        onError: (err: any) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error uploading images');
                console.log(err);
            }
        },
    });
};

export const useDeleteImageCenter = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(deleteImageCenter, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Image Deleted successfully');
            });
        },
        onError: (err: any) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error deleting image');
                console.log(err);
            }
        },
    });
};

export const useDeleteDocument = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(deleteDocument, {
        onSuccess: () => {
            queryClient.invalidateQueries(['documents', 'fetch']).then(() => {
                toast.success('Document deleted successfully');
            });
        },
        onError: (err: any) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error deleting document');
                console.log(err);
            }
        },
    });
};

export function useGetDocumentsOrder(orderId: string) {
    const navigate = useNavigate();
    return useQuery(
        ['documents', 'fetch', 'order', orderId],
        () =>
            axiosInstance
                .get(`/getDocuments/order/${orderId}`)
                .then((res) => res.data.data),
        {
            onError: (err: any) => {
                toast.error('Error fetching documents');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please login again');
                    navigate('/', { replace: true });
                }
            },
        }
    );
}

export function useGetDocumentsCustomer(customerId?: string) {
    const navigate = useNavigate();
    return useQuery(
        ['documents', 'fetch', 'customer', customerId],
        () =>
            axiosInstance
                .get(`/getDocuments/customer/${customerId}`)
                .then((res) => res.data.data),
        {
            onError: (err: any) => {
                toast.error('Error fetching documents');
                console.log(err);
                if (err?.response?.status === 401) {
                    toast.error('Session expired. Please login again');
                    navigate('/', { replace: true });
                }
            },
        }
    );
}

// Get Status
const getStatusLogByOrderId = async (orderId: string) => {
    const res = await axiosInstance.get(`/order/statuslog/${orderId}`);
    return res.data.data;
};

export function useGetStatusLogByOrderId(orderId: string) {
    return useQuery(
        ['allStatusLogByOrderId'],
        () => getStatusLogByOrderId(orderId),
        {
            onError: (err: any) => {
                toast.error('Error fetching order');
                console.log(err);
                if (err.response.status === 401) {
                    toast.error('Session expired. Please refresh page');
                }
            },
        }
    );
}

// Draft Order

export const useGetDraftOrders = (params: GetDraftOrderType) => {
    return useQuery(['getDraftOrder', params], () => getDraftOrders(params), {
        onError: (err: any) => {
            toast.error('Error fetching draft order');
            console.log(err);
            if (err.response.status === 401) {
                toast.error('Session expired. Please refresh page');
            }
        },
    });
    // return useMutation(getDraftOrders, {
    //     onError: (err: any) => {
    //         if (err?.response?.status === 401) {
    //             toast.error('Session expired. Please refresh page');
    //         } else {
    //             toast.error('Error fetching study');
    //             console.log(err);
    //         }
    //     },
    // });
};

export const useCreateDraftOrder = () => {
    const queryClient = useQueryClient();
    return useMutation(createDraftOrder, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['getDraftOrder']).then(() => {
                toast.success(
                    'Draft order created successfully and notified patient.'
                );
                return;
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error?.response?.status === 401) {
                return toast.error('Session expired. Please refresh page');
            }
            return toast.error(error?.message);
        },
    });
};

export const useUpdateDraftOrderById = () => {
    const queryClient = useQueryClient();
    return useMutation(updateDraftOrderById, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['getDraftOrder']).then(() => {
                toast.success(
                    'Updated draft order successfully and notified patient.'
                );
                return;
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                return toast.error('Session expired. Please refresh page');
            }
        },
    });
};

export const useDeleteDraftOrderById = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteDraftOrderById, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['getDraftOrder']).then(() => {
                toast.success('Draft order deleted successfully.');
                return;
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                return toast.error('Session expired. Please refresh page');
            }
        },
    });
};

//patient flow

const createPatientFlowOrder = async (data: any) => {
    const { formData, userDetails } = data;
    const res = await axiosInstance.post(
        '/public/order/with-document',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                user_id: userDetails.id,
                display_name: userDetails.displayName,
            },
        }
    );
    return res.data;
};

export const useDeletePublicDocument = () => {
    const queryClient = useQueryClient();

    return useMutation(deletePublicDocument, {
        onSuccess: () => {
            queryClient.invalidateQueries(['draftOrderPublic']).then(() => {
                toast.success('Document deleted successfully');
            });
        },
        onError: (err) => {
            toast.error('Error deleting document');
            console.log(err);
        },
    });
};

export const useCreatePatientFlowOrder = () => {
    return useMutation(createPatientFlowOrder, {
        onSuccess: (data) => {
            toast.success('Test created successfully');
            return;
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 401) {
                return toast.error('something went wrong');
            }
        },
    });
};
