import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper';

const Carousel = ({
    data,
    CardComponent,
    breakpoints = {
        // when window width is >= 640px
        200: {
            slidesPerView: 1,
        },
        // when window width is >= 640px
        500: {
            slidesPerView: 2,
        },
        // when window width is >= 768px
        770: {
            slidesPerView: 3,
        },
    },
    paginationInsideImage = false,
}: {
    data: any;
    CardComponent: ({ cardData }: any) => JSX.Element;
    breakpoints?: any;
    paginationInsideImage?: boolean;
}) => {
    return (
        <Swiper
            breakpoints={breakpoints}
            spaceBetween={30}
            pagination={{
                clickable: true,
                renderBullet(index, className) {
                    return `<span class=${className} style="background: #1F6299; color: #1F6299;"></span>`;
                },
            }}
            modules={[Pagination, Autoplay]}
            className={`${paginationInsideImage ? 'pb-[0px]' : 'pb-[40px]'}`}
            // loop={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
        >
            {data?.map((cardData: any, index: number) => {
                {
                    return (
                        <SwiperSlide key={index + '1'}>
                            <CardComponent cardData={cardData} />
                        </SwiperSlide>
                    );
                }
            })}
        </Swiper>
    );
};

export default Carousel;
