import React, { useEffect, useState } from 'react';
import { BiUpload } from 'react-icons/bi';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../../components/ModalTemplate/ModalComponent';
import { TermsConditions } from '../common/TermsConditions';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { steps } from '../common/Stepper';
import { useCreateModalState } from '../../components/hooks/useModalHooks';
import SelectDocumentsModal from '../../components/ModalTemplate/components/SelectDocumentsModal';
import { patientFlowStateObject } from '../../redux/selectors';
import { useDispatch } from 'react-redux';
import { setCreatePatientDetails } from '../../redux/actions/actions';
import { getFullPatientRoute } from '../helpers/contants';
import NoAccess from '../common/NoAccess';
import UploadDocumentsModal from '../../components/ModalTemplate/components/UploadDocumentsModal';
import {
    useDeletePublicDocument,
    useUploadDocumentDraftOrder,
} from '../../components/hooks/useOrder';
import { useGetDraftOrderPublic } from '../../components/hooks/useTempOrder';

const PatientInfo = () => {
    const { patientDetails, draftDetails, userDetails } =
        patientFlowStateObject();
    const [patientDetailsInForm, setPatientDetailsInForm] =
        useState(patientDetails);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { mutateAsync: uploadDraftDocument } = useUploadDocumentDraftOrder();
    const { mutateAsync: mutateDeleteDocument } = useDeletePublicDocument();

    const uploadDocsModal = useCreateModalState();
    const termsConditionModal = useCreateModalState();
    const {
        handleStepper,
        selectedFile,
        setSelectedFile,
        selectedFileToUpload,
        setSelectedFileToUpload,
        setFcmToken,
    } = useOutletContext<any>();

    const [isTAndCAccept, setIsTAndCAccept] = useState<boolean>(false);

    const { data: draftData, isFetching: isFetchingDraft } =
        useGetDraftOrderPublic(draftDetails?.draftOrderId);
  

    const handleFormOnChange = (event: any) => {
        const { name, value } = event.target;
        setPatientDetailsInForm({ ...patientDetailsInForm, [name]: value });
    };

    const handleFormPatientDetails = (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        dispatch(setCreatePatientDetails(patientDetailsInForm));
        handleStepper(steps.PATIENT_DETAILS);
        navigate(getFullPatientRoute.orderInfo(), {
            state: { from: location.pathname },
        });
    };

    const handlePatientType = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'myself') {
            setPatientDetailsInForm({
                ...patientDetailsInForm,
                isForSomeone: false,
            });
        } else {
            setPatientDetailsInForm({
                ...patientDetailsInForm,
                isForSomeone: true,
            });
        }
    };

    // useEffect(() => {
    //     getToken(messageService, {
    //         vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    //     })
    //         .then((currentToken) => {
    //             if (currentToken) {
    //                 setFcmToken(currentToken);
    //             } else {
    //                 requestPermission();
    //             }
    //         })
    //         .catch((err) => {
    //             console.log('An error occurred while retrieving token. ', err);
    //         });

    //     onMessage(messageService, async (payload) => {
    //         handleStepper(steps.PAYMENT);
    //         navigate(getFullPatientRoute.paymentComplete(), {
    //             state: { from: getFullPatientRoute.payment() },
    //         });
    //     });
    // }, []);

    if (!userDetails?.id && !draftDetails?.draftOrderId) {
        return <NoAccess />;
    }

    return (
        <div>
            <p className="text-[#2C2B2B] text-md font-bold leading-6 mb-8 ">
                Patient Details
            </p>
            <form onSubmit={handleFormPatientDetails}>
                <div className="flex text-sm text-[#707070] justify-between items-center mb-5 flex-wrap">
                    <p className="font-semibold mb-2 lg:mb-0">
                        Booking test for:
                    </p>
                    <div className="flex w-[400px] items-center flex-initial justify-between">
                        <div className="flex flex-row items-center gap-2">
                            <input
                                type="radio"
                                required
                                name="patientType"
                                value="myself"
                                id="myself"
                                checked={!patientDetailsInForm.isForSomeone}
                                className="w-4 h-4"
                                onChange={handlePatientType}
                            />
                            <label htmlFor="myself">Myself</label>
                        </div>
                        <div className="flex items-center gap-2">
                            <input
                                required
                                type="radio"
                                name="patientType"
                                value="someoneElse"
                                id="someoneElse"
                                checked={patientDetailsInForm.isForSomeone}
                                className="w-4 h-4"
                                onChange={handlePatientType}
                            />
                            <label htmlFor="someoneElse">Someone else</label>
                        </div>
                    </div>
                </div>

                {patientDetailsInForm.isForSomeone && (
                    <div className="flex flex-col gap-6 mb-8">
                        <p className="text-xs font-semibold leading-6">
                            Contact Information
                        </p>

                        <div className="flex gap-6">
                            <div className="flex flex-col w-full">
                                <label
                                    htmlFor="contactName"
                                    className="text-[#636369] text-xs"
                                >
                                    Full Name
                                </label>
                                <input
                                    id="contactName"
                                    name="contactName"
                                    required
                                    onChange={handleFormOnChange}
                                    value={patientDetailsInForm.contactName}
                                    className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label
                                    htmlFor="contactPhone"
                                    className="text-[#636369] text-xs"
                                >
                                    Phone
                                </label>
                                <input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    placeholder="00000 00000"
                                    pattern="\d{10}"
                                    required
                                    maxLength={10}
                                    title="Please enter a 10 digit number, ex:0000000000"
                                    value={patientDetailsInForm.phone}
                                    onChange={handleFormOnChange}
                                    className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <label
                                htmlFor="contactEmail"
                                className="text-[#636369] text-xs"
                            >
                                Email
                            </label>
                            <input
                                id="email"
                                name="email"
                                required
                                type="email"
                                value={patientDetailsInForm.email}
                                onChange={handleFormOnChange}
                                className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                            />
                        </div>
                    </div>
                )}

                <div className="flex flex-col gap-6 mb-8">
                    <p className="text-xs font-semibold leading-6">
                        Patient Information
                    </p>

                    <div className="flex gap-6">
                        <div className="flex flex-col w-full">
                            <label
                                htmlFor="name"
                                className="text-[#636369] text-xs"
                            >
                                Full Name
                            </label>
                            <input
                                id="name"
                                name="name"
                                required
                                value={patientDetailsInForm.name}
                                onChange={handleFormOnChange}
                                className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                            />
                        </div>
                        {!patientDetailsInForm.isForSomeone && (
                            <div className="flex flex-col w-full">
                                <label
                                    htmlFor="phone"
                                    className="text-[#636369] text-xs"
                                >
                                    Phone
                                </label>
                                <input
                                    id="phone"
                                    name="phone"
                                    maxLength={10}
                                    type="tel"
                                    pattern="\d{10}"
                                    title="Please enter a 10 digit number ex:0000000000"
                                    value={patientDetailsInForm.phone}
                                    required
                                    placeholder="00000 00000"
                                    onChange={handleFormOnChange}
                                    className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                                />
                            </div>
                        )}
                    </div>
                    {!patientDetailsInForm.isForSomeone && (
                        <div className="flex flex-col w-full">
                            <label
                                htmlFor="email"
                                className="text-[#636369] text-xs"
                            >
                                Email
                            </label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                placeholder="Ex: abc@domain.com"
                                value={patientDetailsInForm.email}
                                title="Please enter a valid email id: abc@domain.com"
                                onChange={handleFormOnChange}
                                className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                            />
                        </div>
                    )}

                    <div className="flex flex-row gap-6">
                        <div className="flex flex-col w-full">
                            <label
                                htmlFor="age"
                                className="text-[#636369] text-xs"
                            >
                                Age
                            </label>
                            <input
                                id="age"
                                name="age"
                                maxLength={3}
                                type="number"
                                max={125}
                                min={0}
                                placeholder="Ex: 23"
                                required
                                value={patientDetailsInForm.age}
                                onChange={handleFormOnChange}
                                className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[8px] text-sm font-semibold text-[#414149]"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label
                                htmlFor="gender"
                                className="text-[#636369] text-xs"
                            >
                                Gender
                            </label>
                            <select
                                id="gender"
                                name="gender"
                                onChange={handleFormOnChange}
                                value={patientDetailsInForm.gender}
                                className="block border w-full rounded focus:ring-1 ring-primary-500 outline-none p-[7px] text-sm font-semibold text-[#414149]"
                            >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="transgender">Transgender</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center mb-5">
                    <p className="text-[#636369] text-xs font-normal">
                        Prescription
                    </p>
                    <div>
                        <button
                            type="button"
                            onClick={uploadDocsModal.openModal}
                            className="px-4 py-2 flex gap-2 border rounded items-center text-sm font-semibold text-[#414149] hover:bg-[#d7d7da]"
                        >
                            <BiUpload size={18} />
                            Document
                        </button>
                    </div>
                </div>
                <div className="flex gap-4 items-center text-[#636369] mb-6">
                    <input
                        type="checkbox"
                        name="termsCondition"
                        className="w-4 h-4"
                        onClick={() => setIsTAndCAccept(!isTAndCAccept)}
                    />
                    <label
                        htmlFor="termsCondition"
                        className="text-xs font-normal"
                    >
                        I agree to the{' '}
                        <span
                            className="underline text-[#1F6299]"
                            onClick={termsConditionModal.openModal}
                        >
                            terms & condition
                        </span>
                    </label>
                </div>
                <div className="flex justify-center">
                    <button
                        disabled={!isTAndCAccept}
                        type="submit"
                        className="bg-[#1F6299] px-4 py-[5px] text-white disabled:bg-[#5d97c6] font-medium test-sm rounded-md hover:bg-[#022e53]"
                    >
                        Continue
                    </button>
                </div>
            </form>

            <AnimatePresence initial={false} mode="wait">
                {termsConditionModal.modal && (
                    <ModalComponent
                        handleClose={termsConditionModal.closeModal}
                        width={'1200px'}
                    >
                        <TermsConditions
                            handleClose={termsConditionModal.closeModal}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>

            <AnimatePresence initial={false} mode="wait">
                {uploadDocsModal.modal && (
                    <ModalComponent
                        handleClose={uploadDocsModal.closeModal}
                        width={'800px'}
                        min_height={'80%'}
                    >
                        {draftDetails.draftOrderId ? (
                            <UploadDocumentsModal
                                handleClose={uploadDocsModal.closeModal}
                                //@ts-ignore
                                uploadDocument={uploadDraftDocument}
                                id={draftDetails.draftOrderId}
                                draftId={draftDetails.draftOrderId}
                                documents={draftData.documents}
                                //@ts-ignore
                                deleteDocument={mutateDeleteDocument}
                                userId={userDetails?.id}
                            />
                        ) : (
                            <SelectDocumentsModal
                                handleClose={uploadDocsModal.closeModal}
                                selectedFile={selectedFile}
                                selectedFileToUpload={selectedFileToUpload}
                                setSelectedFile={setSelectedFile}
                                setSelectedFileToUpload={
                                    setSelectedFileToUpload
                                }
                            />
                        )}
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PatientInfo;
