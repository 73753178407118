import React from 'react';
import { GrMapLocation } from 'react-icons/gr';
import { useCreateModalState } from '../../hooks/useModalHooks';
import ChangeCenterModal from './ChangeCenterModal';
import { AnimatePresence } from 'framer-motion';
import ModalComponent from '../ModalComponent';
import {
    OneMgCustomerIds,
    OrderLevelStatus,
    OrderType,
    StatusForChangeCenter,
    StatusForNoChangeCenter,
    StudyLevelStatus,
} from '../../utils';
import { userObject } from '../../../redux/selectors';
import { CaslUserRoles } from '../../../casl/ability';
import { OrderActionType } from '../../../constants/constant';

const CenterDetailsCard = ({
    orderDetail,
    isCombinedSlots,
    handleReschedule,
}) => {
    const changeCenter = useCreateModalState();
    const {
        type,
        orderStudy,
        center: { isProviderPriceType },
        user: { customerId },
    } = orderDetail;

    const { accountType, role } = userObject();

    const isChangeCenterAvailable = () => {
        let isAnyOrderStudyCheckedOut = false;
        console.log({ orderStudy });
        if (orderDetail.statusName === StudyLevelStatus.CHECKED_IN) {
            isAnyOrderStudyCheckedOut = Boolean(
                orderStudy.some((study) =>
                    StatusForNoChangeCenter.includes(study.statusName)
                )
            );
        }

        return (
            type === OrderType.INDIVIDUAL &&
            !OneMgCustomerIds.includes(customerId) &&
            isProviderPriceType &&
            accountType === CaslUserRoles.SERVICE_PROVIDER &&
            role === CaslUserRoles.ADMIN &&
            StatusForChangeCenter.includes(orderDetail.statusName) &&
            !isAnyOrderStudyCheckedOut
        );
    };

    const handleModalState = () => {
        changeCenter.modal
            ? changeCenter.closeModal()
            : changeCenter.openModal();
    };

    const renderCenterChangeButton = () => {
        return (
            <button
                onClick={handleModalState}
                className={
                    'border text-primary-700 border-primary-700 hover:bg-primary-100 py-2 px-6 text-sm font-medium rounded disabled:cursor-not-allowed disabled:opacity-50'
                }
            >
                Change Center
            </button>
        );
    };

    const renderPackageReschedule = () => {
        const enableRescheduleStatus = [
            StudyLevelStatus.SCHEDULED,
            StudyLevelStatus.RESCHEDULED,
            OrderLevelStatus.PAYMENT_RECEIVED,
        ];
        return isCombinedSlots ? (
            <div className="text-xs text-red-500">
                <button
                    disabled={
                        !enableRescheduleStatus.includes(
                            orderDetail.orderStudy[0]?.statusName
                        ) ||
                        orderDetail?.orderDetail?.actionType ===
                            OrderActionType.RESCHEDULE_REQUESTED
                    }
                    onClick={() => handleReschedule(orderDetail.orderStudy[0])}
                    className={
                        'text-sm text-primary-600 cursor-pointer underline disabled:cursor-not-allowed disabled:opacity-50 hover:text-primary-700'
                    }
                >
                    Reschedule
                </button>
                {orderDetail?.orderDetail?.actionType ===
                    OrderActionType.RESCHEDULE_REQUESTED &&
                    ` - Reschedule is requested for this tests
                    `}
            </div>
        ) : (
            <></>
        );
    };
    return (
        <div className="w-full flex flex-row py-2 items-center justify-between">
            <div className="flex flex-row justify-start items-start gap-2">
                <GrMapLocation className="text-xl text-gray-900 mt-4 mr-4" />
                <div className="w-full flex flex-col justify-center items-start">
                    <p className="font-medium text-gray-900">
                        {orderDetail?.center.centerName}
                    </p>
                    <p className="text-xs text-gray-600">
                        {orderDetail?.center.address}
                        ,&nbsp;
                        {orderDetail?.center.pincode}
                    </p>
                    <div>{renderPackageReschedule()}</div>
                </div>
            </div>

            <div>{isChangeCenterAvailable() && renderCenterChangeButton()}</div>

            <AnimatePresence initial={false} mode="wait">
                {changeCenter.modal && (
                    <ModalComponent
                        handleClose={changeCenter.closeModal}
                        width={'800px'}
                        min_height={'100%'}
                    >
                        <ChangeCenterModal
                            handleClose={changeCenter.closeModal}
                            orderDetails={orderDetail}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CenterDetailsCard;
