import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import axiosInstance from '../../services/axiosInstance';
import { getAPIEndPoints } from '../../router/utils/api';

type useGetAllStudyWithPaginationType = {
    offset: number;
    limit: number;
    isEnabled?: boolean;
    studySearch?: string;
    modalityIds?: string[];
    studyIds?: string[];
    orderBy?: string;
    sortBy?: string;
};

type ModalityObjType = {
    id: '8c997ebb-3e1a-4571-a735-1e5c379e6345';
    modalityName: 'Audiometry';
    createdAt: '2023-04-04T13:39:18.906Z';
    updatedAt: '2023-04-04T13:39:18.906Z';
};

type StudyPriceObjType = {
    id: string;
    studyId: string;
    centerId: string;
    price: number;
    centerPrice: number;
    contrastPrice: number;
    basePrice: number;
    createdAt: string;
    updatedAt: string;
    providerPrice: number;
    isEnabled: true;
    center: {
        id: string;
        centerName: string;
        location: string;
        city: string;
        isProviderPriceType: boolean;
    };
};

export type ProviderStudyPriceObjType = {
    id: string;
    studyId: string;
    study: StudyObjType;
    basePrice: number;
    contrastPrice: number;
    createdAt: string;
    updatedAt: string;
    isEnabled: true;
};

export interface StudyObjType {
    id: string;
    studyName: string;
    modalityId: string;
    createdAt: string;
    updatedAt: string;
    centerMachineId: string;
    isEnabled: boolean;
    testPreparation: string;
    reportingTAT: number;
    bladderFullRequired: boolean;
    tags: string[];
    studyPrice: StudyPriceObjType[];
    providerStudyPrice?: ProviderStudyPriceObjType[];
    modality: ModalityObjType;
    _count: {
        studyPrice: number;
    };
}

export interface RawStudyObjType {
    id: string;
    studyName: string;
    modalityId: string;
    createdAt: string;
    updatedAt: string;
    centerMachineId: string;
    isEnabled: boolean;
    testPreparation: string;
    reportingTAT: number;
    bladderFullRequired: boolean;
    tags: string[];
}

interface StudyParamsType {
    studyId?: string;
    studyName: string;
    modalityId: string;
    tagsArray: string[];
    testPreparation?: string;
    reportingTAT?: number;
    bladderFullRequired?: boolean;
}

interface UpdateStudy {
    studyIds?: string[];
    isEnabled: boolean;
    studyPriceIds?: string[];
}
interface UpdateFullBladderRequiredStudy {
    studyIds?: string[];
    bladderFullRequired: boolean;
    studyPriceIds?: string[];
}

//Get All study with pagination
const getAllStudyWithPagination = async ({
    limit,
    offset,
    isEnabled,
    studySearch,
    modalityIds,
    orderBy,
    sortBy,
}: Partial<useGetAllStudyWithPaginationType>) => {
    const res = await axiosInstance.get(getAPIEndPoints.common.getStudyList(), {
        params: {
            ...(limit && { limit }),
            ...(offset && { offset }),
            isEnabled,
            ...(studySearch && { search: studySearch }),
            ...(modalityIds?.length && { modality_ids: modalityIds }),
            ...(orderBy && { order_by: orderBy }),
            ...(sortBy && { sort_by: sortBy }),
        },
    });
    return res.data.data as {
        count: number;
        study_list: StudyObjType[];
    };
};

//Get All study with study ids
const getStudyListById = async ({
    studyIds,
    orderBy,
    sortBy,
}: Partial<useGetAllStudyWithPaginationType>) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.common.getBulkStudyList(),
        {
            data: {
                ...(orderBy && { order_by: orderBy }),
                ...(sortBy && { sort_by: sortBy }),
                ...(studyIds?.length && { study_ids: studyIds }),
            },
        }
    );
    return res.data.data as {
        count: number;
        study_list: StudyObjType[];
    };
};

const getStudyList = async (onlyEnabled?: boolean) => {
    const res = await axiosInstance.get(`/study`, {
        params: {
            onlyEnabled,
        },
    });
    return res.data.data;
};

// Upload bulk study
const uploadBulkStudy = async (data: any) => {
    const res = await axiosInstance.post(
        getAPIEndPoints.admin.uploadBulkStudy(),
        data
    );
    return res.data.data;
};

// Get provide study price
const getProviderStudyPrice = async () => {
    const res = await axiosInstance.get(
        getAPIEndPoints.admin.getProviderStudyPrice()
    );
    return res.data.data;
};

// Upload bulk provide study price
const uploadProviderStudyPrice = async (data: any) => {
    const res = await axiosInstance.patch(
        getAPIEndPoints.admin.uploadProviderStudyPrice(),
        data
    );
    return res.data.data;
};

const getRawStudyList = async ({
    isEnabled,
    studySearch,
    modalityIds,
}: Partial<useGetAllStudyWithPaginationType>) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.common.getRawStudyList(),
        {
            params: {
                isEnabled,
                ...(studySearch && { searchString: studySearch }),
                ...(modalityIds?.length && { modality_ids: modalityIds }),
            },
        }
    );
    return res.data.data as RawStudyObjType[];
};

export const useGetAllStudyWithPaginationQuery = (
    params: useGetAllStudyWithPaginationType
) => {
    return useQuery(
        ['studyList', params],
        () => getAllStudyWithPagination(params),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                if (err.response?.status === 401) {
                    toast.error('Session expired. Please refresh page');
                } else {
                    toast.error('Error fetching orders');
                    console.log(err);
                }
            },
        }
    );
};

export const useGetAllStudyWithPaginationMutation = () => {
    return useMutation(getAllStudyWithPagination, {
        onError: (err: any) => {
            if (err.response.status === 401) {
                toast.error('Something went wrong');
            } else {
                toast.error('Error fetching study List');
                console.log(err);
            }
        },
    });
};

export const useGetStudyListById = () => {
    return useMutation(getStudyListById, {
        onError: (err: any) => {
            if (err.response.status === 401) {
                toast.error('Something went wrong');
            } else {
                toast.error('Error fetching study List');
                console.log(err);
            }
        },
    });
};

export const useGetAllStudy = (onlyEnabled?: boolean) => {
    return useQuery(['allStudyList'], () => getStudyList(onlyEnabled), {
        onError: (error) => {
            toast.error('Error in fetching study list');
        },
    });
};

export const useGetRawStudyList = (
    params: Partial<useGetAllStudyWithPaginationType>
) => {
    return useQuery(['rawStudyList', params], () => getRawStudyList(params), {
        onError: (error) => {
            toast.error('Error in fetching study list');
        },
    });
};

const addStudy = async (data: StudyParamsType) => {
    const res = await axiosInstance.post(`/study`, data);
    return res.data.data;
};

export const useAddStudy = () => {
    const queryClient = useQueryClient();

    return useMutation(addStudy, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allStudyList']);
        },
        onError: (error: any) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.error('Error in adding study');
            });
        },
    });
};

const addStudyCorrelation = async (data: any) => {
    const res = await axiosInstance.post(`/study/correlation`, data);
    return res.data.data;
};

export const useAddStudyCorrelation = () => {
    const queryClient = useQueryClient();

    return useMutation(addStudyCorrelation, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.success('Study correlation added successfully');
            });
        },
        onError: (error: any) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.error(
                    "There's a mismatch in the ID. Please check the file and try again."
                );
            });
        },
    });
};

const updateStudy = async (data: StudyParamsType) => {
    const res = await axiosInstance.patch(`/study`, data);
    return res.data.data;
};

export const useUpdateStudy = () => {
    const queryClient = useQueryClient();

    return useMutation(updateStudy, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.success('Study updated successfully');
            });
        },
        onError: (error: any) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.error('Error in updating study');
            });
        },
    });
};

const updateEnableStudy = async (props: UpdateStudy) => {
    const res = await axiosInstance.patch(`/study/update`, {
        studyIds: props.studyIds,
        isEnabled: props.isEnabled,
    });
    return res.data.data;
};

const updateFullBladderRequired = async (
    props: UpdateFullBladderRequiredStudy
) => {
    const res = await axiosInstance.patch(`/study-bladder/update`, {
        studyIds: props.studyIds,
        bladderFullRequired: props.bladderFullRequired,
    });
    return res.data.data;
};

export const useUpdateEnableStudy = () => {
    const queryClient = useQueryClient();

    return useMutation(updateEnableStudy, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['studyList', 'rawStudyList']);
        },
        onError: (error: any) => {
            queryClient
                .invalidateQueries(['studyList', 'rawStudyList'])
                .then(() => {
                    toast.error('Error in updating study');
                });
        },
    });
};

export const useUpdateFullBladderRequiredStudy = () => {
    const queryClient = useQueryClient();

    return useMutation(updateFullBladderRequired, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['studyList', 'rawStudyList']);
        },
        onError: (error: any) => {
            queryClient
                .invalidateQueries(['studyList', 'rawStudyList'])
                .then(() => {
                    toast.error('Error in updating study');
                });
        },
    });
};

const updateEnableStudyPerCenter = async (props: UpdateStudy) => {
    const res = await axiosInstance.patch(`/study/center/update`, {
        studyPriceIds: props.studyPriceIds,
        isEnabled: props.isEnabled,
    });
    return res.data.data;
};

export const useUpdateEnableStudyPerCenter = () => {
    const queryClient = useQueryClient();

    return useMutation(updateEnableStudyPerCenter, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.success('Study updated successfully');
            });
        },
        onError: (error: any) => {
            queryClient.invalidateQueries(['allStudyList']).then(() => {
                toast.error('Error in updating study');
            });
        },
    });
};

export const useUploadBulkStudy = () => {
    const queryClient = useQueryClient();

    return useMutation(uploadBulkStudy, {
        onSuccess: (data) => {
            queryClient
                .invalidateQueries(['allStudyList', 'rawStudyList'])
                .then(() => {
                    toast.success('Study uploaded successfully');
                });
        },
        onError: (error: any) => {
            queryClient
                .invalidateQueries(['allStudyList', 'rawStudyList'])
                .then(() => {
                    toast.error(error?.response?.data?.data);
                });
        },
    });
};

export const useGetProviderStudyPrice = () => {
    return useMutation(getProviderStudyPrice, {
        onError: (error: any) => {
            toast.error(error?.response?.data?.data);
        },
    });
};

export const useUploadProviderStudyPrice = () => {
    const queryClient = useQueryClient();

    return useMutation(uploadProviderStudyPrice, {
        onSuccess: (data) => {
            toast.success('5C Study Price uploaded successfully');
        },
        onError: (error: any) => {
            queryClient
                .invalidateQueries(['providerStudyPriceList'])
                .then(() => {
                    toast.error(error?.response?.data?.data);
                });
        },
    });
};

const getAllStudyCorrelation = async (customerId: string) => {
    const res = await axiosInstance.get(`/study/correlation/${customerId}`);
    return res.data.data;
};

export const useGetAllStudyCorrelation = (selectedCustomerId: string) => {
    return useQuery(
        ['allStudyCorrelationList'],
        () => getAllStudyCorrelation(selectedCustomerId),
        {
            onError: (error) => {
                toast.error('Error in fetching study correlation list');
            },
        }
    );
};
