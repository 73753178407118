import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../services/axiosInstance';
import { getAPIEndPoints } from '../../router/utils/api';

const getAvailableTimeSlots = async (day, centerId) => {
    const res = await axiosInstance.post(`/slots`, {
        dayId: day,
        centerId: centerId,
    });
    return res.data;
};

const getScheduledTimeSlots = async (data) => {
    const res = await axiosInstance.get(
        getAPIEndPoints.booking.getScheduledTimeSlots(),
        {
            params: data,
        }
    );
    return res.data.data || [];
};

const addPatientDetails = async (data) => {
    const res = await axiosInstance.post('/patient', data);
    return res.data.data;
};

const updatePatient = async (data) => {
    return axiosInstance
        .post('/patient/update', data)
        .then((res) => res.data.data);
};

export function useFetchTimeSlot(day, centerId) {
    const navigate = useNavigate();
    return useQuery(
        ['slot', day, centerId],
        () => getAvailableTimeSlots(day, centerId),
        {
            enabled: day === 0 || (!!day && !!centerId),
            onError: (err) => {
                if (err?.response?.status === 401) {
                    navigate('/', { replace: true });
                } else {
                    toast.error('Error fetching time slot');
                    console.log(err);
                }
            },
        }
    );
}

export function useGetBookedSlotsByCenter(data) {
    return useQuery(
        ['scheduledOrderStudySlots'],
        () => getScheduledTimeSlots(data),
        {
            cacheTime: 0,
            enabled:
                !!data.centerMachineIds &&
                !!data.centerMachineIds?.length &&
                !!data.selectedDate &&
                !!data.centerId &&
                !!data.isSchedulerEnabled,
            onError: (err) => {
                toast.error('Error fetching time slot');
                console.log(err);
            },
        }
    );
}

export function useAddPatient() {
    const navigate = useNavigate();

    return useMutation(addPatientDetails, {
        onError: (err) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error adding patient');
                console.log(err);
            }
        },
    });
}

export function useUpdatePatient() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(updatePatient, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Patient updated successfully');
            });
        },
        onError: (err) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error updating patient');
                console.log(err);
            }
        },
    });
}

// Upload rework
const uploadReport = async ({ bookingId, formData }) => {
    const res = await axiosInstance.post(
        `/upload-report/${bookingId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return res.data;
};

export const useUploadRework = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(uploadReport, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Uploaded successfully');
            });
        },
        onError: (err) => {
            if (err?.response?.status === 401) {
                navigate('/', { replace: true });
            } else {
                toast.error('Error uploading rework');
                console.log(err);
            }
        },
    });
};

const updateImageLink = async ({ bookingId, imageLink }) => {
    const res = await axiosInstance.post(`/update-image-link/${bookingId}`, {
        imageLink: imageLink,
    });
    return res.data.data;
};

export const useUpdateImageLink = () => {
    const queryClient = useQueryClient();

    return useMutation(updateImageLink, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Image link updated successfully');
            });
        },
        onError: (err) => {
            toast.error('Error updating image link');
        },
    });
};

const getStatusForBooking = async () => {
    const res = await axiosInstance.get('/status-for-booking');
    return res.data.data;
};

export const useStatusForBookings = () => {
    return useQuery(['status'], getStatusForBooking, {
        onError: (err) => {
            toast.error('Error fetching status');
        },
    });
};

const updateBookingStatus = async (data) => {
    const res = await axiosInstance.patch(
        `/booking/status/update/${data.bookingId}`,
        data
    );
    return res.data.data;
};

export const useUpdateBookingStatus = () => {
    const queryClient = useQueryClient();

    return useMutation(updateBookingStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Status updated successfully');
            });
        },
        onError: (err) => {
            toast.error('Error updating status');
        },
    });
};

const updateStatusForBooking = async (data) => {
    const res = await axiosInstance.patch(
        `/confirm/booking/${data.orderId}`,
        data
    );
    return res.data.data;
};

export const useUpdateStatusForBooking = () => {
    const queryClient = useQueryClient();

    return useMutation(updateStatusForBooking, {
        onSuccess: () => {
            queryClient.invalidateQueries().then(() => {
                toast.success('Status updated successfully');
            });
        },
        onError: (err) => {
            toast.error('Error updating status');
        },
    });
};

// patient flow

const getScheduledTimeSlotsPublic = async (data) => {
    const res = await axiosInstance.get(`/public/scheduled/slots`, {
        params: data,
        headers: {
            user_id: data.userId,
        },
    });
    return res.data.data || [];
};

export function useGetBookedSlotsByCenterPublic(data) {
    return useQuery(
        ['scheduledOrderStudySlots'],
        () => getScheduledTimeSlotsPublic(data),
        {
            cacheTime: 0,
            enabled:
                !!data.centerMachineIds &&
                !!data.centerMachineIds?.length &&
                !!data.selectedDate &&
                !!data.centerId &&
                !!data.isSchedulerEnabled,
            onError: (err) => {
                toast.error('Error fetching time slot');
                console.log(err);
            },
        }
    );
}
