import { RadioGroup } from '@headlessui/react';
import React from 'react';
import styled from 'styled-components';

enum TimingStatusEnum {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE',
}

type TimingsType = {
    start_time: string | number;
    end_time: string | number;
    status: TimingStatusEnum;
};

type CenterScheduleType = {
    date_time: string;
    timings: TimingsType[];
};

export interface SlotsPropsType {
    timeSlotData: TimingsType[] | undefined;
    selectedDate: Date;
    isLoading: boolean;
    selectedSlot: string;
    handleOnSlotSelect: (value: string) => void;
}

const TimeSlotV2 = (props: SlotsPropsType) => {
    const { timeSlotData, isLoading, handleOnSlotSelect, selectedSlot } = props;
    return (
        <ParentContainer
            className={`${
                isLoading && 'animate-pulse'
            } w-full max-h-36 overflow-y-auto border border-gray-300 bg-white shadow-inner rounded-md`}
        >
            <div className="block text-sm text-gray-600  bg-gray-100 px-2 py-1 sticky top-0">
                Available slots
            </div>
            <div className="p-2">
                {timeSlotData?.length ? (
                    <RadioGroup
                        value={selectedSlot}
                        onChange={(val: any) => handleOnSlotSelect(val)}
                    >
                        <div className="flex flex-wrap justify-start">
                            {timeSlotData.map((timeSlotObj) => {
                                return (
                                    <RadioGroup.Option
                                        disabled={
                                            timeSlotObj?.status ===
                                            TimingStatusEnum.UNAVAILABLE
                                        }
                                        key={timeSlotObj?.start_time}
                                        value={timeSlotObj?.start_time}
                                        className={({ checked, disabled }) =>
                                            `${
                                                checked
                                                    ? 'bg-primary-500'
                                                    : `border border-gray-200 ${
                                                          disabled
                                                              ? 'opacity-60 cursor-not-allowed bg-red-100'
                                                              : 'opacity-100 cursor-pointer'
                                                      }`
                                            } static flex justify-center cursor-pointer rounded py-1 m-1 focus:outline-none transition-all duration-200`
                                        }
                                    >
                                        {({ checked, disabled }) => (
                                            <div className="text-sm px-2.5 py-0.5">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-semibold ${
                                                        disabled &&
                                                        'text-red-600'
                                                    } ${
                                                        checked
                                                            ? 'text-white'
                                                            : 'text-gray-900'
                                                    }`}
                                                >
                                                    {timeSlotObj?.start_time}
                                                </RadioGroup.Label>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                );
                            })}
                        </div>
                    </RadioGroup>
                ) : (
                    <p className="py-2 text-xs text-gray-500 text-center">
                        {isLoading
                            ? 'Fetching Slots'
                            : 'No slots available for this date!'}
                    </p>
                )}
            </div>
        </ParentContainer>
    );
};

export default TimeSlotV2;

const ParentContainer = styled.div`
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0px;
    }
`;
