import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../services/axiosInstance';
import { toast } from 'react-toastify';
import { CouponDetailsStateType } from '../aggregator/coupons/AddOrEditCoupons';

type OnReceivePayloadType = {
    id: string;
    couponName: string;
    couponDiscount: number;
    isEnabled: true;
    customerId: string;
};

type RequestUpdateCouponPayloadType = {
    couponData: CouponDetailsStateType;
    couponId: string;
};

type RequestGetCouponByCouponCodeType = {
    customerId: string;
    couponCode: string;
    userId: string;
};

const getCouponsByCustomerId = async (customerId: string) => {
    return await axiosInstance
        .get(`/coupon/${customerId}`)
        .then((res) => res.data.data);
};

const createCoupon = async (couponData: CouponDetailsStateType) => {
    return await axiosInstance
        .post('/coupon', couponData)
        .then((res) => res.data.data);
};

const updateCoupon = async ({
    couponData,
    couponId,
}: RequestUpdateCouponPayloadType) => {
    return await axiosInstance
        .put(`/coupon/update/${couponId}`, couponData)
        .then((res) => res.data.data);
};

const getCouponByCouponCode = async ({
    customerId,
    couponCode,
    userId,
}: RequestGetCouponByCouponCodeType) => {
    return axiosInstance
        .get(`/public/coupon/${customerId}`, {
            params: {
                user_id: userId,
                coupon_code: couponCode,
            },
        })
        .then((res) => res.data.data);
};

export const useGetCouponByCouponCode = () => {
    return useMutation(getCouponByCouponCode, {
        onError: (error: any) => {
            console.log({ error });
            toast.error('something went wrong');
        },
    });
};
export const useGetCouponsByCustomerId = (customerId: string) => {
    return useQuery(['allCoupons'], () => getCouponsByCustomerId(customerId), {
        onError: (error: any) => {
            console.log({ error });
            toast.error("Couldn't Fetch Coupons");
        },
    });
};

export const useCreateCoupon = () => {
    const clientQuery = useQueryClient();
    return useMutation(createCoupon, {
        onSuccess: (data: OnReceivePayloadType) => {
            clientQuery.invalidateQueries(['allCoupons']).then(() => {
                toast.success('Coupon Created successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 409) {
                toast.error(error.response.data.message);
            } else {
                toast.error(`couldn't update coupon`);
            }
        },
    });
};

export const useUpdateCoupon = () => {
    const clientQuery = useQueryClient();
    return useMutation(updateCoupon, {
        onSuccess: (data: OnReceivePayloadType) => {
            clientQuery.invalidateQueries(['allCoupons']).then(() => {
                toast.success('Coupon updated successfully');
            });
        },
        onError: (error: any) => {
            console.log(error);
            if (error.response.status === 409) {
                toast.error(error.response.data.message);
            } else {
                toast.error(`couldn't update coupon`);
            }
        },
    });
};
