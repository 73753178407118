export const getAPIEndPoints = Object.freeze({
    common: {
        getOrderList: () => `/order/list`,
        getStudyList: () => `/study/list`,
        getBulkStudyList: () => `/study/bulk/list`,
        getRawStudyList: () => `/raw/study`,
    },
    booking: {
        getScheduledTimeSlots: () => `/scheduled/slots`,
    },
    aggregator: {
        getDraftOrder: () => '/order/draft/list',
        createDraftOrder: () => '/order/draft',
        updateDraftOrder: (draftId: string) => `/order/draft/${draftId}`,
        deleteDraftOrder: (draftId: string) => `/order/draft/${draftId}`,
    },
    center: {
        searchCenterByStudy: () => '/search/center/study',
        searchCenterByPackage: () => '/search/center/package',
        getCenterMachines: (centerId: string) => `/center/machine/${centerId}`,
        getCenterMachinesWithOrder: (centerId: string) =>
            `/center/machine-order/${centerId}`,
        getCenterTimeSlotByModality: (centerId: string) =>
            `/center/timeslot/${centerId}`,
        getCenterTimeSlotByModalityV2: (centerId: string) =>
            `/v2/center/timeslot/${centerId}`,
        createMachineWithDefaultTimeSlot: () => '/center/machine',
        updateMachineWithDefaultTimeSlot: () => '/center/machine',
        updateCenterSchedulerStatus: (centerId: string) =>
            `/center/scheduler/${centerId}`,
        createCenterOrder: () => '/center/order',
        updateCenterOrder: () => '/center/order',
    },
    admin: {
        getOrderStatusCount: () => `/order/status/count`,
        getOrderCustomerCount: () => `/order/customer/count`,
        getOrderCenterCount: () => `/order/center/count`,
        modalityDiscount: () => '/modality/discount',
        uploadBulkStudy: () => '/study/bulk',
        getProviderStudyPrice: () => '/5c/study/price',
        uploadProviderStudyPrice: () => '/5c/study/price',
        createOrUpdateModalityDiscount: (customerId: string) =>
            `/modality/discount/${customerId}`,
        createOrUpdateModalityDiscountByCenter: () =>
            `/modality/discount/center`,
    },
});
