import React, { Dispatch, SetStateAction } from 'react';
import { useGeocoding } from '../hooks/useOrder';
import { CgSpinner } from 'react-icons/cg';

export type PincodeObjType = {
    value: string | null;
    lat: string | null;
    long: string | null;
    error: boolean;
};

type PincodeSearchInputBoxProps = {
    disabled?: boolean;
    pincodeState: PincodeObjType;
    setPincodeState: Dispatch<SetStateAction<PincodeObjType>>;
};

const PincodeSearchInputBox = ({
    disabled,
    pincodeState,
    setPincodeState,
}: PincodeSearchInputBoxProps) => {
    const {
        isLoading: pincodeDetailIsLoading,
        mutateAsync: mutatePincodeDetails,
    } = useGeocoding();

    const handlePincodeChange = async (pincodeVal: string) => {
        try {
            if (pincodeVal.length === 6) {
                if (isNaN(Number(pincodeVal))) {
                    setPincodeState({
                        ...pincodeState,
                        error: true,
                    });
                    return;
                }

                mutatePincodeDetails(pincodeVal).then((data) => {
                    const addressData = data.features[0];
                    if (addressData?.geometry?.coordinates?.length) {
                        const { coordinates } = addressData.geometry;
                        setPincodeState({
                            value: pincodeVal,
                            lat: coordinates[1],
                            long: coordinates[0],
                            error: false,
                        });
                        return;
                    }
                });
            }
            setPincodeState({
                value: pincodeVal,
                lat: '',
                long: '',
                error: true,
            });
        } catch (error) {
            console.log(error);
            setPincodeState({
                value: pincodeVal,
                lat: '',
                long: '',
                error: true,
            });
        }
    };

    return (
        <div
            className={`${
                pincodeState.error ? 'border-red-300' : 'border-gray-300'
            } flex flex-row px-2 w-full justify-center items-center border rounded outline-none bg-white overflow-hidden`}
        >
            <input
                disabled={disabled}
                maxLength={6}
                value={pincodeState.value! || ''}
                placeholder="Eg: 560102"
                className={`w-full py-1.5 outline-none bg-white disabled:opacity-50`}
                onChange={(e) => handlePincodeChange(e.target.value)}
            />
            {pincodeDetailIsLoading && (
                <CgSpinner size={18} className="opacity-50 animate-spin" />
            )}
        </div>
    );
};

export default PincodeSearchInputBox;
