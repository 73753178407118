import {
    OrderLevelStatus,
    StudyLevelStatus,
    returnISTTime,
} from '../../utils/index';
import { STATUS_COLORS } from '../../../constants/statusColors';
import { allStatusType } from './TrackOrder';

interface StatusNodePropsType {
    allStatus: allStatusType[];
}
const StatusNode = (props: StatusNodePropsType) => {
    if (props.allStatus?.length !== 0) {
        return (
            <div className="mx-8 sm:mx-20 my-2">
                {props.allStatus?.map((status: any, index: number) => {
                    return (
                        <div
                            className="box-border flex flex-row items-start font-medium relative"
                            key={`i_${index}`}
                        >
                            <div className="h-[100%] ">
                                <div className="z-10 bg-white py-[2px]">
                                    <div
                                        className={
                                            'p-4 flex h-2 w-2 rounded-full items-center justify-center drop-shadow-lg border'
                                        }
                                        style={{
                                            background:
                                                //@ts-ignore
                                                STATUS_COLORS[
                                                    status.statusName
                                                ] + 20,
                                            //@ts-ignore
                                            color: STATUS_COLORS[
                                                status.statusName
                                            ],
                                        }}
                                    >
                                        <p className="text-xs">
                                            {props.allStatus.length - index}
                                        </p>
                                    </div>
                                </div>
                                {props.allStatus.length == 1 ||
                                index == props.allStatus.length - 1 ? null : (
                                    <div className="border-l-2 h-[100%] border-gray-300 absolute left-4  drop-shadow-md"></div>
                                )}
                            </div>

                            <div className="m-[10px] w-full">
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-row gap-8 items-center justify-between w-full">
                                        <p
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                //@ts-ignore
                                                color: STATUS_COLORS[
                                                    status.statusName
                                                ],
                                            }}
                                        >
                                            {status.statusName
                                                .split('_')
                                                .join(' ')}
                                        </p>
                                        <p
                                            className="text-sm text-black-500 px-[10px] bg-gray-100 rounded-md"
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '12px',

                                                backgroundColor:
                                                    //@ts-ignore
                                                    STATUS_COLORS[
                                                        status.statusName
                                                    ] + 20,
                                                //@ts-ignore
                                                color: STATUS_COLORS[
                                                    status.statusName
                                                ],
                                            }}
                                        >
                                            {status.statusLevel.toUpperCase()}
                                        </p>
                                    </div>

                                    {status.statusName ===
                                        StudyLevelStatus.CANCELLED ||
                                    status.statusName ===
                                        OrderLevelStatus.ORDER_CANCELLED ? (
                                        <p className="text-xs text-gray-500 font-medium">
                                            Reason:{' '}
                                            {status.bookingId ? (
                                                status.orderStudy
                                                    .cancelReason ? (
                                                    status.orderStudy
                                                        .cancelReason
                                                ) : (
                                                    <span className="italic text-gray-400">
                                                        -None-
                                                    </span>
                                                )
                                            ) : status.order.cancelReason ? (
                                                status.order.cancelReason
                                            ) : (
                                                <span className="italic text-gray-400">
                                                    -None-
                                                </span>
                                            )}
                                        </p>
                                    ) : null}
                                    {status.bookingId !== null ? (
                                        <p className="text-xs text-gray-500">
                                            Study:{' '}
                                            {status.orderStudy.study.studyName}
                                        </p>
                                    ) : null}
                                    <p className="text-xs text-gray-400 italic">
                                        Updated By: {status.updatedBy}
                                    </p>

                                    <p className="text-xs text-gray-400 italic">
                                        Updated at:{' '}
                                        {returnISTTime(
                                            status.createdAt,
                                            'dd MMM, hh:mm a'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return (
            <div className="text-lg text-gray-500 font-medium w-48 m-auto py-16">
                No Status Available
            </div>
        );
    }
};

export default StatusNode;
