import * as React from 'react';
import ModalHeader from '../ModalTemplate/components/ModalHeader';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';

interface Props {
    selectedFile: string[];
    handleClose: () => void;
    onClick: (
        selectedFile: File[],
        uploadingState: (
            value: ((prevState: boolean) => boolean) | boolean
        ) => void
    ) => void;
    selectedFileToUpload: File[];
    setSelectedFile: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedFileToUpload: React.Dispatch<React.SetStateAction<File[]>>;
}

const PreviewDocuments: React.FC<Props> = (Props) => {
    const {
        selectedFile,
        handleClose,
        onClick,
        selectedFileToUpload,
        setSelectedFile,
        setSelectedFileToUpload,
    } = Props;

    if (selectedFile?.length <= 0) {
        handleClose();
    }
    const [previewFileIndex, setPreviewFileIndex] = React.useState<number>(0);
    let type = selectedFileToUpload[previewFileIndex]?.name.split('.');
    let fileExtension = type?.[type.length - 1];

    const [uploading, setUploading] = React.useState(false);

    const removeFile = () => {
        let cloneSelectedFile = [...selectedFile];
        let cloneSelectedFileToUpload = [...selectedFileToUpload];
        cloneSelectedFile.splice(previewFileIndex, 1);
        cloneSelectedFileToUpload.splice(previewFileIndex, 1);
        setSelectedFile([...cloneSelectedFile]);
        setSelectedFileToUpload([...cloneSelectedFileToUpload]);
        setPreviewFileIndex(0);
    };
    return (
        <div className={'m-1'}>
            <ModalHeader title={'File Preview'} handleClose={handleClose} />
            {selectedFile?.length > 0 && (
                <div className=" flex flex-col sm:flex-row justify-between items-end sm:items-center mx-6">
                    <div className="flex mt-[10px] gap-5">
                        <button
                            disabled={previewFileIndex === 0}
                            className="disabled:opacity-30"
                            onClick={() => {
                                setPreviewFileIndex((prev: any) => prev - 1);
                            }}
                        >
                            <BsArrowLeftCircle className=" h-[25px] w-[25px] " />
                        </button>
                        <p>{`Document ${previewFileIndex + 1} of ${
                            selectedFile.length
                        }`}</p>
                        <button
                            disabled={
                                previewFileIndex === selectedFile.length - 1
                            }
                            className="disabled:opacity-30"
                            onClick={() => {
                                setPreviewFileIndex((prev: any) => prev + 1);
                            }}
                        >
                            <BsArrowRightCircle className=" h-[25px] w-[25px]" />
                        </button>
                    </div>
                    <div className="flex mt-[10px] items-center justify-center">
                        <button
                            className=" mr-5 bg-red-500 text-white font-medium p-2 rounded-md"
                            onClick={removeFile}
                        >
                            <AiOutlineDelete />
                        </button>
                        <button
                            disabled={uploading}
                            onClick={() => {
                                setUploading(true);
                                onClick(selectedFileToUpload, setUploading);
                            }}
                            className="bg-blue-500 text-sm hover:bg-blue-700 text-white py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed "
                        >
                            {uploading ? 'Uploading...' : 'Upload file'}
                        </button>
                    </div>
                </div>
            )}

            <div
                className="mt4"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '20px',
                }}
            >
                {selectedFile?.length > 0 ? (
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            width: '95%',
                            height: '475px',
                        }}
                    >
                        {fileExtension === 'jpg' ||
                        fileExtension === 'jpeg' ||
                        fileExtension === 'png' ||
                        fileExtension === 'svg' ? (
                            <img
                                src={selectedFile[previewFileIndex]}
                                style={{
                                    width: '100%',
                                }}
                            />
                        ) : (
                            <iframe
                                src={selectedFile[previewFileIndex]}
                                frameBorder="0"
                                width="100%"
                                height="100%"
                            ></iframe>
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            alignItems: 'center',
                            border: '2px dashed rgba(0, 0, 0, .3)',
                            display: 'flex',
                            fontSize: '20px',
                            height: '500px',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        Preview area
                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviewDocuments;
