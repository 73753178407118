import React from 'react';
import { CgSpinner } from 'react-icons/cg';

type LoaderProps = {
    isLoading: boolean;
    children?: JSX.Element;
};

const Loader = ({ isLoading, children }: LoaderProps) => {
    if (isLoading) {
        return (
            <div className="w-full flex flex-row justify-center items-center gap-2 p-4">
                <CgSpinner
                    className="animate-spin text-primary-600"
                    size={24}
                />
                <p className="text-xs text-gray-400">Please Wait</p>
            </div>
        );
    }

    return <>{children}</>;
};

export default Loader;
