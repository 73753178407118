import React, { useState } from 'react';
import { STATUS_COLORS } from '../../../constants/statusColors';
import { getLink } from './InvoiceCard';
import axiosInstance from '../../../services/axiosInstance';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { MdOutlineContentCopy } from 'react-icons/md';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { useUploadRework } from '../../hooks/useBooking';
import format from 'date-fns/format';
import { copyToClipboard } from '../../utils/CopyToClipboard';
import { FaRegCalendarAlt } from 'react-icons/fa';
import {
    StudyLevelStatus,
    returnISTTime,
    OrderLevelStatus,
    OrderType,
} from '../../utils';
import CancelReasonModal from './CancelReasonModal';
import ModalComponent from '../../ModalTemplate/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import TrackOrder from '../../order/TrackOrder/TrackOrder';
import { useCreateModalState } from '../../hooks/useModalHooks';
import {
    CaslActions,
    CaslSubjects,
    CaslUserRoles,
    ContextualCan,
} from '../../../casl/ability';
import { userObject } from '../../../redux/selectors';
import { getRoutesEndPoints } from '../../../router/utils/routes';

const StudiesViewCard = ({
    orderDetail,
    withReport = false,
    handleReschedule,
    isPackageOrder = false,
    isCombinedSlots = false,
}) => {
    const queryClient = useQueryClient();

    const location = useLocation();

    const uploadReworkReport = useUploadRework();

    const [selectedFile, setSelectedFile] = useState(null);

    const trackOrderModal = useCreateModalState();

    const cancelStudyModal = useCreateModalState();

    const [disableOnLoading, setDisableOnLoading] = useState(false);

    const [studyDetail, setstudyDetail] = useState({});

    const ACCOUNT_TYPE = userObject().accountType;

    const isPackage = orderDetail?.type === OrderType.GROUP;

    const handleFileChange = async (e, bookingId) => {
        setSelectedFile('');
        const reader = new FileReader();
        reader.onloadend = () => {
            // @ts-ignore
            console.log('reader');
        };

        // @ts-ignore
        reader.readAsDataURL(e.target.files[0]);
        // @ts-ignore
        setSelectedFile(e.target.files[0]);

        await uploadReworkToServer(e.target.files[0], bookingId);
    };

    const uploadReworkToServer = async (file, bookingId) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'rework');
        formData.append('bookingId', bookingId);
        isPackage && formData.append('packageId', orderDetail.id);

        await uploadReworkReport.mutateAsync({
            formData: formData,
            bookingId: bookingId,
        });
    };

    const handleCancelTest = ({ id: bookingId, reason: cancelReason }) => {
        setDisableOnLoading(true);
        if (cancelReason === '') {
            toast.error('Please add reason for cancelling the study');
            setDisableOnLoading(false);
        } else {
            const data = { reason: cancelReason };
            if (
                window.confirm(
                    'Are you sure you want to cancel this test order?'
                )
            ) {
                axiosInstance
                    .post(`/test/cancel/${bookingId}`, data)
                    .then((res) => {
                        queryClient.invalidateQueries().then(() => {
                            toast.success('Test cancelled successfully');
                        });
                        cancelStudyModal.closeModal();
                        setDisableOnLoading(false);
                    })
                    .catch((err) => {
                        toast.error('Failed to cancel test');
                        setDisableOnLoading(false);
                    });
            } else {
                setDisableOnLoading(false);
            }
        }
    };

    const ReturnStudyList = ({ orderDetail }) => {
        return (
            <div className="flex flex-row flex-wrap gap-2 py-2">
                {orderDetail?.orderStudy?.map((study, index) => (
                    <div
                        className="flex flex-col items-start justify-center rounded border border-gray-200"
                        key={`i_${index}`}
                    >
                        {orderDetail?.type !== 'group' ? (
                            <p className="p-2 pb-0 text-xs text-gray-500 rounded">
                                {study.study.studyName}:&nbsp;
                                <span className="text-sm font-semibold text-gray-900">
                                    ₹{study.study.price}
                                </span>
                            </p>
                        ) : (
                            <p className="p-2 pb-0 text-xs text-gray-500 rounded">
                                {study.study.studyName}
                            </p>
                        )}
                        <div className="px-2 py-1 flex flex-row gap-2 justify-center items-center">
                            <FaRegCalendarAlt
                                className="text-gray-500"
                                size={12}
                            />
                            <p className="text-xs font-semibold">
                                {returnISTTime(study.scheduledDateTime)}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const BtnImageAndReport = ({
        title,
        studies,
        url,
        studyName,
        modalityName,
        packageReports,
    }) => {
        const reports = isPackage ? packageReports : studies.reports;
        return url ? (
            <div className={'grid'}>
                <div
                    className={`flex items-center justify-between bg-gray-100 rounded-md p-0`}
                >
                    <button
                        className="float-right text-sm p-2 overflow-hidden rounded-md font-medium hover:bg-primary-500 hover:text-white transition-all duration-200 border shadow-sm bg-white"
                        onClick={() => {
                            window.open(url, '_blank');
                        }}
                    >
                        {title}
                        {title === 'View Report' && (
                            <p className={'text-[10px]'}>
                                {format(
                                    new Date(reports[0].createdAt),
                                    'dd-MM-yyyy HH:mm'
                                )}
                            </p>
                        )}
                    </button>

                    {title === 'Image' && (
                        <MdOutlineContentCopy
                            className={
                                'hover:text-gray-500 text-[22px] m-0.5 p-0.5 cursor-pointer'
                            }
                            onClick={() => {
                                copyToClipboard(url);
                            }}
                        />
                    )}

                    {title === 'View Report' &&
                    ACCOUNT_TYPE !== CaslUserRoles.AGGREGATOR ? (
                        <div className={'mr-2'}>
                            <input
                                id="upload-id"
                                type="file"
                                accept="application/pdf"
                                onChange={(e) =>
                                    handleFileChange(e, studies.id)
                                }
                                className={
                                    'disabled:opacity-50 disabled:cursor-not-allowed'
                                }
                                style={{
                                    display: 'none',
                                    margin: 0,
                                    padding: 0,
                                }}
                            />

                            <label
                                htmlFor="upload-id"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    height: '51px',
                                }}
                            >
                                <AiOutlineFileAdd
                                    className={
                                        'hover:text-gray-500 text-[22px] m-0.5 p-0.5 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed'
                                    }
                                />
                            </label>
                        </div>
                    ) : null}
                </div>

                {title === 'View Report' && (
                    <div>
                        {reports.map(
                            (res, index) =>
                                index !== 0 && (
                                    <div
                                        key={index}
                                        className={
                                            'flex flex-col gap-1 text-[12px] mt-2 justify-start items-start border-b-[1px]'
                                        }
                                    >
                                        <button
                                            className={
                                                'text-blue-500 hover:text-blue-600'
                                            }
                                            onClick={() =>
                                                window.open(
                                                    res.reportLink,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            View Report
                                        </button>
                                        <p className="text-xs">
                                            {format(
                                                new Date(res.createdAt),
                                                'dd-MM-yyyy HH:mm'
                                            )}
                                        </p>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </div>
        ) : (
            <a
                href={'//'}
                onClick={(e) => e.preventDefault()}
                target={'_blank'}
                rel="noreferrer"
                className={`float-right text-sm p-2 border border-gray-200 overflow-hidden rounded-md font-medium cursor-not-allowed opacity-50`}
            >
                {title}
            </a>
        );
    };

    const ReturnStudyReportList = ({ orderDetail }) => {
        const enableRescheduleStatus = [
            StudyLevelStatus.SCHEDULED,
            StudyLevelStatus.RESCHEDULED,
            OrderLevelStatus.PAYMENT_RECEIVED,
        ];

        const disableCancelButton = [
            StudyLevelStatus.CANCELLED,
            StudyLevelStatus.COMPLETED,
            StudyLevelStatus.REPORTED,
            StudyLevelStatus.CHECKED_OUT,
        ];

        return (
            <div className="flex flex-col justify-center items-start rounded-md border border-gray-200 divide-gray-200 my-2">
                {orderDetail?.orderStudy?.map((study, index) => (
                    <div
                        key={`i_${index}`}
                        className="w-full flex flex-col md:flex-row md:items-center justify-between gap-2 p-2"
                    >
                        <div className="flex flex-col">
                            <div className="flex flex-row items-baseline justify-between  md:flex-col md:items-start">
                                {orderDetail?.type !== 'group' ? (
                                    <p className="p-2 pb-0 text-xs text-gray-500 rounded">
                                        {study.study.studyName}:&nbsp;
                                        <span className="text-sm font-semibold text-gray-900">
                                            ₹{study.study.price}
                                        </span>
                                    </p>
                                ) : (
                                    <p className="p-2 pb-0 text-xs text-gray-500 rounded">
                                        {study.study.studyName}
                                    </p>
                                )}

                                <div className="px-2 py-1 flex flex-row gap-2 justify-center items-center">
                                    <FaRegCalendarAlt
                                        className="text-gray-500"
                                        size={12}
                                    />
                                    <p className="text-xs font-semibold">
                                        {returnISTTime(study.scheduledDateTime)}
                                    </p>
                                    {!isCombinedSlots && (
                                        <button
                                            disabled={
                                                !enableRescheduleStatus.includes(
                                                    study?.statusName
                                                ) ||
                                                location.pathname.includes(
                                                    'finance'
                                                ) ||
                                                isPackageOrder
                                            }
                                            onClick={() =>
                                                handleReschedule(study)
                                            }
                                            className={
                                                'text-xs text-primary-600 cursor-pointer underline p-0 ml-2 disabled:cursor-not-allowed disabled:opacity-50 hover:text-primary-700'
                                            }
                                        >
                                            Reschedule
                                        </button>
                                    )}

                                    {!isPackageOrder && (
                                        <ContextualCan
                                            I={CaslActions.READ}
                                            a={
                                                CaslSubjects.ORDER_STUDY_CANCEL_OPTION
                                            }
                                        >
                                            <button
                                                disabled={
                                                    disableCancelButton.includes(
                                                        study?.statusName
                                                    ) ||
                                                    location.pathname.includes(
                                                        'finance'
                                                    )
                                                }
                                                onClick={() => {
                                                    setstudyDetail(study);
                                                    cancelStudyModal.openModal();
                                                }}
                                                className={
                                                    'border-solid border-red-600 w-[max-content] text-xs text-red-600 cursor-pointer underline p-0 disabled:cursor-not-allowed disabled:opacity-50 hover:text-red-700 hover:font-medium mr-5 md:mr-0'
                                                }
                                            >
                                                Cancel
                                            </button>
                                        </ContextualCan>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row items-start gap-2 p-2 pr-0 justify-between">
                            <div className="flex flex-row gap-2 items-start flex-grow justify-between">
                                <div>
                                    <p
                                        className={`py-2 px-2 mr-3 text-sm text-center rounded-sm `}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            backgroundColor:
                                                STATUS_COLORS[
                                                    study.statusName
                                                ] + '20',
                                            color: STATUS_COLORS[
                                                study.statusName
                                            ],
                                        }}
                                    >
                                        {study.statusName
                                            .split('_')
                                            .map((str) => {
                                                return (
                                                    str
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    str
                                                        .substring(1)
                                                        .toLowerCase()
                                                );
                                            })
                                            .join(' ')}
                                    </p>
                                </div>
                                <BtnImageAndReport
                                    title={'Image'}
                                    studies={study}
                                    date={study.createdAt}
                                    url={study.imageLink}
                                    studyName={study.study.studyName}
                                    modalityName={
                                        study.study.modality.modalityName
                                    }
                                />
                            </div>

                            {!isPackage && (
                                <BtnImageAndReport
                                    title={'View Report'}
                                    studies={study}
                                    url={
                                        study.reports.length !== 0
                                            ? study.reports[0].reportLink
                                            : null
                                    }
                                    date={study.createdAt}
                                    studyName={study.study.studyName}
                                    modalityName={
                                        study.study.modality.modalityName
                                    }
                                />
                            )}
                        </div>
                    </div>
                ))}

                <AnimatePresence initial={false} mode="wait">
                    {cancelStudyModal.modal && (
                        <ModalComponent
                            handleClose={cancelStudyModal.closeModal}
                            width={'400px'}
                            min_height={'40%'}
                        >
                            <CancelReasonModal
                                handleClose={cancelStudyModal.closeModal}
                                handleCancel={handleCancelTest}
                                studyDetails={{ ...studyDetail }}
                                disableOnLoading={disableOnLoading}
                            />
                        </ModalComponent>
                    )}
                </AnimatePresence>
            </div>
        );
    };

    return (
        <div className="flex flex-col justify-start mt-6">
            <div className="flex items-center justify-between">
                <p className="text-xs font-medium text-gray-400 uppercase">
                    Studies Selected
                </p>
                {location.pathname === getRoutesEndPoints.admin.dashboard() && (
                    <button
                        className="bg-primary-500 px-4 py-2 rounded-lg justify-end text-xs font-medium text-white"
                        onClick={() => {
                            trackOrderModal.modal
                                ? trackOrderModal.closeModal()
                                : trackOrderModal.openModal();
                        }}
                    >
                        Track Order
                    </button>
                )}
            </div>
            {withReport ? (
                <ReturnStudyReportList orderDetail={orderDetail} />
            ) : (
                <ReturnStudyList orderDetail={orderDetail} />
            )}

            {isPackage && withReport && (
                <div>
                    <p className="text-xs font-medium text-gray-400 uppercase">
                        Reports
                    </p>
                    <div className="flex flex-row justify-between items-center rounded-md border border-gray-200 p-2 divide-gray-200 my-4">
                        <p className="text-sm text-gray-500 rounded">
                            {orderDetail.packages?.[0]?.name}
                        </p>
                        <BtnImageAndReport
                            title={'View Report'}
                            studies={orderDetail.orderStudy[0]}
                            url={
                                orderDetail?.reports?.length !== 0
                                    ? orderDetail?.reports?.[0].reportLink
                                    : null
                            }
                            date={orderDetail.createdAt}
                            studyName={orderDetail?.packages?.[0]?.name}
                            packageReports={orderDetail?.reports}
                        />
                    </div>
                </div>
            )}
            <AnimatePresence initial={false} mode="wait">
                {trackOrderModal.modal && (
                    <ModalComponent
                        handleClose={trackOrderModal.closeModal}
                        width={'650px'}
                        min_height={'50%'}
                    >
                        <TrackOrder
                            handleClose={trackOrderModal.closeModal}
                            orderId={orderDetail.id}
                        />
                    </ModalComponent>
                )}
            </AnimatePresence>
        </div>
    );
};

export default StudiesViewCard;
