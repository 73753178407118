import React from 'react';

const ModalSpinner = () => {
    return (
        <div className="w-full p-12 mx-auto">
            <div className="animate-pulse flex flex-col space-y-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-10 gap-4">
                        <div className="h-4 bg-gray-200 rounded col-span-9"></div>
                        <div className="h-4 bg-gray-200 rounded col-span-1"></div>
                    </div>
                    <div className="space-y-3 py-4">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-4 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-4 bg-gray-200 rounded"></div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-4 bg-gray-200 rounded col-span-2"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="h-4 bg-transparent rounded col-span-2"></div>
                        <div className="h-4 bg-gray-200 rounded col-span-1"></div>
                        <div className="h-4 bg-gray-200 rounded col-span-1"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSpinner;
