import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUpdatePatient } from '../../hooks/useBooking';
import ModalHeader from '../../ModalTemplate/components/ModalHeader';
import ModalSpinner from '../../ModalTemplate/components/ModalSpinner';
import { OrderLevelStatus } from '../../utils';

const EditPatient = ({ handleClose, orderDetail }) => {
    const [patientData, setPatientData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        age: '',
        gender: 'male',
        isPrepaid: false,
    });

    const updatePatient = useUpdatePatient();

    useEffect(() => {
        setPatientData(orderDetail?.patient);
    }, [orderDetail]);

    const handleUpdatePatient = async (e) => {
        e.preventDefault();
        if (patientData.phoneNumber.length < 10) {
            toast.error('Contact number must be 10 digits');
            return;
        }

        const data = {
            ...patientData,
            orderId: orderDetail.id,
            isSchedulerEnabled: orderDetail?.center?.isSchedulerEnabled,
        };

        await updatePatient.mutateAsync(data);
        handleClose();
    };
    const disablePaymentChange = [
        OrderLevelStatus.PAYMENT_PENDING,
        OrderLevelStatus.PAYMENT_RECEIVED,
    ];
    if (!orderDetail) return <ModalSpinner />;

    return (
        <div>
            <form
                onSubmit={handleUpdatePatient}
                className="flex flex-col justify-between items-start gap-4"
            >
                <ModalHeader
                    title={'Patient details'}
                    handleClose={handleClose}
                />

                <div className="w-full rounded-md px-4">
                    <div className="bg-white flex flex-col gap-2 p-4">
                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Name
                                </label>
                                <input
                                    required
                                    value={patientData.name}
                                    onChange={(e) => {
                                        setPatientData((prev) => {
                                            return {
                                                ...prev,
                                                name: e.target.value,
                                            };
                                        });
                                    }}
                                    type="text"
                                    name="last-name"
                                    id="name"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                            <div className="flex-1">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email
                                </label>
                                <input
                                    required
                                    value={patientData.email}
                                    onChange={(e) => {
                                        setPatientData((prev) => {
                                            return {
                                                ...prev,
                                                email: e.target.value,
                                            };
                                        });
                                    }}
                                    type="email"
                                    name="email"
                                    id="email-address"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="phone"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Phone
                                </label>
                                <input
                                    required
                                    value={patientData.phoneNumber}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setPatientData((prev) => {
                                                return {
                                                    ...prev,
                                                    phoneNumber: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="tel"
                                    name="number"
                                    id="number"
                                    maxLength={10}
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>
                        </div>

                        <div className="flex flex-row gap-2">
                            <div className="flex-1">
                                <label
                                    htmlFor="age"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Age
                                </label>
                                <input
                                    required
                                    value={patientData.age}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex
                                        if (
                                            e.target.value === '' ||
                                            re.test(e.target.value)
                                        ) {
                                            setPatientData((prev) => {
                                                return {
                                                    ...prev,
                                                    age: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    type="number"
                                    pattern="[0-9]*"
                                    name="age"
                                    id="age"
                                    autoComplete="off"
                                    className="p-2 focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                />
                            </div>

                            <div className="flex-1">
                                <label
                                    htmlFor="country"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Gender
                                </label>

                                <select
                                    required
                                    value={patientData.gender}
                                    onChange={(e) => {
                                        setPatientData((prev) => {
                                            return {
                                                ...prev,
                                                gender: e.target.value,
                                            };
                                        });
                                    }}
                                    id="gender"
                                    name="gender"
                                    className="p-2 bg-white focus:ring-primary-500 focus:border-primary-500 block w-full border border-gray-300 rounded outline-none"
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-8 py-4 w-full flex justify-end items-center gap-4 border-t border-gray-200">
                    <div className="flex flex-row items-center gap-4">
                        <input
                            type="checkbox"
                            checked={patientData.isPrepaid}
                            value={patientData.isPrepaid}
                            className={'w-5 h-5 cursor-pointer'}
                            onChange={(e) => {
                                if (
                                    !disablePaymentChange.includes(
                                        orderDetail?.statusName
                                    )
                                ) {
                                    toast.error(
                                        'You cannot change the payment type now'
                                    );
                                    return;
                                }

                                setPatientData((prev) => {
                                    return {
                                        ...prev,
                                        isPrepaid: e.target.checked,
                                    };
                                });
                            }}
                        />
                        <label
                            htmlFor="age"
                            className="block font-medium text-gray-700"
                        >
                            Prepaid
                        </label>
                    </div>
                    <button
                        className={`bg-primary-500 hover:bg-primary-700 py-3 px-12 border border-transparent font-medium rounded text-white focus:outline-none disabled:opacity-30`}
                        disabled={updatePatient.isLoading}
                    >
                        Update
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditPatient;
