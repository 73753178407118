import React, { useEffect, useState } from 'react';
import TestimonialCard from '../common/TestimonialCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFullPatientRoute } from '../helpers/contants';
import {
    removeDrTitleFromDoctor,
    requestPermission,
    testimonials,
} from '../helpers/helper';
import { useGetDraftOrderPublic } from '../../components/hooks/useTempOrder';
import { useDispatch } from 'react-redux';
import prodigi_pass_logo from '../../assets/images/prodigi_pass_logo_xxl.png';
import {
    setCreatePatientDetails,
    setDraftDetails,
    setSelectedStudies,
    setUserDetails,
} from '../../redux/actions/actions';
import { useGetUserDetailPublic } from '../../components/hooks/useCustomers';
import Carousel from '../common/Carousel';
import NoAccess from '../common/NoAccess';
import Loader from '../../components/utils/Loader';
import { patientFlowStateObject } from '../../redux/selectors';
import { DraftOrderStatusType } from '../../constants/constant';

const HomePage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const draftId = params.get('draft_order_id');
    const userId = params.get('user_id');

    const { patientDetails } = patientFlowStateObject();
    const { data: draftData, isFetching: isFetchingDraft } =
        useGetDraftOrderPublic(draftId);
    const { data: userData, isFetching: isFetchingUser } =
        useGetUserDetailPublic(userId);

    const [studies, setStudies] = useState<string>('');

    useEffect(() => {
        let studyString = '';
        if (draftData?.id) {
            const studiesForRedux = draftData.study?.map(
                (eachStudy: any, index: number) => {
                    if (index != 0) {
                        studyString += `, `;
                    }
                    studyString += `${eachStudy.studyName}`;
                    return {
                        value: eachStudy.id,
                        label: eachStudy.studyName,
                        modalityId: eachStudy.modalityId,
                    };
                }
            );
            dispatch(
                setCreatePatientDetails({
                    ...patientDetails,
                    name: draftData?.patient?.name ?? '',
                    phone: draftData?.patient?.phoneNumber ?? '',
                    email: draftData?.patient?.email ?? '',
                    age: draftData?.patient?.age ?? '',
                    id: draftData?.patient?.id,
                })
            );
            dispatch(
                setDraftDetails({
                    documents: draftData?.documents,
                    draftOrderId: draftData?.id,
                    status: draftData?.status,
                    physicianId: draftData?.physicianId,
                    draftDiscount: draftData.discount,
                })
            );
            dispatch(
                setSelectedStudies({
                    study: studiesForRedux,
                    patientCity: '',
                    patientPincode: '',
                })
            );
            dispatch(
                setUserDetails({
                    displayName: draftData.user?.displayName,
                    physicianName:
                        draftData.user?.customer?.physician?.[0]?.name ||
                        draftData?.user?.displayName,
                    id: draftData.user?.id,
                    customerId: draftData.user?.customer?.customerId,
                })
            );
        }

        if (userData?.id) {
            dispatch(
                setUserDetails({
                    customerId: userData.customer?.customerId,
                    displayName: userData.displayName,
                    id: userData.id,
                    physicianName:
                        userData?.customer?.physician?.[0]?.name ||
                        userData?.displayName,
                })
            );
        }
        setStudies(studyString);
    }, [draftData, userData]);

    const doctorsName =
        (draftData?.id && draftData?.user?.customer?.physician[0]?.name) ||
        (userData && userData?.customer?.physician[0]?.name) ||
        '';
    const doctorNamePlaceholder =
        (draftData?.id && draftData?.user?.customer?.name) ||
        (userData && userData?.customer?.name);

    const designation =
        (draftData?.user.customer?.physician?.[0] &&
            `${draftData?.user.customer?.physician?.[0]?.degree}, ${draftData?.user.customer?.physician?.[0]?.specialization}`) ||
        (userData?.customer?.physician?.[0] &&
            `${userData?.customer?.physician?.[0]?.degree}, ${userData?.customer?.physician?.[0]?.specialization}`) ||
        '';
    const patientName = draftData?.patient?.name ?? '';

    // if (userData?.id || draftData?.id) {
    //     try {
    //         requestPermission();
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const doctorNameWithoutTitle = removeDrTitleFromDoctor(doctorsName);

    const navigateToCreateOrder = () => {
        navigate(getFullPatientRoute.testInfo(), {
            state: { from: location.pathname },
        });
    };

    if (
        !userData?.id &&
        !draftData?.id &&
        !isFetchingDraft &&
        !isFetchingUser
    ) {
        return <NoAccess />;
    }

    if (draftData?.id && draftData?.status !== DraftOrderStatusType.DRAFTED) {
        return <NoAccess text={'This link has expired!'} />;
    }

    return (
        <Loader isLoading={isFetchingDraft || isFetchingUser}>
            <>
                <div className="flex flex-col items-center mt-6 cy-testing">
                    <div className="w-[95%] md:w-[90%] shadow border-solid border-2 flex rounded-lg py-5 px-0 p md:px-10 md:py-10 gap-4 justify-start">
                        <div className="w-[25%] flex justify-center items-center">
                            <img
                                src="../mri.png"
                                alt="scanning_image"
                                className="w-[207px] hidden md:block"
                            />
                        </div>
                        <div className="md:w-[50%] flex items-center justify-start flex-col">
                            <img
                                src={prodigi_pass_logo}
                                alt="prodigi_logo"
                                className="w-[250px] mb-2"
                            />
                            <p className="font-poppins text-[13px] md:text-[14px] text-[#707070] text-center leading-4">
                                {doctorNameWithoutTitle ? (
                                    <span className="font-medium text-[#010423]">{`Dr. ${doctorNameWithoutTitle}'s ${designation && `(${designation})`
                                        }`}</span>
                                ) : (
                                    <span className="font-medium text-[#010423]">
                                        {`${doctorNamePlaceholder}'s`}
                                    </span>
                                )}{' '}
                                preferred diagnostic partner
                            </p>
                            <br />
                            <p className="font-poppins text-[13px] md:text-[14px] font-medium text-[#010423] text-center leading-7">
                                Hello {patientName},
                            </p>
                            {studies.length > 0 &&
                                doctorNameWithoutTitle.length > 0 ? (
                                <p className="font-poppins text-[13px] md:text-[14px] text-[#707070] text-center leading-7">
                                    Your doctor has selected{' '}
                                    <span className="font-medium text-[#010423]">
                                        {studies}
                                    </span>{' '}
                                    tests for you.
                                    <br />
                                </p>
                            ) : null}
                            {draftData?.discount > 0 && (
                                <p className="font-poppins text-[13px] md:text-[14px] text-[#707070] text-center leading-7">
                                    <span>
                                        {doctorNameWithoutTitle ? (
                                            <span className="font-medium text-[#010423]">{`Dr. ${doctorNameWithoutTitle}'s ${designation &&
                                                `(${designation})`
                                                }`}</span>
                                        ) : (
                                            <span className="font-medium text-[#010423]">
                                                {doctorNamePlaceholder}
                                            </span>
                                        )}
                                    </span>{' '}
                                    has applied{' '}
                                    <span className="font-medium text-[#010423]">
                                        {draftData?.discount}% Discount for you.
                                    </span>
                                </p>
                            )}

                            <button
                                className="bg-[#1F6299] px-4 py-[5px] text-white font-medium test-sm rounded-md flex align-center hover:bg-[#022e53]"
                                onClick={navigateToCreateOrder}
                            >
                                {studies.length > 0
                                    ? 'Choose Scan Center'
                                    : 'Start Test Booking'}
                            </button>
                            <img
                                src="../mri.png"
                                alt="scanning_image"
                                className="w-[207px] md:hidden mt-10"
                            />
                        </div>
                        <div className="w-[25%]"></div>
                    </div>
                </div>
                <div className="p-10">
                    <Carousel
                        data={testimonials}
                        CardComponent={TestimonialCard}
                    />
                </div>
            </>
        </Loader>
    );
};

export default HomePage;
