import store from './store';

export const firebaseToken = () => {
    return store.getState().FirebaseTokenReduxState;
};

export const userData = () => {
    return store.getState().UserDataReduxState;
};

export const userObject = () => {
    return store.getState().UserObjectReduxState;
};

export const userClaims = () => {
    return store.getState().UserClaimsReduxState;
};

export const firebaseUserObject = () => {
    return store.getState().FirebaseUserObjectReduxState;
};

export const patientFlowStateObject = () => {
    return store.getState().PatientFlowState;
};
