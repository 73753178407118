export const DATE_ONLY_FORMAT = 'dd MMM, yyyy';

export const ReportType = {
    REWORK: 'rework',
    REPORT: 'report',
};

export const DraftOrderStatusType = {
    DRAFTED: 'DRAFTED',
    ACTIVATED: 'ACTIVATED',
    DELETED: 'DELETED',
};

export const OrderDetailSourceType = {
    NORMAL_ORDER: 'NORMAL_ORDER',
    MOBILE_APP_ORDER: 'MOBILE_APP_ORDER',
    PATIENT_ORDER: 'PATIENT_ORDER',
};

export const AggregatorType = {
    MARKET_PLACE: 'MARKET_PLACE',
    PHYSICIAN: 'PHYSICIAN',
    PHARMACY: 'PHARMACY',
};
export const OrderActionType = {
    RESCHEDULE_REQUESTED: 'RESCHEDULE_REQUESTED',
    RESCHEDULE_COMPLETED: 'RESCHEDULE_COMPLETED',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
    CANCEL_COMPLETED: 'CANCEL_COMPLETED',
};
